import React from 'react';

import PropTypes from 'prop-types';

export const RawDataExportDisclaimer = ({ action }) => {
  if (action === 'ENABLING') {
    return (
      <React.Fragment>
        <li>
          This will result in the{' '}
          <strong>
            customer gaining access to raw employee data (scores, comments etc)
          </strong>
        </li>
        <li>
          To turn this on for an existing customer, you{' '}
          <strong>must ensure the following has occurred:</strong>
          <ol>
            <li>
              Information on the feature has been reviewed by the customer and
              they understand the data that will be made available
            </li>
            <li>
              The customer has gained group sign-off from internal Privacy /
              Legal / DPO counsel and Analytics teams (and any other key
              stakeholders)
            </li>
            <li>
              API Documentation has been provided to the customers development
              team to ensure that data can be inputted into their analytics tool
            </li>
            <li>
              The customer has communicated this update to their employees ahead
              of their next round, or has plans to
            </li>
            <li>
              A response with approval from relevant persons to the Feature
              Enablement Email has been received. This email thread has been
              logged to the Gainsight timeline, with the date/time of the
              approval
            </li>
          </ol>
        </li>
      </React.Fragment>
    );
  }
  if (action === 'DISABLING') {
    return (
      <React.Fragment>
        <li>
          This will result in the{' '}
          <strong>
            customer losing access to raw employee data (scores, comments etc)
          </strong>
        </li>
        <li>
          <strong>
            We recommend this feature is not disabled while there are active
            rounds
          </strong>
          , since the experience will change for the surveyed employees
        </li>
        <li>
          To disable this for a customer, you{' '}
          <strong>must ensure the following has occurred:</strong>
          <ol>
            <li>
              The customer has gained group sign-off from internal Privacy /
              Legal / DPO counsel and Analytics teams (and any other key
              stakeholders)
            </li>
            <li>
              The customer has communicated this update to their employees ahead
              of their next round, or has plans to
            </li>
            <li>
              The email thread with the request to disable Data Export has been
              logged to the Gainsight timeline, with the date/time of the
              request
            </li>
          </ol>
        </li>
      </React.Fragment>
    );
  }
  return null;
};

RawDataExportDisclaimer.propTypes = {
  action: PropTypes.oneOf(['ENABLING', 'DISABLING']),
};
