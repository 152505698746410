import moment from 'moment';

export const defaultCalculationDate = moment.utc().startOf('month').toDate();

export const formatDate = (date, { includeDay = true } = {}) =>
  moment(date)
    .utc()
    .format(includeDay ? 'Do MMMM YYYY' : 'MMMM YYYY');

export const formatDateMonth = (date) =>
  formatDate(date, { includeDay: false });

export const hasUniqueVersionName = (versionName, versions) =>
  !versions.some((version) => version.name.trim() === versionName.trim());
