import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button, InputField, Spinner, View } from '@peakon/components';

import { formatDate, hasUniqueVersionName } from './util';
import {
  modifyBenchmarkVersion,
  publishBenchmarkVersion,
  removeBenchmarkVersion,
  scheduleExportBenchmarksJson,
} from '../../actions/BenchmarkActions';
import { show } from '../../actions/NotificationActions';
import DownloadButton from '../DownloadButton';

import styles from './styles.css';

const BenchmarkVersion = ({
  version,
  idx,
  publishedCount,
  isLoading,
  backgroundTasksRealtimeChannel,
  benchmarkVersions,
}) => {
  const { id, name, createdAt, publishedAt, companiesCount, selectorsCount } =
    version;

  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [versionNameEdit, setVersionNameEdit] = useState(name);
  const exportingVersionId = useRef(null);

  useEffect(() => {
    if (backgroundTasksRealtimeChannel) {
      backgroundTasksRealtimeChannel.on('complete', (result) => {
        realtimeNotificationDispatch(
          'success',
          `Benchmark ${name} export is ready`,
          successNotificationMessage(result),
          5 * 60 * 1000, // the temporary download link survives for 5 mins
        );
      });

      backgroundTasksRealtimeChannel.on('taskError', (result) => {
        realtimeNotificationDispatch(
          'error',
          `Benchmark ${name} export failed`,
          result.error,
        );
      });
    }
  }, [backgroundTasksRealtimeChannel]); // eslint-disable-line react-hooks/exhaustive-deps

  const isDefault = idx === 0;

  const isCreating = selectorsCount === 0;
  const isPublished = Boolean(version.publishedAt);
  const isInUse = companiesCount > 0;
  const isLastRemainingPublished = isPublished && publishedCount === 1;

  const canDelete = !isLoading && !isInUse && !isLastRemainingPublished;
  const canEdit = !isLoading;
  const canExport = !isLoading && !isExporting;
  const canPublish = !isLoading && !isCreating;
  const canSave =
    !isLoading &&
    versionNameEdit.trim().length > 0 &&
    hasUniqueVersionName(versionNameEdit, benchmarkVersions);

  const handleDelete = () => {
    const message = `Are you sure you want to delete the ${name} benchmarks?`;
    if (confirm(message)) {
      dispatch(removeBenchmarkVersion(version));
    }
  };

  const handleExport = (syntheticEvent) => {
    syntheticEvent.preventDefault();

    exportingVersionId.current = id;
    setIsExporting(true);

    dispatch(scheduleExportBenchmarksJson(id));
  };

  const handlePublish = () => {
    const message = `Are you sure you want to publish the ${name} benchmarks? This will mean they are the new default benchmarks`;
    if (confirm(message)) {
      dispatch(publishBenchmarkVersion(version));
    }
  };

  const handleSave = () => {
    version.name = versionNameEdit;
    dispatch(modifyBenchmarkVersion(version));

    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setVersionNameEdit(name);
  };

  const successNotificationMessage = (realtimeResult) => {
    return (
      <React.Fragment>
        <DownloadButton
          title="Download benchmarks"
          url={realtimeResult.benchmarksUrl}
        />
        <DownloadButton
          title="Download company details"
          url={realtimeResult.companyDetailsUrl}
        />
      </React.Fragment>
    );
  };

  const realtimeNotificationDispatch = (type, title, message, duration) => {
    if (exportingVersionId.current === id) {
      // only show the notification if this version was exported
      exportingVersionId.current = null;
      setIsExporting(false);

      return dispatch(
        show({
          type,
          title,
          message,
          duration,
          dismissable: true,
        }),
      );
    }
  };

  return (
    <tr key={id}>
      <td>
        {isDefault && (
          <View className={styles.badge}>
            <View className={styles.text}>Default</View>
          </View>
        )}
      </td>
      <td>
        {isEditing ? (
          <InputField
            inputType="text"
            onChange={setVersionNameEdit}
            value={versionNameEdit}
          />
        ) : (
          name
        )}
      </td>
      <td>
        {isCreating ? (
          <Spinner className={styles.spinnerCreating} size="small" />
        ) : (
          formatDate(createdAt)
        )}
      </td>
      <td>
        {publishedAt ? (
          formatDate(publishedAt)
        ) : (
          <Button
            size="small"
            type="muted"
            disabled={!canPublish}
            onClick={handlePublish}
          >
            Publish
          </Button>
        )}
      </td>
      <td>{companiesCount}</td>
      <td>
        <Button
          size="small"
          type="primary"
          disabled={!canExport}
          onClick={handleExport}
        >
          Export
        </Button>
      </td>
      <td>
        {isEditing ? (
          <Button
            size="small"
            type="primary"
            disabled={!canSave}
            onClick={handleSave}
          >
            Save
          </Button>
        ) : (
          <Button
            size="small"
            type="secondary"
            disabled={!canEdit}
            onClick={() => setIsEditing(true)}
          >
            Edit
          </Button>
        )}
      </td>
      <td>
        {isEditing ? (
          <Button size="small" type="muted" onClick={handleCancelEdit}>
            Cancel
          </Button>
        ) : (
          <Button
            size="small"
            type="danger"
            disabled={!canDelete}
            onClick={handleDelete}
          >
            Delete
          </Button>
        )}
      </td>
    </tr>
  );
};

BenchmarkVersion.propTypes = {
  benchmarkVersions: PropTypes.array,
  isLoading: PropTypes.bool,
  version: PropTypes.object,
  idx: PropTypes.number,
  publishedCount: PropTypes.number,
  backgroundTasksRealtimeChannel: PropTypes.object,
};

export default BenchmarkVersion;
