import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Notification } from '@peakon/components';

import * as NotificationActions from '../../actions/NotificationActions';

class NotificationSystem extends Component {
  static propTypes = {
    notifications: PropTypes.array,
    notificationActions: PropTypes.object,
  };

  render() {
    const {
      notifications,
      notificationActions: { remove },
    } = this.props;

    return (
      <Notification.Stack zIndex={1000}>
        {notifications.map((n) => (
          <Notification key={n.id} onDismiss={() => remove(n.id)} type={n.type}>
            <Notification.Title>{n.title}</Notification.Title>
            {n.message ? (
              <Notification.Message>{n.message}</Notification.Message>
            ) : null}
          </Notification>
        ))}
      </Notification.Stack>
    );
  }
}

const mapStateToProps = ({ notifications }) => ({ notifications });

const mapDispatchToProps = (dispatch) => ({
  notificationActions: bindActionCreators(NotificationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSystem);
