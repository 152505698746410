const roles = [
  'tester',
  'sales',
  'support',
  'csm',
  'cs_leader',
  'developer',
  'admin',
  'partner_consultant',
];

export const ACCESS_ROLE_CONSTANTS = roles;
