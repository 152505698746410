/**
 * @param  {object} options
 * @param  {string=} options.subdomain
 * @param  {string} options.pathname
 * @param  {Record<string, string>} options.searchParams
 */
export const getDashboardURL = ({
  subdomain = 'app',
  pathname,
  searchParams,
}) => {
  // We are assuming the backoffice hostname will always look like `subdomain.domain.topLevelDomain` (e.g.: `backoffice.peakon.com`)
  // eslint-disable-next-line no-unused-vars -- unused variable required for destructuring.
  const [_subdomain, domain, topLevelDomain] =
    window.location.hostname.split('.');
  const baseURL = `${window.location.protocol}//${subdomain}.${domain}.${topLevelDomain}`;
  const url = new URL(pathname, baseURL);

  for (const param in searchParams) {
    url.searchParams.set(param, searchParams[param]);
  }

  return url.toString();
};
