const INITIAL_STATE = {
  isLoading: false,
  accounts: [],
};

const access = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ACCESS_ACCOUNTS_READ_LOADING': {
      return {
        accounts: [],
        isLoading: true,
      };
    }
    case 'ACCESS_ACCOUNTS_READ_SUCCESS': {
      const accounts = action.data.data.map((account) => {
        const { id, attributes } = account;

        return {
          id,
          ...attributes,
        };
      });

      return {
        accounts,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default access;
