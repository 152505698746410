import React, { Component } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { View, Spinner } from '@peakon/components';

import * as EmployeeActions from '../../../actions/EmployeeActions';

import styles from './styles.css';

class EmployeeSMSLogs extends Component {
  componentDidMount() {
    const {
      employee: { id },
      employeeActions: { getSmsEvents },
    } = this.props;

    getSmsEvents(id);
  }

  render() {
    const {
      isLoadingChildData,
      employee: { name },
      smsLogs,
    } = this.props;

    return (
      <View className={styles.container}>
        {isLoadingChildData && <Spinner />}
        {!isLoadingChildData && smsLogs.length === 0 && (
          <span>{name} does not have any SMS logs.</span>
        )}
        {!isLoadingChildData && smsLogs.length > 0 && (
          <table style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <td>Id</td>
                <td>Sent</td>
                <td>Receipt</td>
                <td>Status</td>
                <td>Error</td>
                <td>Provider</td>
              </tr>
            </thead>
            <tbody>
              {smsLogs.map((smsLog) => {
                const {
                  id,
                  sentAt,
                  receiptAt,
                  receiptStatus,
                  receiptError,
                  provider,
                } = smsLog;

                return (
                  <tr key={id}>
                    <td>{id}</td>
                    <td title={sentAt}>
                      {moment(sentAt).utc().format('MMMM Do YYYY, h:mm:ss a')}
                    </td>
                    <td title={receiptAt}>
                      {receiptAt &&
                        moment(receiptAt)
                          .utc()
                          .format('MMMM Do YYYY, h:mm:ss a')}
                    </td>
                    <td>{receiptStatus}</td>
                    <td>{receiptError}</td>
                    <td>{provider}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </View>
    );
  }
}

EmployeeSMSLogs.propTypes = {
  employeeActions: PropTypes.object,
  isLoadingChildData: PropTypes.bool,
  smsLogs: PropTypes.array,
  employee: PropTypes.object,
};

EmployeeSMSLogs.defaultProps = {
  isLoadingChildData: true,
  smsLogs: [],
};

const mapStateToProps = (state) => {
  const { isLoadingChildData, smsLogs } = state.employee;

  return {
    isLoadingChildData,
    smsLogs,
  };
};

const mapDispatchToProps = (dispatch) => ({
  employeeActions: bindActionCreators(EmployeeActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSMSLogs);
