import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as FeatureFlipActions from '../../../actions/FeatureFlipActions';
import FeatureFlipForm from '../Form';

class NewFeatureFlip extends Component {
  static propTypes = {
    featureFlipActions: PropTypes.object,
    history: PropTypes.object,
  };

  navigateToFeatureFlip = (response) => {
    const {
      history: { push },
    } = this.props;
    const { data: featureFlip } = response;

    push(`/feature-flips/${featureFlip.id}`);
  };

  save = (featureFlip) => {
    const { featureFlipActions } = this.props;

    featureFlipActions
      .create({
        data: {
          type: 'feature_flips',
          attributes: featureFlip,
          relationships: {
            enabledCompanies: {
              data: featureFlip.enabledCompanies.map((company) => ({
                id: company.id,
                type: 'companies',
              })),
            },
            disabledCompanies: {
              data: featureFlip.disabledCompanies.map((company) => ({
                id: company.id,
                type: 'companies',
              })),
            },
          },
        },
      })
      .then(this.navigateToFeatureFlip);
  };

  render() {
    return <FeatureFlipForm onSave={this.save} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  featureFlipActions: bindActionCreators(FeatureFlipActions, dispatch),
});

export default connect(null, mapDispatchToProps)(NewFeatureFlip);
