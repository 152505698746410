import React from 'react';

import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

import { View } from '@peakon/components';

import { getPageQuery } from '../../utils/pagination';

import styles from './styles.css';

const Paginate = (props) => {
  const { links, currentPage, handlePageClick } = props;

  if (!links) {
    return null;
  }

  const pageCount = getPageQuery(links.last);

  if (pageCount === 1) {
    return null;
  }

  return (
    <View className={styles.pagination}>
      <ReactPaginate
        pageCount={pageCount}
        forcePage={parseInt(currentPage, 10) - 1}
        onPageChange={({ selected }) => handlePageClick(selected + 1)}
        disableInitialCallback
        disabledClassName={styles.disabled}
        activeClassName={styles.selected}
        previousLabel={<span>&laquo;</span>}
        nextLabel={<span>&raquo;</span>}
      />
    </View>
  );
};

Paginate.propTypes = {
  links: PropTypes.object,
  currentPage: PropTypes.number,
  handlePageClick: PropTypes.func,
};

export default Paginate;
