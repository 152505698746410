import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Spinner } from '@peakon/components';

import * as FeatureFlipActions from '../../../actions/FeatureFlipActions';
import FeatureFlipForm from '../Form';

class FeatureFlip extends Component {
  static propTypes = {
    featureFlipActions: PropTypes.object,
    featureFlip: PropTypes.object,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    history: PropTypes.object,
  };

  componentDidMount() {
    const {
      featureFlip,
      featureFlipActions,
      match: {
        params: { id },
      },
    } = this.props;

    if (!featureFlip) {
      featureFlipActions.read(id);
    }
  }

  navigateToFeatureFlips = () => {
    const {
      history: { push },
    } = this.props;

    push(`/feature-flips/`);
  };

  destroy = () => {
    const { featureFlipActions, featureFlip } = this.props;

    return featureFlipActions
      .destroy(featureFlip.id)
      .then(this.navigateToFeatureFlips);
  };

  save = (featureFlip) => {
    const {
      featureFlip: { id },
    } = this.props;
    const { featureFlipActions } = this.props;

    return featureFlipActions.update(id, {
      data: {
        type: 'feature_flips',
        attributes: featureFlip,
        relationships: {
          enabledCompanies: {
            data: featureFlip.enabledCompanies.map((company) => ({
              id: company.id,
              type: 'companies',
            })),
          },
          disabledCompanies: {
            data: featureFlip.disabledCompanies.map((company) => ({
              id: company.id,
              type: 'companies',
            })),
          },
        },
      },
    });
  };

  render() {
    const { featureFlip, isLoading } = this.props;

    if (isLoading || !featureFlip) {
      return <Spinner />;
    }

    return (
      <FeatureFlipForm
        featureFlip={featureFlip}
        onSave={this.save}
        onDelete={this.destroy}
      />
    );
  }
}

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => {
  const featureFlip =
    state.featureFlips.featureFlip && state.featureFlips.featureFlip.id === id
      ? state.featureFlips.featureFlip
      : state.featureFlips.featureFlips.find((f) => f.id === id);

  return {
    featureFlip,
    isLoading: state.featureFlips.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  featureFlipActions: bindActionCreators(FeatureFlipActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureFlip);
