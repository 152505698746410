import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const TestCompanyContext = React.createContext({
  testCompany: {
    name: '',
    domain: '',
    employees: 500,
    weeks: 68,
    configName: '',
    plan: '',
    password: '',
  },
  setTestCompanyContext: () => {},
  showNotification: () => {},
});

const TestCompanyProvider = (props) => {
  const dispatch = useDispatch();
  const [testCompanyContext, setTestCompanyContext] = useState({
    name: '',
    domain: '',
    employees: 500,
    weeks: 68,
    configName: '',
    plan: '',
    password: '',
  });

  const showNotification = (title, error = false) => {
    const id = uuidv4();
    dispatch({
      type: 'NOTIFICATION_SHOW',
      data: {
        id,
        title,
        message: '',
        type: error ? 'error' : 'success',
        code: null,
        dismissable: true,
      },
    });

    setTimeout(() => {
      dispatch({ type: 'NOTIFICATION_HIDE', data: { id } });
    }, 5000);
  };

  return (
    <TestCompanyContext.Provider
      value={{
        testCompany: testCompanyContext,
        setTestCompanyContext,
        showNotification,
      }}
    >
      {props.children}
    </TestCompanyContext.Provider>
  );
};

TestCompanyProvider.propTypes = {
  children: PropTypes.object,
};

export { TestCompanyContext, TestCompanyProvider };
