import PeakonApi from '@peakon/peakon-js';

import env from './env';
import errorReporter from './errorReporter';
import redirectTo from './redirectTo';

const peakonApi = new PeakonApi({
  baseUrl: env.apiProxy ? env.host : env.api,
  retryAuthUsingParam: true,
});

peakonApi.configure({
  onError: (_error, _data, response) => {
    if (response && response.status === 401) {
      return redirectTo('/login');
    }

    errorReporter.error(_error, _data);
  },
});

export default peakonApi;
