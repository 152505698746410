import each from 'lodash/each';

import { AppError } from '@peakon/error-reporter';

import api from '../client/peakonApi';

export const IMAGE_TYPES = [
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/tiff',
];

function fetch(url, options = {}, onProgress) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(options.method || 'GET', url);

    for (const k in options.headers || {}) {
      xhr.setRequestHeader(k, options.headers[k]);
    }

    xhr.onload = (e) => resolve(e.target.responseText);
    xhr.onerror = reject;

    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = onProgress;
    }

    xhr.send(options.body);
  });
}

export class FileService {
  async upload(
    file,
    type,
    { onProgress, isImage = true } = {},
    maxSize = 1024 * 1024,
  ) {
    if (isImage && !IMAGE_TYPES.includes(file.type)) {
      throw new AppError('file__type__invalid');
    } else if (file.size && file.size > maxSize) {
      throw new AppError('file__too-large');
    }

    const response = await api.get(`/files/upload`, {
      type,
      filename: file.name,
    });

    await this.sendFile(file, response.url, response.settings, onProgress);

    return {
      field: type,
      value: response.settings.key,
    };
  }

  async sendFile(file, url, settings, onProgress) {
    const formData = new FormData();

    // Don't break if https://github.com/peakon/api/pull/8769 gets rolled back
    if (!settings['Content-Type']) {
      settings['Content-Type'] = file.type;
    }

    each(settings, (setting, key) => {
      formData.append(key, setting);
    });

    formData.append('file', file);

    await fetch(
      url,
      {
        method: 'POST',
        body: formData,
      },

      onProgress,
    );
  }
}

export default new FileService();
