import React from 'react';

import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import { DateAndTimeCalendarIcon } from '@peakon/bedrock/icons/system';

import styles from './styles.css';

const formatDate = (date) => moment(date).utc().format('YYYY-MM');
const parseDate = (yearMonth) => moment(yearMonth).utc().toDate();

function MonthPicker(props) {
  const { id, disabled, label, date, onChange, minDate } = props;

  const chooseYearMonth = (e) => {
    if (!onChange) {
      return;
    }

    onChange(parseDate(e.target.value));
  };

  return (
    <div className="peak-form-field peak-form-field--date peak-form-field--date-picker">
      <label className="peak-form-label" htmlFor={id}>
        {label}
      </label>
      <div className="peak-form-control peak-form-control--icon">
        <input
          className={classNames('peak-form-text', styles.input)}
          type="month"
          id={id}
          name={id}
          min={formatDate(minDate)}
          max={formatDate(new Date())}
          onChange={chooseYearMonth}
          value={formatDate(date)}
          disabled={disabled}
        />
      </div>
      <div className="peak-form-media peak-form-media--date">
        <DateAndTimeCalendarIcon />
      </div>
    </div>
  );
}

MonthPicker.propTypes = {
  id: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
};

export default MonthPicker;
