import React, { Fragment } from 'react';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './containers/App';
import NotificationSystem from './containers/NotificationSystem';
import createStore from './createStore';

const { store, persistor } = createStore();

// eslint-disable-next-line no-restricted-globals
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Fragment>
          <App />
          <NotificationSystem />
        </Fragment>
      </Router>
    </PersistGate>
  </Provider>,
);
