import api from '../client/peakonApi';
import asyncDispatch from '../utils/asyncDispatch';

export const getAddOns = (companyId) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'ADD_ONS_READ',
    action: api.get(`/add_ons/companies/${companyId}`),
  });
};
