export const EMPLOYEE_ACTIONS_MAP = {
  daily: {
    title: 'Send daily',
    notes: ['Sends a daily digest to an employee with relevant notifications'],
    confirmationMessage: 'Are you sure you want to send daily?',
    right: 'backoffice:dataset:admin',
  },
  reset: {
    title: 'Send password reset email',
    notes: [
      'Sends a password reset email, the same as if user initiated call on the login page.',
    ],
    confirmationMessage:
      'Are you sure you want to send a password reset email?',
    right: 'backoffice:company:create',
  },
};
