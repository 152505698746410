import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { View } from '@peakon/components';

import styles from './styles.css';

const Heading = ({ children }) => (
  <h3 className={styles.heading}>{children}</h3>
);

const ResourceHeader = ({ children, blocked = false }) => {
  const classNames = classnames(styles.container, {
    [styles.blocked]: blocked,
  });

  return (
    <View className={classNames}>
      <View className={styles.content}>{children}</View>
    </View>
  );
};

ResourceHeader.propTypes = {
  children: PropTypes.node,
  blocked: PropTypes.bool,
};

Heading.propTypes = ResourceHeader.propTypes;

ResourceHeader.Heading = Heading;

export default ResourceHeader;
