import jsonapiparser from '@peakon/jsonapiparser';

import { showSuccessNotification } from './NotificationActions';
import api from '../client/peakonApi';
import asyncDispatch from '../utils/asyncDispatch';

const params = {
  fields: {
    responses: [
      'status',
      'openedAt',
      'sendAt',
      'sentAt',
      'remindAt',
      'lastRemindedAt',
      'answeredAt',
      'tokens',
      'answerCount',
      'employee',
      'kioskCode',
      'answeredVia',
      'round',
      'link',
    ].join(','),
    rounds: ['opensAt', 'closesAt', 'status', 'type'].join(','),
  },
  include: ['employee', 'round'].join(','),
};

export const getResponsesByEmployee = (employeeId) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'RESPONSES_READ',
    action: api
      .get(`/responses/employee/${employeeId}`, params)
      .then(jsonapiparser),
  });
};

export const getNextResponses = (nextUrl) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'RESPONSES_READ_NEXT',
    action: api.get(nextUrl).then(jsonapiparser),
  });
};

export const getResponsesByToken = (token) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'RESPONSES_READ',
    action: api.get(`/responses/token/${token}`, params).then(jsonapiparser),
  });
};

export const resetResponses = () => (dispatch) => {
  dispatch({
    type: 'RESPONSES_RESET',
  });
};

export const sendSurvey = (id, type, referenceId) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'RESPONSES_SURVEY_SEND',
    action: api.post(`/responses/${id}/send`).then((response) => {
      dispatch(
        showSuccessNotification({
          title: 'Survey has been sent.',
        }),
      );

      refresh(type, referenceId);

      return response;
    }),
  });
};

export const sendReminder = (id, type, referenceId) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'RESPONSES_SURVEY_REMIND_SEND',
    action: api.post(`/responses/${id}/remind`).then((response) => {
      dispatch(
        showSuccessNotification({
          title: 'Reminder has been sent.',
        }),
      );

      refresh(type, referenceId);

      return response;
    }),
  });
};

export const open = (id, type, referenceId) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'RESPONSES_SURVEY_OPEN',
    action: api.post(`/responses/${id}/open`).then((response) => {
      const title =
        'The response was reopened, and a new survey email was sent to the employee.';

      dispatch(
        showSuccessNotification({
          title,
        }),
      );

      refresh(type, referenceId);

      return response;
    }),
  });
};

export const clear = (id, type, referenceId) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'RESPONSES_SURVEY_CLEAR',
    action: api.post(`/responses/${id}/clear`).then((response) => {
      const title =
        'The response was cleared, and associated answers and comments were permanently deleted.';

      dispatch(
        showSuccessNotification({
          title,
        }),
      );

      refresh(type, referenceId);

      return response;
    }),
  });
};

const refresh = (type, referenceId) => (dispatch) => {
  if (type === 'employee') {
    dispatch(getResponsesByEmployee(referenceId));
  } else if (type === 'token') {
    dispatch(getResponsesByToken(referenceId));
  } else {
    throw new Error(`unknown type ${type}`);
  }
};
