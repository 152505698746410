import React from 'react';

import sortBy from 'lodash/sortBy';
import moment from 'moment';
import PropTypes from 'prop-types';

import styles from './styles.css';

function getClassName(fflip) {
  if (fflip.rolloutPct === 0 && fflip.enabledCompanies.length === 0) {
    return 'disabled';
  }
  if (fflip.rolloutPct === 100 && fflip.disabledCompanies.length === 0) {
    return 'enabled';
  }
  return 'partial';
}

function FeatureFlipsList(props) {
  const featureFlipsUnsorted = props.featureFlips;

  const featureFlips = sortBy(featureFlipsUnsorted, function (featureFlip) {
    switch (getClassName(featureFlip)) {
      case 'disabled':
        return 0;
      case 'partial':
        return 1;
      case 'enabled':
        return 2;
    }
  });

  const rows = featureFlips.map((featureFlip) => {
    const {
      id,
      name,
      createdAt,
      hasDefinition,
      rolloutPct,
      enabledCompanies,
      disabledCompanies,
      description,
    } = featureFlip;

    return (
      <tr
        key={id}
        className={styles[getClassName(featureFlip)]}
        onClick={() => props.onSelect(id)}
      >
        <td>
          {!hasDefinition && <span className="peak-badge">No Definition</span>}
        </td>
        <td>{name}</td>
        <td className={styles.description}>{description}</td>
        <td className={styles.rollout}>
          {rolloutPct}% / +{enabledCompanies.length} / -
          {disabledCompanies.length}
        </td>
        <td title={moment(createdAt).format('LL')}>
          {moment(createdAt).fromNow()}
        </td>
      </tr>
    );
  });

  return (
    <div className={styles.content}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Status</th>
            <th>Name</th>
            <th>Description</th>
            <th>Rollout</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}

FeatureFlipsList.propTypes = {
  featureFlips: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default FeatureFlipsList;
