import every from 'lodash/every';

const notifications = (state = [], action) => {
  switch (action.type) {
    case 'NOTIFICATION_SHOW': {
      const notification = action.data;

      if (every(state, (n) => n.id !== notification.id)) {
        return state.concat(notification);
      }

      return state;
    }
    case 'NOTIFICATION_HIDE': {
      const { id } = action.data;

      return state.filter((n) => n.id !== id);
    }
    default:
      return state;
  }
};

export default notifications;
