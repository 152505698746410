import React from 'react';

export const AiSummariesDisclaimer = () => {
  return (
    <React.Fragment>
      <li>
        This will result in the{' '}
        <strong>
          customer gaining access to AI-generated summaries, including summaries
          by NPS and on-demand summaries.
        </strong>
      </li>
      <li>
        There are legal considerations and before enabling this feature, legal
        consent needs to be collected from the customer.
      </li>
      <li>
        Please contact the Product Manager (Brett Morrone) before enabling. This
        is to ensure the correct process is adhered to and to avoid legal
        liability.
      </li>
    </React.Fragment>
  );
};
