import React, { Component } from 'react';

import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import PropTypes from 'prop-types';
import qs from 'qs';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { View, Spinner } from '@peakon/components';

import * as CompanyActions from '../../actions/CompanyActions';
import { getPageQuery } from '../../utils/pagination';
import Paginate from '../Paginate';

import styles from './styles.css';

class FormerCustomers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previousFormer: undefined,
      previousPending: undefined,
    };
  }

  componentDidMount() {
    this.get();
  }

  componentDidUpdate(prevProps) {
    if (isEqual(prevProps.location.search, this.props.location.search)) {
      return;
    }

    this.get();
  }

  render() {
    return (
      <View className={styles.container}>
        {this.renderFormerCompanies()}
        {this.renderPendingCompanies()}
      </View>
    );
  }

  renderFormerCompanies() {
    const { isLoadingFormer, formerCompanies, formerLinks } = this.props;

    return (
      <View>
        <h2>Former Customers</h2>
        {isLoadingFormer && <Spinner />}
        {!isLoadingFormer && (
          <table>
            <thead>
              <tr>
                <td>Name</td>
                <td>Status</td>
                <td>Size</td>
                <td>Service Ended At</td>
                <td>Deletion Due</td>
              </tr>
            </thead>
            <tbody>
              {formerCompanies.map((company) => {
                const { id, name, status, size, serviceEndedAt } = company;

                const dueAt = moment(serviceEndedAt).utc().add(3, 'months');
                const isDeletionDue = dueAt.isSameOrBefore(moment.utc());

                const classNames = classnames({
                  [styles.isDeletionDue]: isDeletionDue,
                });

                return (
                  <tr key={id}>
                    <td>
                      <Link to={`/companies/${id}`}>{name}</Link>
                    </td>
                    <td>{status}</td>
                    <td>{size}</td>
                    <td>{moment(serviceEndedAt).format('LL')}</td>
                    <td className={classNames}>{dueAt.format('LL')}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <Paginate
          links={formerLinks}
          // eslint-disable-next-line no-restricted-globals
          currentPage={getPageQuery(location.search, 'former')}
          handlePageClick={(page) => this.handlePageClick(page, 'former')}
        />
      </View>
    );
  }

  renderPendingCompanies() {
    const { isLoadingPending, pendingCompanies, pendingLinks } = this.props;

    return (
      <View className={styles.pendingContainer}>
        <h2>Pending Deletion ({pendingCompanies.length})</h2>
        {isLoadingPending && <Spinner />}
        {!isLoadingPending && (
          <table>
            <thead>
              <tr>
                <td>Name</td>
                <td>Status</td>
                <td>Last Answer</td>
                <td>Requested At</td>
                <td>Requested By</td>
                <td>Deletion Due</td>
              </tr>
            </thead>
            <tbody>
              {pendingCompanies.map((company) => {
                const {
                  id,
                  name,
                  status,
                  lastAnswerAt,
                  deletionRequestedAt,
                  blockedBy: { attributes: { email } = {} } = {},
                } = company;

                const dueAt = moment(deletionRequestedAt)
                  .utc()
                  .add(3, 'months');
                const isDeletionDue = dueAt.isSameOrBefore(moment.utc());

                const classNames = classnames({
                  [styles.isDeletionDue]: isDeletionDue,
                });

                return (
                  <tr key={id}>
                    <td>
                      <Link to={`/companies/${id}`}>{name}</Link>
                    </td>
                    <td>{status}</td>
                    <td>
                      {lastAnswerAt ? moment(lastAnswerAt).format('LL') : null}
                    </td>
                    <td>{moment(deletionRequestedAt).utc().format('LLL')}</td>
                    <td>{email || 'No email available'}</td>
                    <td className={classNames}>{dueAt.format('LL')}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <Paginate
          links={pendingLinks}
          // eslint-disable-next-line no-restricted-globals
          currentPage={getPageQuery(location.search, 'pending')}
          handlePageClick={(page) => this.handlePageClick(page, 'pending')}
        />
      </View>
    );
  }

  handlePageClick = (page, type) => {
    const {
      location: { search },
      history: { push },
    } = this.props;

    const query = qs.parse(search, {
      ignoreQueryPrefix: true,
    });

    query[type] = page;

    let url = '/former-customers';
    if (page > 1) {
      url = `${url}?${qs.stringify(query)}`;
    }

    push(url);
  };

  get() {
    const {
      location: { search },
      companyActions: { getFormerCompanies, getPendingCompanies },
      formerLinks,
      pendingLinks,
    } = this.props;

    const query = qs.parse(search, {
      ignoreQueryPrefix: true,
    });

    const { former, pending } = query;
    const { previousFormer, previousPending } = this.state;

    if (former !== previousFormer || !formerLinks) {
      getFormerCompanies(former);
    }

    if (pending !== previousPending || !pendingLinks) {
      getPendingCompanies(pending);
    }

    this.setState({
      previousFormer: former,
      previousPending: pending,
    });
  }
}

FormerCustomers.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  companyActions: PropTypes.object,
  isLoadingFormer: PropTypes.bool,
  formerCompanies: PropTypes.array,
  formerLinks: PropTypes.object,
  isLoadingPending: PropTypes.bool,
  pendingCompanies: PropTypes.array,
  pendingLinks: PropTypes.object,
};

FormerCustomers.defaultProps = {
  isLoadingFormer: true,
  formerCompanies: [],
  formerLinks: null,
  isLoadingPending: true,
  pendingCompanies: [],
  pendingLinks: null,
};

const mapStateToProps = (state) => {
  const {
    companies: {
      isLoadingFormer,
      formerCompanies,
      formerLinks,
      isLoadingPending,
      pendingCompanies,
      pendingLinks,
    },
  } = state;

  return {
    isLoadingFormer,
    formerCompanies,
    formerLinks,
    isLoadingPending,
    pendingCompanies,
    pendingLinks,
  };
};

const mapDispatchToProps = (dispatch) => ({
  companyActions: bindActionCreators(CompanyActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormerCustomers);
