import React from 'react';

import { View } from '@peakon/components';

const Missed = () => (
  <View>
    Sorry, you have insufficient rights to access this area. Try{' '}
    <a href="/login?redirectTo=%2F">logging in again</a>
  </View>
);

export default Missed;
