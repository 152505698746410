import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { NavigationBasicNavigationChevronRightIcon as ArrowRight } from '@peakon/bedrock/icons/system';
import { View } from '@peakon/components';

import styles from './styles.css';

const Link = ({ to, children, isLast = false }) => (
  <Fragment>
    <NavLink className={styles.link} to={to}>
      {children}
    </NavLink>
    {!isLast && <ArrowRight className={styles.arrow} />}
  </Fragment>
);

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  isLast: PropTypes.bool,
};

const BreadCrumbs = ({ children }) => (
  <View className={styles.container}>{children}</View>
);

BreadCrumbs.propTypes = {
  children: PropTypes.node,
};

BreadCrumbs.Link = Link;

export default BreadCrumbs;
