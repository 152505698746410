const initialState = {
  isLoading: false,
  error: null,
  items: [],
};

const companies = (state = initialState, action) => {
  switch (action.type) {
    case 'COMPANY_SEARCH_LOADING': {
      return {
        ...state,
        isSearching: true,
      };
    }
    case 'COMPANY_LIST_LOADING': {
      return {
        ...state,
        items: [],
        isLoading: true,
      };
    }
    case 'COMPANY_LIST_SUCCESS': {
      const { data, included } = action.data;

      const items = data.map((company) => {
        const subdomains = company.relationships?.companySubdomains.data ?? [];

        return {
          id: company.id,
          ...company.attributes,
          subdomains: subdomains.map(
            (s) => included.find((i) => i.id === s.id)?.attributes,
          ),
        };
      });

      return {
        ...state,
        items,
        isLoading: false,
      };
    }
    case 'COMPANY_SEARCH_SUCCESS': {
      const { data, links, included } = action.data;

      const searchResult = data.map((company) => {
        const subdomains = company.relationships?.companySubdomains.data ?? [];

        return {
          id: company.id,
          ...company.attributes,
          subdomains: subdomains.map(
            (s) => included?.find((i) => i.id === s.id)?.attributes,
          ),
        };
      });

      return {
        ...state,
        links,
        searchResult,
        isSearching: false,
        hasSearchResult: true,
      };
    }
    case 'COMPANY_READ_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'COMPANY_READ_SUCCESS': {
      const {
        data: {
          id,
          attributes,
          relationships: { csm, customSenderDomains, companySubdomains } = {},
        },
      } = action.data;

      const index = state.items.findIndex((i) => i.id === id);
      if (index > -1) {
        state.items[index] = {
          id,
          csm,
          customSenderDomains,
          companySubdomains,
          ...attributes,
        };
      } else {
        state.items.push({
          id,
          csm,
          customSenderDomains,
          companySubdomains,
          ...attributes,
        });
      }

      return {
        ...state,
        isLoading: false,
      };
    }
    case 'COMPANY_UPDATE_SUCCESS': {
      const {
        data: { id, attributes, relationships: { csm } = {} },
      } = action.data;

      const index = state.items.findIndex((i) => i.id === id);
      if (index > -1) {
        state.items[index] = {
          id,
          csm,
          ...attributes,
        };
      }

      return {
        ...state,
        isLoading: false,
      };
    }
    case 'COMPANY_SEARCH_RESET': {
      return {
        ...state,
        searchResult: [],
        isSearching: false,
        hasSearchResult: false,
      };
    }
    case 'COMPANIES_LIST_FORMER_LOADING': {
      return {
        ...state,
        isLoadingFormer: true,
      };
    }
    case 'COMPANIES_LIST_FORMER_SUCCESS': {
      const { data, links } = action.data;

      const formerCompanies = data.map((company) => {
        const { id, attributes } = company;

        return {
          id,
          ...attributes,
        };
      });

      return {
        ...state,
        isLoadingFormer: false,
        formerCompanies,
        formerLinks: links,
      };
    }
    case 'COMPANIES_LIST_PENDING_LOADING': {
      return {
        ...state,
        isLoadingPending: true,
      };
    }
    case 'COMPANIES_LIST_PENDING_SUCCESS': {
      const { data, links } = action.data;

      const pendingCompanies = data.map((company) => {
        const { id, attributes, relationships } = company;

        return {
          id,
          ...attributes,
          ...relationships,
        };
      });

      return {
        ...state,
        isLoadingPending: false,
        pendingCompanies,
        pendingLinks: links,
      };
    }
    default:
      return state;
  }
};

export default companies;
