import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button, Card, InputField, Typography, View } from '@peakon/components';

import { upgradeBenchmarks } from '../../../actions/BenchmarkActions';
import MonthPicker from '../../MonthPicker';
import {
  defaultCalculationDate,
  formatDateMonth,
  hasUniqueVersionName,
} from '../util';

import styles from '../styles.css';

function UpgradeBenchmarks({ benchmarkVersions, disabled }) {
  const dispatch = useDispatch();

  const [upgradeVersionName, setUpgradeVersionName] = useState('');
  const [calculationDate, setCalculationDate] = useState(
    defaultCalculationDate,
  );

  const handleUpgradeBenchmarks = (syntheticEvent) => {
    syntheticEvent.preventDefault();

    const message = `Are you sure you want to calculate new benchmarks for ${formatDateMonth(
      calculationDate,
    )}?`;
    if (confirm(message)) {
      dispatch(upgradeBenchmarks(upgradeVersionName, calculationDate));
    }

    setUpgradeVersionName('');
    setCalculationDate(defaultCalculationDate);
  };

  return (
    <Card className={styles.headerCard}>
      <View>
        <Typography.H2>Calculate Benchmarks</Typography.H2>
        <form onSubmit={handleUpgradeBenchmarks}>
          <View>
            <InputField
              id="version-name"
              data-testid="calculateBenchmark"
              label="Benchmark Version Name"
              inputType="text"
              onChange={setUpgradeVersionName}
              value={upgradeVersionName}
              disabled={disabled}
            />
          </View>
          <View>
            <MonthPicker
              id="calculation-date"
              label="Calculation Date"
              minDate={new Date('2017-01-01')} // no need to backdate further than this
              onChange={setCalculationDate}
              date={calculationDate}
              disabled={disabled}
            />
          </View>
          <View>
            <Button
              async
              buttonType="submit"
              disabled={
                disabled ||
                isEmpty(upgradeVersionName.trim()) ||
                !calculationDate ||
                !hasUniqueVersionName(upgradeVersionName, benchmarkVersions)
              }
            >
              Queue Benchmark Calculation
            </Button>
          </View>
        </form>
      </View>
    </Card>
  );
}

UpgradeBenchmarks.propTypes = {
  benchmarkVersions: PropTypes.array,
  disabled: PropTypes.bool,
};

export default UpgradeBenchmarks;
