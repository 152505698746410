import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { View } from '@peakon/components';

import { PLANS, PREMIUM_STATUS } from '../../constants/PlanConstants';

import styles from './styles.css';

const getPlanTitle = (plan) => {
  if (plan.includes('-')) {
    plan = plan.replace('-', ' ');
  }

  return plan;
};

const PlanBadge = ({ plan, type }) => {
  if (!plan) {
    plan = 'free';
  }

  if (plan === 'premium') {
    plan = 'paid';
  }

  return (
    <View className={classnames(styles.badge, styles[plan], styles[type])}>
      <View className={styles.text}>{getPlanTitle(plan)}</View>
    </View>
  );
};

PlanBadge.propTypes = {
  plan: PropTypes.oneOf([
    ...PLANS,
    ...PREMIUM_STATUS,
    'blocked',
    'beta-features',
    'question-set',
    'include',
  ]),
  type: PropTypes.oneOf(['default', 'full', 'label']),
};

PlanBadge.defaultProps = {
  plan: 'free',
};

export default PlanBadge;
