import React, { Component } from 'react';

import classnames from 'classnames';
import escape from 'lodash/escape';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDashboardURL } from 'utils/getDashboardURL';

import { LinkButton } from '@peakon/bedrock/react/button';
import { InputField, View, Select, Label, Spinner } from '@peakon/components';

import * as CompanyActions from '../../actions/CompanyActions';
import { PLANS } from '../../constants/PlanConstants';
import { getPageQuery } from '../../utils/pagination';
import {
  getUrlQueryParams,
  getStateFromQueryParams,
} from '../../utils/queryParams';
import CollectionHeader from '../CollectionHeader';
import CompanyInfo from '../CompanyInfo';
import Paginate from '../Paginate';

import styles from './styles.css';

const FILTERS = [
  { value: 'all', label: 'All' },
  { value: 'essential', label: 'Essential' },
  { value: 'business', label: 'Business' },
  { value: 'premier', label: 'Premier' },
  { value: 'premium', label: 'Paid' },
  { value: 'churned', label: 'Churned' },
  { value: 'free', label: 'Free' },
];

function buildParams(state, page = 1) {
  const {
    query,
    filter: { value },
  } = state;

  let params = {
    page,
  };

  if (value !== 'all') {
    const property = PLANS.includes(value) ? 'plan' : 'premiumStatus';

    params = {
      ...params,
      filter: {
        [property]: value,
      },
    };
  }

  if (query) {
    params = {
      ...params,
      q: query,
    };
  }

  return params;
}

class Companies extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    companyActions: PropTypes.object,
    isLoading: PropTypes.bool,
    companies: PropTypes.array,
    links: PropTypes.object,
    searchResult: PropTypes.array,
    isSearching: PropTypes.bool,
    hasSearchResult: PropTypes.bool,
  };

  static defaultProps = {
    isLoading: true,
    companies: [],
    searchResult: [],
    isSearching: false,
    links: null,
  };

  constructor(props) {
    super(props);

    // eslint-disable-next-line no-restricted-globals
    const { q, filter } = getStateFromQueryParams(location.search, {
      q: '',
      filter: FILTERS[0].value,
    });

    this.state = {
      query: escape(q),
      filter: FILTERS.find((f) => f.value === filter) || FILTERS[0],
      params: {},
      page: getPageQuery(props.location.search),
    };

    this.input = null;
  }

  componentDidMount() {
    const {
      companyActions: { resetSearch },
    } = this.props;

    resetSearch();
    this.search();

    if (this.input) {
      this.input.focus();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;

    const page = getPageQuery(location.search);

    const hasChangedPage = page !== getPageQuery(prevProps.location.search);
    const hasChanges = this.state.filter.value !== prevState.filter.value;

    if (hasChanges || hasChangedPage) {
      this.search(!hasChangedPage);
    }
  }

  componentWillUnmount() {
    const {
      companyActions: { resetSearch },
    } = this.props;

    resetSearch();
  }

  render() {
    const {
      isLoading,
      companies,
      isSearching,
      hasSearchResult,
      searchResult,
      links,
    } = this.props;

    const { query } = this.state;

    const items = hasSearchResult ? searchResult : companies;

    return (
      <View>
        <CollectionHeader>
          <CollectionHeader.Heading>Companies</CollectionHeader.Heading>
          <CollectionHeader.Actions>
            <View className={styles.action}>
              <form action="" onSubmit={this.handleSearchSubmit}>
                <InputField
                  inputType="search"
                  placeholder="Company name"
                  label="Company name"
                  value={query}
                  onChange={this.handleSearchChange}
                  onClear={this.handleSearchClear}
                  inputRef={(node) => (this.input = node)}
                />
              </form>
            </View>
            {this.renderFilters()}
          </CollectionHeader.Actions>
        </CollectionHeader>
        <View className={styles.content}>
          {(isLoading || isSearching) && <Spinner />}
          {!isLoading && hasSearchResult && items.length === 0 && (
            <span>Could not find any companies.</span>
          )}
          {!isLoading && !isSearching && items.length > 0 && (
            <View className={styles.table}>
              <table>
                <thead>
                  <tr className={styles.tableHeader}>
                    <td>Name</td>
                    <td>Created at</td>
                    <td>Location</td>
                    <td>Survey rounds</td>
                    <td>Last survey</td>
                    <td>Next survey</td>
                    <td>&nbsp;</td>
                  </tr>
                </thead>
                <tbody>
                  {items.map((company) => {
                    const {
                      id,
                      location,
                      createdAt,
                      roundCount,
                      nextOpen,
                      lastOpen,
                      lastAnswerAt,
                      accessPossible,
                      subdomains,
                    } = company;

                    const primarySubdomain = subdomains?.find(
                      (subdomain) => subdomain.primary,
                    )?.subdomain;

                    return (
                      <tr key={id} onClick={() => this.navigateToCompany(id)}>
                        <td>
                          <CompanyInfo company={company} />
                        </td>
                        <td>{moment(createdAt).utc().format('ll')}</td>
                        <td>{location}</td>
                        <td>{roundCount}</td>
                        <td>
                          {lastOpen
                            ? moment(lastOpen).utc().format('ll')
                            : null}
                          {lastAnswerAt
                            ? ` - ${moment(lastAnswerAt).utc().format('ll')}`
                            : null}
                        </td>
                        <td>
                          {nextOpen
                            ? moment(nextOpen).utc().format('ll')
                            : null}
                        </td>
                        <td className={styles.loginAs}>
                          {accessPossible && (
                            <LinkButton
                              size="small"
                              variant="primary"
                              href={getDashboardURL({
                                pathname: '/login',
                                searchParams: { company_id: id },
                                subdomain: primarySubdomain,
                              })}
                              onClick={(e) => e.stopPropagation()}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Log in as
                            </LinkButton>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Paginate
                links={links}
                // eslint-disable-next-line no-restricted-globals
                currentPage={getPageQuery(location.search)}
                handlePageClick={this.handlePageClick}
              />
            </View>
          )}
        </View>
      </View>
    );
  }

  renderFilters = () => {
    const { filter } = this.state;

    const isClearable = filter.value !== 'all';

    return (
      <View className={classnames(styles.action, styles.filters)}>
        <View className={styles.filterText}>Filters:</View>
        <View className={styles.filter}>
          <Label>Account Status</Label>
          <Select
            options={FILTERS}
            value={filter}
            onChange={this.handleFilterChange}
            placeholder="All"
            isClearable={isClearable}
          />
        </View>
      </View>
    );
  };

  handleSearchChange = (q) => {
    this.setState({ query: q });
  };

  handleSearchClear = () => {
    this.setState({ query: '' }, () => this.search(true));
  };

  handleSearchSubmit = (syntheticEvent) => {
    syntheticEvent.preventDefault();

    this.search(true);
  };

  handleFilterChange = (filter) => {
    // set default when clearing
    if (filter === null) {
      filter = {
        ...FILTERS[0],
      };
    }

    this.setState({ filter }, () => {
      if (this.input) {
        this.input.focus();
      }
    });
  };

  search = (reset = false) => {
    const {
      location,
      history: { replace },
      companyActions: { search },
    } = this.props;

    let page = getPageQuery(location.search);

    if (reset) {
      // reset page when changing filters etc.
      page = 1;

      // replace url
      replace('/companies');
    }

    const params = getUrlQueryParams(location.search, {
      q: this.state.query,
      filter: this.state.filter.value,
    });

    replace({ pathname: location.pathname, search: params });
    search(buildParams(this.state, page));
  };

  navigateToCompany = (id) => {
    const {
      history: { push },
    } = this.props;

    push(`/companies/${id}`);
  };

  handlePageClick = (page) => {
    const {
      history: { push },
    } = this.props;

    const query = page === 1 ? '' : `?page=${page}`;
    push(`/companies${query}`);
  };
}

const mapStateToProps = (state) => ({
  isLoading: state.companies.isLoading,
  companies: state.companies.items,
  links: state.companies.links,
  searchResult: state.companies.searchResult,
  isSearching: state.companies.isSearching,
  hasSearchResult: state.companies.hasSearchResult,
});

const mapDispatchToProps = (dispatch) => ({
  companyActions: bindActionCreators(CompanyActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
