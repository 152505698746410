import jsonapiparser from '@peakon/jsonapiparser';

import { showSuccessNotification } from './NotificationActions';
import api from '../client/peakonApi';
import fileService from '../services/FileService';
import asyncDispatch from '../utils/asyncDispatch';

const MAX_BENCHMARKS_FILE_SIZE = 10 * 1024 * 1024; // 10 MB
const MAX_COMPANY_DETAILS_FILE_SIZE = 1 * 1024 * 1024; // 1 MB

const companyBenchmarkVersionsQuery = {
  include: ['version'].join(','),
  fields: {
    benchmark_versions: ['+'].join(','),
  },
};

export const addCompanyBenchmarkVersion =
  (versionId, companyId, validFrom) => (dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'COMPANY_BENCHMARK_VERSION_CREATE',
      action: api
        .post(
          `/benchmarks/companies/${companyId}/versions/${versionId}`,
          companyBenchmarkVersionsQuery,
          {
            validFrom,
          },
        )
        .then((response) => jsonapiparser(response)),
    });
  };

export const removeCompanyBenchmarkVersion =
  (companyBenchmarkVersion, companyId) => (dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'COMPANY_BENCHMARK_VERSION_DELETE',
      action: api
        .delete(`/benchmarks/companies/versions/${companyBenchmarkVersion.id}`)
        .then((response) => {
          dispatch(
            showSuccessNotification({
              title: 'Benchmark version deleted from company',
            }),
          );

          dispatch(getCompanyBenchmarkVersions(companyId));

          return response;
        }),
    });
  };

export const publishBenchmarkVersion = (benchmarkVersion) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'BENCHMARK_VERSION_PUBLISH',
    action: api
      .patch(`/benchmarks/versions/${benchmarkVersion.id}/publish`)
      .then((response) => {
        dispatch(
          showSuccessNotification({
            title: `Benchmark version ${benchmarkVersion.name} published`,
          }),
        );

        dispatch(getBenchmarkVersions());

        return response;
      }),
  });
};

export const modifyBenchmarkVersion = (benchmarkVersion) => (dispatch) => {
  const { id, name } = benchmarkVersion;

  return asyncDispatch({
    dispatch,
    resource: 'BENCHMARK_VERSION_EDIT',
    action: api
      .patch(`/benchmarks/versions/${id}`, undefined, {
        name,
      })
      .then((response) => jsonapiparser(response)),
  });
};

export const getCompanyBenchmarkVersions = (companyId) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_BENCHMARK_VERSIONS_READ',
    action: api
      .get(
        `/benchmarks/companies/${companyId}/versions`,
        companyBenchmarkVersionsQuery,
      )
      .then((response) => jsonapiparser(response)),
  });
};

export const removeBenchmarkVersion = (benchmarkVersion) => (dispatch) => {
  const { id, name } = benchmarkVersion;

  return asyncDispatch({
    dispatch,
    resource: 'BENCHMARK_VERSION_DELETE',
    action: api.delete(`/benchmarks/versions/${id}`).then((response) => {
      dispatch(
        showSuccessNotification({
          title: `Benchmark version ${name} deleted`,
        }),
      );

      dispatch(getBenchmarkVersions());

      return response;
    }),
  });
};

export const scheduleExportCompareBenchmarks =
  (baseVersionId, compareVersionId) => (dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'BENCHMARK_VERSION_COMPARISON_EXPORT',
      action: api.post(
        `/benchmarks/versions/compare/${baseVersionId}/${compareVersionId}/export/schedule`,
      ),
    });
  };

export const scheduleExportBenchmarksJson = (versionId) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'BENCHMARK_VERSION_EXPORT_JSON',
    action: api.post(`/benchmarks/versions/${versionId}/export/json/schedule`),
  });
};

export const upgradeBenchmarks =
  (versionName, calculationDate) => (dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'BENCHMARKS_UPGRADE',
      action: api
        .post('/benchmarks/upgrade', undefined, {
          versionName,
          calculationDate,
        })
        .then((response) => {
          dispatch(
            showSuccessNotification({
              title: `${versionName} benchmarks queued for upgrade`,
            }),
          );

          dispatch(getBenchmarkVersions());

          return response;
        }),
    });
  };

export const getBenchmarkVersions =
  (query = {}) =>
  (dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'BENCHMARK_VERSIONS_READ',
      action: api.get(`/benchmarks/versions`, query),
    });
  };

export const importBenchmarkVersion =
  (benchmarksFile, companyDetailsFile, versionName = null) =>
  async (dispatch) => {
    dispatch(updateProgress('BENCHMARKS_UPLOAD', 0));
    dispatch(updateProgress('COMPANY_DETAILS_UPLOAD', 0));

    const benchmarksUploaded = await asyncDispatch({
      dispatch,
      resource: 'BENCHMARKS_UPLOAD',
      action: fileService.upload(
        benchmarksFile,
        'benchmarks',
        {
          onProgress: (event) => {
            dispatch(
              updateProgress(
                'BENCHMARKS_UPLOAD',
                (event.loaded / event.total) * 100,
              ),
            );
          },
          isImage: false,
        },
        MAX_BENCHMARKS_FILE_SIZE,
      ),
    });
    const companyDetailsUploaded = await asyncDispatch({
      dispatch,
      resource: 'COMPANY_DETAILS_UPLOAD',
      action: fileService.upload(
        companyDetailsFile,
        'benchmarkCompanyDetails',
        {
          onProgress: (event) => {
            dispatch(
              updateProgress(
                'COMPANY_DETAILS_UPLOAD',
                (event.loaded / event.total) * 100,
              ),
            );
          },
          isImage: false,
        },
        MAX_COMPANY_DETAILS_FILE_SIZE,
      ),
    });

    return asyncDispatch({
      dispatch,
      resource: 'BENCHMARKS_IMPORT',
      action: api
        .post(`/benchmarks/import`, undefined, {
          benchmarksFile: benchmarksUploaded.value,
          companyDetailsFile: companyDetailsUploaded.value,
          versionName,
        })
        .then((response) => {
          dispatch(
            showSuccessNotification({
              title: `Benchmark version ${response.data.attributes.name} created; benchmarks queued for import`,
            }),
          );

          dispatch(getBenchmarkVersions());

          return response;
        }),
    });
  };

export const updateProgress = (resource, progress) => {
  return {
    type: `${resource}_PROGRESS`,
    data: {
      progress,
    },
  };
};
