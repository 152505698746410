import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Spinner } from '@peakon/bedrock/react/spinner';

import * as CompanyActions from '../../../actions/CompanyActions';

import styles from './styles.css';

const CompanyAdmins = ({ company }) => {
  const dispatch = useDispatch();
  const { isLoadingAdmins, admins = [] } = useSelector((state) => {
    return {
      session: state.session,
      isLoadingAdmins: state.company.isLoadingAdmins,
      admins: state.company.admins,
    };
  });

  const { id } = company;

  useEffect(() => {
    dispatch(CompanyActions.getAdmins(id));
  }, [id, dispatch]);

  return (
    <div className={styles.container}>
      <h2>Admins</h2>

      {isLoadingAdmins ? (
        <div className={styles.spinner}>
          <Spinner size="48" />
        </div>
      ) : (
        <table>
          <tbody>
            {admins.map((admin) => {
              const { id: adminId, name, email } = admin;

              return (
                <tr key={id}>
                  <td>
                    <Link to={`/employees/${adminId}`}>{name}</Link>
                  </td>
                  <td>{email}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

CompanyAdmins.propTypes = {
  company: PropTypes.object,
};

export default CompanyAdmins;
