import merge from 'lodash/merge';

import ErrorReporter from '@peakon/error-reporter';

import env from './env';
import { getBuffer } from './logMiddleware';

const transform = (payload) =>
  merge(payload, {
    data: {
      custom: {
        previousActions: getBuffer(),
      },
    },
  });

const errorReporter =
  window.__peakon_error__ ||
  new ErrorReporter({
    accessToken: env.rollbar ? env.rollbar.client : '',
    environment: env.environment,
    version: env.version,
    transform,
  });

// install rollbar into window.error
window.__peakon_error__ = errorReporter;

export default errorReporter;
