import React, { Fragment, useEffect, useState } from 'react';

import classnames from 'classnames';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import thisAndThat from 'this-and-that';

import { Spinner } from '@peakon/bedrock/react/spinner';
import {
  View,
  // Spinner,
  Checkbox,
  Button,
  RadioGroup,
  Typography,
  Modal,
} from '@peakon/components';

import { AiSummariesDisclaimer } from './AiSummariesDisclaimer';
import { RawDataExportDisclaimer } from './RawDataExportDisclaimer';
import * as AddOnActions from '../../../actions/AddOnActions';
import * as CompanyActions from '../../../actions/CompanyActions';
import LockedIcon from '../../../assets/lock.svg';
import PlanBadge from '../../PlanBadge';

import styles from './styles.css';

const SORTED_PLANS = ['question-set', 'essential', 'business', 'premier'];

const DEPRECATED_ALWAYS_ON = ['sensitive_comments'];
const areArraysDifferent = (left, right) => !isEqual(left.sort(), right.sort());

const QUESTION_SETS = [
  'engagement',
  'diversity_inclusion',
  'health_wellbeing',
  'covid19',
  'transformation_change',
  'onboarding',
  'exit',
];

const CUSTOM_DRIVER_OPTIONS = [
  {
    id: 'no_custom_driver',
    name: 'No Custom Drivers',
    plan: 'essential',
  },
  {
    id: 'single_custom_driver',
    name: 'Single Custom Driver',
    plan: 'business',
  },
  {
    id: 'multiple_custom_drivers',
    name: 'Multiple Custom Drivers',
    plan: 'premier',
  },
];

const customDriverArray = CUSTOM_DRIVER_OPTIONS.map((option) => option.id);

const CONFIRMATION_STATUS = {
  INITIAL: null,
  REQUIRES_CONFIRMATION: 'requiresConfirmation',
  CONFIRMING: 'confirming',
  CONFIRMED: 'confirmed',
};

const REQUIRE_DISABLE_CONFIRMATION_ADDONS = new Set([
  'access_by_category',
  'access_by_question_set',
  'engagement',
  'diversity_inclusion',
  'health_wellbeing',
  'covid19',
  'transformation_change',
  'onboarding',
  'exit',
  'single_custom_driver',
  'multiple_custom_drivers',
  'raw_data_export',
]);

const REQUIRE_ENABLE_CONFIRMATION_ADDONS = new Set([
  'raw_data_export',
  'ai_summaries',
]);

const isAddonLocked = (option, addOns) => {
  return (
    option.isLocked ||
    (option.id === 'access_by_category' &&
      !addOns.includes('access_by_question_set')) ||
    (option.id === 'access_by_question_set' &&
      addOns.includes('access_by_category')) ||
    (option.id === 'access_by_segment' &&
      !addOns.includes('access_by_category')) ||
    (option.id === 'access_by_category' && addOns.includes('access_by_segment'))
  );
};

const AddonChangesModal = ({
  enabledDiff,
  disabledDiff,
  status,
  onChange,
  onConfirm,
  onClose,
}) => {
  const enabledIds = enabledDiff.map((addon) => addon.id);
  const disabledIds = disabledDiff.map((addon) => addon.id);
  const ids = [...enabledIds, ...disabledIds];
  const isModifyingRawDataExport = ids.includes('raw_data_export');
  const isModifyingOtherAddOn = ids.some(
    (id) => !['raw_data_export', 'ai_summaries'].includes(id),
  );
  const isEnablingAiSummaries = enabledIds.includes('ai_summaries');
  const checkboxLabel =
    !isModifyingRawDataExport ||
    (isModifyingRawDataExport && isModifyingOtherAddOn)
      ? 'I understand'
      : 'I confirm that this has all happened';

  return (
    <Modal
      isOpen={
        status === CONFIRMATION_STATUS.CONFIRMING ||
        status === CONFIRMATION_STATUS.REQUIRES_CONFIRMATION
      }
      title="Are you sure?"
      onClose={onClose}
    >
      <Modal.Header>
        Are you sure?
        <Modal.CloseButton closeLabel="Close" onClick={onClose} />
      </Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to update the following add-ons:</p>
        {enabledDiff.length > 0 && (
          <p>Enabled: {thisAndThat(enabledDiff.map((addon) => addon.name))}</p>
        )}
        {disabledDiff.length > 0 && (
          <p>
            Disabled: {thisAndThat(disabledDiff.map((addon) => addon.name))}
          </p>
        )}
        <ul>
          {isEnablingAiSummaries && <AiSummariesDisclaimer />}
          {isModifyingRawDataExport && (
            <RawDataExportDisclaimer
              action={
                enabledIds.includes('raw_data_export')
                  ? 'ENABLING'
                  : 'DISABLING'
              }
            />
          )}
          {isModifyingOtherAddOn && (
            <li>
              This will result in{' '}
              <strong>disabling certain access control groups</strong> and users
              losing access to Peakon.
            </li>
          )}
        </ul>
        <div className={styles.confirm}>
          <Checkbox
            id="checkbox"
            aria-label={checkboxLabel}
            disabledLabel="Disabled"
            type="checkbox"
            checked={status === CONFIRMATION_STATUS.CONFIRMING}
            onChange={onChange}
          >
            {checkboxLabel}
          </Checkbox>
        </div>
      </Modal.Content>
      <Modal.Footer className={styles.footer}>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={status !== CONFIRMATION_STATUS.CONFIRMING}
          onClick={onConfirm}
        >
          Confirm changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddonChangesModal.propTypes = {
  disabledDiff: PropTypes.array,
  enabledDiff: PropTypes.array,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  status: PropTypes.string,
};

const CompanyPlans = ({ company }) => {
  const dispatch = useDispatch();
  const { apiAddOns, addOnsById, isLoading } = useSelector((state) => {
    return {
      apiAddOns: state.addOns.addOns,
      addOnsById: state.addOns.addOnsById,
      isLoading: state.addOns.isLoading,
    };
  });
  const { plan, productPlans = {}, id: companyId } = company;
  const { addOns: companyAddOns } = company;
  const [addOns, setAddOns] = useState([...companyAddOns]);
  const [confirmChangeStatus, setConfirmChangeStatus] = useState(
    CONFIRMATION_STATUS.INITIAL,
  );
  const [enabledDiff, setEnabledDiff] = useState([]);
  const [disabledDiff, setDisableDiff] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    dispatch(AddOnActions.getAddOns(companyId));
  }, [companyId, dispatch]);

  useEffect(() => {
    setHasChanges(areArraysDifferent(companyAddOns, addOns));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyAddOns]);

  useEffect(() => {
    if (confirmChangeStatus === CONFIRMATION_STATUS.CONFIRMED) {
      handleUpdateAddOns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmChangeStatus]);

  const handleToggleAddOn = (addOn) => {
    const index = addOns.indexOf(addOn);
    const addOnsCopy = [...addOns];
    if (index > -1) {
      addOnsCopy.splice(index, 1);
    } else {
      addOnsCopy.push(addOn);
    }

    setAddOns(addOnsCopy);
    setHasChanges(areArraysDifferent(addOnsCopy, companyAddOns));
  };

  const renderOptions = (option) => {
    const { id, name, plan: optPlan } = option;
    const isChecked = addOns.includes(id);
    const isLocked = isAddonLocked(option, addOns);

    return (
      <li key={id}>
        {isLocked ? (
          <div className={styles.locked}>
            <span className={styles.iconContainer}>
              <LockedIcon className={styles.lockedIcon} />
            </span>
            {name}
          </div>
        ) : (
          <Fragment>
            <Checkbox
              id={id}
              aria-label={name}
              checked={isChecked}
              onChange={() => handleToggleAddOn(id)}
              disabled={isLocked}
              disabledLabel="Disabled"
              type="checkbox"
            >
              <span>
                {name}
                {id === 'health_wellbeing' ? (
                  <PlanBadge plan={optPlan} type="label" />
                ) : id === 'sensitive_attributes' ? (
                  <PlanBadge plan="include" type="label" />
                ) : null}
              </span>
            </Checkbox>
          </Fragment>
        )}
      </li>
    );
  };

  const onRadioChange = (value) => {
    // we only want to allow one custom driver option to be chosen at at time
    const newAddOns = addOns.filter((d) => !customDriverArray.includes(d));

    if (value !== 'no_custom_driver') {
      newAddOns.push(value);
    }

    setAddOns(newAddOns);
    setHasChanges(areArraysDifferent(companyAddOns, newAddOns));
  };

  const renderPlans = () => {
    if (apiAddOns === null) {
      return null;
    }

    const betaAddOns = apiAddOns.filter(
      (addOn) =>
        addOn.beta === true &&
        !QUESTION_SETS.includes(addOn.id) &&
        !addOn.subContractor,
    );

    const betaSubContractors = apiAddOns.filter(
      (addOn) => addOn.beta === true && addOn.subContractor,
    );

    return SORTED_PLANS.map((sortedPlan) => {
      const isQuestionSetPlan = sortedPlan === 'question-set';

      const planAddOns = apiAddOns.filter((addOn) => {
        const isAlwaysOn = DEPRECATED_ALWAYS_ON.includes(addOn.id);
        const isEnabled = companyAddOns.includes(addOn.id);
        const isCustomDriverQuestion = customDriverArray.includes(addOn.id);
        const isQuestionSet = QUESTION_SETS.includes(addOn.id);

        return (
          !isCustomDriverQuestion &&
          addOn.plan === sortedPlan &&
          !addOn.subContractor &&
          !addOn.beta &&
          !isQuestionSet &&
          !(isAlwaysOn && isEnabled)
        );
      });

      const planSubContractors = apiAddOns.filter(
        (addOn) =>
          addOn.plan === sortedPlan && addOn.subContractor && !addOn.beta,
      );

      const questionSets = apiAddOns.filter((addOn) =>
        QUESTION_SETS.includes(addOn.id),
      );

      // have engagement go first
      const first = 'engagement';
      const sortedQuestionSets = questionSets.sort((a, b) => {
        return a.id === first ? -1 : b.id === first ? 1 : 0;
      });

      let customDriverOption;
      customDriverOption = addOns.find((addOn) =>
        customDriverArray.includes(addOn),
      );

      if (!customDriverOption) {
        customDriverOption = 'no_custom_driver';
      }

      if (isQuestionSetPlan) {
        return (
          <div key={sortedPlan} className={styles.stacked}>
            <View className={classnames(styles.plan, styles.questionSet)}>
              <PlanBadge plan={sortedPlan} type="full" />

              <View className={styles.addOns}>
                <ul>
                  {sortedQuestionSets.map((option) => {
                    return renderOptions(option);
                  })}
                </ul>
              </View>

              {planSubContractors.length > 0 && (
                <View className={styles.subContractors}>
                  <span className={styles.subContractorText}>
                    General add-ons with sub-contractors
                  </span>
                  <ul>
                    {planSubContractors.map((option) => {
                      return renderOptions(option);
                    })}
                  </ul>
                  <span className={styles.notice}>
                    Please consult with the Podio Contracts app, or the account
                    sales rep, to confirm that the customer has agreed to share
                    data with third-party providers before turning these
                    features on
                  </span>
                </View>
              )}

              <View className={styles.subContractors}>
                <span className={styles.subContractorText}>
                  Custom questions
                </span>
                <ul className={styles.radio}>
                  <RadioGroup
                    id="driver-custom-options"
                    value={customDriverOption}
                    onChange={(value) => onRadioChange(value)}
                  >
                    {CUSTOM_DRIVER_OPTIONS.map((option) => (
                      <RadioGroup.Radio
                        key={option.id}
                        value={option.id}
                        name="custom-options"
                      >
                        <span className={styles.radioButton}>
                          {option.name}
                        </span>
                        <PlanBadge plan={option.plan} type="label" />
                      </RadioGroup.Radio>
                    ))}
                  </RadioGroup>
                </ul>
              </View>
            </View>

            <View className={classnames(styles.plan, styles.questionSet)}>
              <PlanBadge plan="beta-features" type="full" />
              <View className={styles.addOns}>
                <ul>
                  {betaAddOns.map((option) => {
                    return renderOptions(option);
                  })}
                </ul>
              </View>

              {betaSubContractors.length > 0 && (
                <View className={styles.subContractors}>
                  <span className={styles.subContractorText}>
                    General add-ons with sub-contractors
                  </span>
                  <ul>
                    {betaSubContractors.map((option) => {
                      return renderOptions(option);
                    })}
                  </ul>
                  <span className={styles.notice}>
                    Please consult with the Podio Contracts app, or the account
                    sales rep, to confirm that the customer has agreed to share
                    data with third-party providers before turning these
                    features on
                  </span>
                </View>
              )}
            </View>
          </div>
        );
      }

      return (
        <View
          key={sortedPlan}
          className={classnames(styles.plan, {
            [styles.questionSet]: isQuestionSetPlan,
          })}
        >
          <PlanBadge plan={sortedPlan} type="full" />
          <View className={styles.addOns}>
            <ul>
              {planAddOns.map((option) => {
                return renderOptions(option);
              })}
            </ul>
          </View>
          {planSubContractors.length > 0 && (
            <View className={styles.subContractors}>
              <span className={styles.subContractorText}>
                General add-ons with sub-contractors
              </span>
              <ul>
                {planSubContractors.map((option) => {
                  return renderOptions(option);
                })}
              </ul>
              <span className={styles.notice}>
                Please consult with the Podio Contracts app, or the account
                sales rep, to confirm that the customer has agreed to share data
                with third-party providers before turning these features on
              </span>
            </View>
          )}
        </View>
      );
    });
  };

  const handleUpdateAddOns = () => {
    const prevAddons = companyAddOns;

    const enabledAddons = difference(addOns, prevAddons);
    const disabledAddons = difference(prevAddons, addOns);

    const requireConfirmationAddons = [];
    const fnEnabledDiff = [];
    const fnDisabledDiff = [];

    for (const enabledAddon of enabledAddons) {
      if (REQUIRE_ENABLE_CONFIRMATION_ADDONS.has(enabledAddon)) {
        requireConfirmationAddons.push(enabledAddon);
        fnEnabledDiff.push(addOnsById.get(enabledAddon));
      }
    }

    for (const disabledAddon of disabledAddons) {
      if (REQUIRE_DISABLE_CONFIRMATION_ADDONS.has(disabledAddon)) {
        requireConfirmationAddons.push(disabledAddon);
        fnDisabledDiff.push(addOnsById.get(disabledAddon));
      }
    }

    if (
      requireConfirmationAddons.length > 0 &&
      confirmChangeStatus !== CONFIRMATION_STATUS.CONFIRMED
    ) {
      setConfirmChangeStatus(CONFIRMATION_STATUS.REQUIRES_CONFIRMATION);
      setEnabledDiff(fnEnabledDiff);
      setDisableDiff(fnDisabledDiff);
    } else {
      setEnabledDiff([]);
      setDisableDiff([]);
      setConfirmChangeStatus(CONFIRMATION_STATUS.INITIAL);
      dispatch(CompanyActions.updateAddOns(companyId, addOns));
    }
  };

  const handleReset = () => {
    setAddOns(companyAddOns);
    setHasChanges(false);
  };

  return (
    <View className={styles.container}>
      {!isLoading && (
        <View className={styles.productPlans}>
          {productPlans ? (
            Object.keys(productPlans).map((product) => {
              const PRODUCT_MAP = {
                engage: 'Engage',
                include: 'Include',
              };

              return (
                <div key={product}>
                  <Typography.H1>
                    {PRODUCT_MAP[product] ? PRODUCT_MAP[product] : product}
                  </Typography.H1>
                  <div>
                    <PlanBadge plan={productPlans[product]} />
                  </div>
                </div>
              );
            })
          ) : (
            <Typography.H1>{plan || 'Legacy'}</Typography.H1>
          )}
        </View>
      )}
      <View>
        {isLoading ? (
          <div className={styles.spinner}>
            <Spinner size="48" />
          </div>
        ) : (
          <View className={styles.plans}>{renderPlans()}</View>
        )}
      </View>

      <AddonChangesModal
        enabledDiff={enabledDiff}
        disabledDiff={disabledDiff}
        status={confirmChangeStatus}
        onChange={(checked) =>
          setConfirmChangeStatus(
            checked
              ? CONFIRMATION_STATUS.CONFIRMING
              : CONFIRMATION_STATUS.REQUIRES_CONFIRMATION,
          )
        }
        onConfirm={() => {
          setConfirmChangeStatus(CONFIRMATION_STATUS.CONFIRMED);
        }}
        onClose={() => {
          setConfirmChangeStatus(CONFIRMATION_STATUS.INITIAL);
        }}
      />

      {hasChanges && (
        <View
          className={classnames(styles.bar, {
            [styles.barVisible]: hasChanges,
          })}
        >
          <View className={styles.barContent}>
            <Button onClick={handleUpdateAddOns} disabled={!hasChanges}>
              Save Changes
            </Button>
            {hasChanges && (
              <span className={styles.cancel} onClick={handleReset}>
                Cancel
              </span>
            )}
          </View>
        </View>
      )}
    </View>
  );
};

CompanyPlans.propTypes = {
  company: PropTypes.object,
};

export default CompanyPlans;
