import { combineReducers } from 'redux';

const initialState = {
  config: null,
  isLoading: true,
};

const saml = (state = initialState, action) => {
  switch (action.type) {
    case 'SAML_CONFIG_READ_LOADING': {
      return initialState;
    }
    case 'SAML_CONFIG_READ_SUCCESS': {
      return {
        config: action.data.data.attributes,
        isLoading: false,
      };
    }
    case 'SAML_CONFIG_READ_FAILED': {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  saml,
});
