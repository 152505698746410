const INITIAL_STATE = {
  isLoading: false,
  audits: [],
};

const audit = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'AUDITS_EMPLOYEE_READ_LOADING':
    case 'AUDITS_COMPANY_READ_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'AUDITS_EMPLOYEE_READ_SUCCESS':
    case 'AUDITS_COMPANY_READ_SUCCESS': {
      const { data, links, continuation } = action.data;

      let audits;

      if (continuation) {
        audits = [...state.audits, ...data];
      } else {
        audits = [...data];
      }

      return {
        ...state,
        isLoading: false,
        audits,
        links,
      };
    }
    case 'AUDITS_OBJECTTYPES_READ_SUCCESS': {
      return {
        ...state,
        objectTypes: action.data,
      };
    }
    case 'AUDITS_RESET': {
      return {
        ...INITIAL_STATE,
      };
    }
    default:
      return state;
  }
};

export default audit;
