import slice from 'lodash/slice';

const RECENT_COUNT = 5;

const recent = (state = [], action) => {
  switch (action.type) {
    case 'COMPANY_READ_SUCCESS': {
      const {
        id,
        data: {
          attributes: { name, logo, premiumStatus, plan, status },
          relationships: { companySubdomains } = {},
        },
      } = action.data;

      const company = {
        id,
        name,
        logo,
        premiumStatus,
        plan,
        status,
        companySubdomains,
      };

      const existingIndex = state.findIndex((c) => c.id === id);
      if (existingIndex > -1) {
        state.splice(existingIndex, 1);
      }

      state.unshift(company);

      return slice(state, 0, RECENT_COUNT);
    }
    default:
      return state;
  }
};

export default recent;
