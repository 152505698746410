import React from 'react';

import PropTypes from 'prop-types';

import { View, Typography } from '@peakon/components';

import styles from './styles.css';

const Heading = ({ children }) => <Typography.H1>{children}</Typography.H1>;

const Actions = ({ children }) => (
  <View className={styles.actions}>{children}</View>
);

const CollectionHeader = ({ children }) => (
  <View className={styles.container}>
    <View className={styles.content}>{children}</View>
  </View>
);

CollectionHeader.propTypes = {
  children: PropTypes.node,
};

Heading.propTypes = CollectionHeader.propTypes;
Actions.propTypes = CollectionHeader.propTypes;

CollectionHeader.Heading = Heading;
CollectionHeader.Actions = Actions;

export default CollectionHeader;
