import React, { Component } from 'react';

import random from 'lodash/random';

import { View } from '@peakon/components';

import Confetti from '../../assets/confetti.svg';
import { HELLO_CONSTANTS } from '../../constants/HelloConstants';

import styles from './styles.css';

const getRandomNumber = () => random(0, HELLO_CONSTANTS.length - 1);

class Banner extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      randomNumber: getRandomNumber(),
    };
  }

  render() {
    const { randomNumber } = this.state;
    const greeting = HELLO_CONSTANTS[randomNumber];

    return (
      <View className={styles.banner}>
        <Confetti className={styles.confetti} />
        <View className={styles.container}>
          <View className={styles.content}>
            <View className={styles.text} onClick={this.updateRandomNumber}>
              <span>{greeting}</span>
            </View>
          </View>
        </View>
      </View>
    );
  }

  updateRandomNumber = () => {
    this.setState({ randomNumber: getRandomNumber() });
  };
}

export default Banner;
