import React, { Component } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getDashboardURL } from 'utils/getDashboardURL';

import { NavigationBasicNavigationChevronRightIcon as ArrowRight } from '@peakon/bedrock/icons/system';
import { LinkButton } from '@peakon/bedrock/react/button';
import { View } from '@peakon/components';

import CompanyInfo from '../CompanyInfo';

import styles from './styles.css';

const CardBodyItem = ({ title, value }) => {
  if (!value) {
    return null;
  } else {
    return (
      <div className={styles.cardBodyItem}>
        <div className={styles.cardBodyItemTitle}>{title.toUpperCase()}</div>
        <div className={styles.cardBodyItemContent}>{value}</div>
      </div>
    );
  }
};

CardBodyItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default class CompanyCard extends Component {
  static propTypes = {
    company: PropTypes.object,
  };

  render() {
    const {
      company: {
        id,
        name,
        employeeCount,
        nextOpen,
        lastOpen,
        roundCount,
        accessPossible,
        subdomains,
      },
    } = this.props;

    const primarySubdomain = subdomains?.find((subdomain) => {
      return subdomain.primary;
    })?.subdomain;

    return (
      <div className={styles.cardContainer}>
        <Link className={styles.cardHeaderContainer} to={`/companies/${id}`}>
          <CompanyInfo company={this.props.company} />
          <View className={styles.arrowContainer}>
            <ArrowRight className={styles.arrow} />
          </View>
        </Link>

        <div className={styles.cardBody}>
          <CardBodyItem title="people" value={employeeCount} />
          <CardBodyItem
            title="next survey"
            value={nextOpen ? moment(nextOpen).utc().format('ll') : null}
          />
          <CardBodyItem
            title="last survey"
            value={lastOpen ? moment(lastOpen).utc().format('ll') : null}
          />
          <CardBodyItem title="total rounds" value={roundCount} />
        </div>
        {accessPossible && (
          <div className={styles.cardFooterContainer}>
            <div className={styles.loginButton}>
              <LinkButton
                size="small"
                variant="primary"
                href={getDashboardURL({
                  pathname: '/login',
                  searchParams: { company_id: id },
                  subdomain: primarySubdomain,
                })}
                rel="noopener noreferrer"
                target="_blank"
              >
                Log in as {name}
              </LinkButton>
            </div>
          </div>
        )}
      </div>
    );
  }
}
