import React, { useState } from 'react';

import { showErrorNotification } from 'actions/NotificationActions';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox, Button } from '@peakon/components';

import * as CompanyActions from '../../../actions/CompanyActions';
import env from '../../../client/env';

import styles from './styles.css';

const Domains = ({ company }) => {
  const dispatch = useDispatch();
  const [allowAllDomains, setAllowAllDomains] = useState(
    company?.allowAllDomains,
  );
  const id = company?.id;
  const domains = company?.domains;
  const name = company?.name;

  const handleDomainRemove = (domain) => {
    if (confirm(`Are you sure you want to remove the domain ${domain}?`)) {
      dispatch(CompanyActions.removeDomain(id, domain));
    }
  };

  const handleDomainAdd = () => {
    const domain = prompt('Enter new domain:');

    if (domain) {
      dispatch(CompanyActions.addDomain(id, domain));
    }
  };

  const toggleAllowAllDomains = (allowAll) => {
    setAllowAllDomains(allowAll);

    const body = {
      data: {
        type: 'companies',
        attributes: {
          allowAllDomains: Boolean(allowAll),
        },
      },
    };

    dispatch(CompanyActions.update(id, body));
  };

  return (
    <div className={styles.block}>
      <h2>Domains</h2>
      {domains.length === 0 && <p>{name} does not have any domains.</p>}
      {domains.length > 0 && (
        <table>
          <tbody>
            {domains.map((domain) => {
              return (
                <tr key={domain}>
                  <td>{domain}</td>
                  <td>
                    <Button
                      type="muted"
                      size="small"
                      onClick={() => handleDomainRemove(domain)}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <div className={styles.allowAllDomains}>
        <Checkbox checked={allowAllDomains} onChange={toggleAllowAllDomains}>
          Allow all domains
        </Checkbox>
      </div>
      <Button size="small" onClick={handleDomainAdd}>
        Add domain
      </Button>
    </div>
  );
};

Domains.propTypes = {
  company: PropTypes.object,
};

const EmailDomains = ({ company }) => {
  const dispatch = useDispatch();
  const companyMailDomains = useSelector((state) => state.company.mailDomains);

  const id = company?.id;
  const name = company?.name;

  const handleEmailDomainRemove = ({
    id: emailDomainId,
    name: emailDomainName,
  }) => {
    if (
      confirm(
        `Are you sure you want to remove the email domain ${emailDomainName}?`,
      )
    ) {
      dispatch(CompanyActions.removeEmailDomain(id, emailDomainId));
    }
  };

  const handleEmailDomainAdd = () => {
    const emailDomain = prompt('Enter new email emailDomain:');
    const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailDomain) {
      if (domainPattern.test(emailDomain)) {
        dispatch(CompanyActions.addEmailDomain(id, emailDomain));
      } else {
        dispatch(showErrorNotification({ title: 'Invalid email domain' }));
      }
    }
  };

  return (
    <div className={styles.block}>
      <h2>Email Domains</h2>
      {!companyMailDomains?.length && (
        <p>{name} does not have any email domains.</p>
      )}
      {companyMailDomains?.length > 0 && (
        <table>
          <tbody>
            {companyMailDomains.map((emailDomain) => {
              return (
                <tr key={emailDomain.id}>
                  <td>{emailDomain.attributes.domain}</td>
                  <td>
                    <Button
                      type="muted"
                      size="small"
                      onClick={() =>
                        handleEmailDomainRemove({
                          id: emailDomain.id,
                          name: emailDomain.attributes.domain,
                        })
                      }
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <Button size="small" onClick={handleEmailDomainAdd}>
        Add email domain
      </Button>
    </div>
  );
};

EmailDomains.propTypes = {
  company: PropTypes.object,
};

const CompanyDomains = ({ company }) => {
  return (
    <div className={styles.container}>
      <Domains company={company} />
      {env.environment !== 'production' && <EmailDomains company={company} />}
    </div>
  );
};

CompanyDomains.propTypes = {
  company: PropTypes.object,
};

export default CompanyDomains;
