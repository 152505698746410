const INITIAL_STATE = {
  isLoading: false,
  testCompanies: [],
};

function flattenJsonapi(testCompany) {
  const { id, attributes, relationships } = testCompany;

  return {
    id,
    ...attributes,
    ...relationships,
  };
}

const testCompany = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'TEST_COMPANY_LIST_LOADING': {
      return {
        testCompanies: [],
        isLoading: true,
      };
    }
    case 'TEST_COMPANY_LIST_SUCCESS': {
      const { data, links } = action.data;

      return {
        testCompanies: data.map(flattenJsonapi),
        isLoading: false,
        links,
      };
    }
    case 'TEST_COMPANY_SEARCH_SUCCESS': {
      const { data, links } = action.data;

      const searchResult = data.map(flattenJsonapi);

      return {
        ...state,
        links,
        searchResult,
        isSearching: false,
        hasSearchResult: true,
      };
    }
    case 'TEST_COMPANY_SEARCH_RESET': {
      return {
        ...state,
        searchResult: [],
        isSearching: false,
        hasSearchResult: false,
      };
    }
    default:
      return state;
  }
};

export default testCompany;
