import React, { Fragment, useEffect } from 'react';

import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { View, Typography } from '@peakon/components';

import { getConsultants } from '../../../actions/CompanyActions';
import env from '../../../client/env';
import { REGION_HUMAN_MAP } from '../../../constants/RegionConstants';
import hasRequiredRight from '../../../utils/hasRequiredRight';

import styles from './styles.css';

const CardBodyItem = ({ title, value, description, className }) => {
  if (!value) {
    return null;
  } else {
    return (
      <div className={classnames(styles.cardBodyItem, className)}>
        <div className={styles.cardBodyItemTitle}>{title.toUpperCase()}</div>
        <div className={styles.cardBodyItemContent}>{value}</div>
        <div className={styles.cardBodyItemDescription}>{description}</div>
      </div>
    );
  }
};

CardBodyItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  description: PropTypes.string,
};

const CompanyOverview = ({ company }) => {
  const dispatch = useDispatch();
  const { session, consultants } = useSelector((state) => {
    return {
      session: state.session,
      consultants: state.company.consultants,
    };
  });
  const rights = session?.rights;
  const id = company?.id;

  useEffect(() => {
    if (id && hasRequiredRight(rights, ['backoffice:consultant:read'])) {
      dispatch(getConsultants(id));
    }
  }, [dispatch, rights, id]);

  const {
    name,
    employeeCount,
    employeeLimit,
    location,
    size,
    roundCount,
    nextOpen,
    lastOpen,
    lastAnswerAt,
    accessAllowed,
    accessRegions,
    customSenderDomains,
    deletedAt,
  } = company;

  const isDeleted = Boolean(deletedAt);

  return (
    <View>
      {isDeleted && (
        <View className={styles.block}>
          <Typography.H2>Company</Typography.H2>
          <Typography.Paragraph>
            This company was deleted{' '}
            <span className={styles.deletionTime}>
              {moment(deletedAt).utc().format('lll')}
            </span>
          </Typography.Paragraph>
        </View>
      )}
      {!isDeleted && (
        <Fragment>
          <View className={styles.block}>
            <Typography.H2>Company</Typography.H2>
            <View className={styles.cardBody}>
              <CardBodyItem
                title="People"
                value={employeeCount}
                description={
                  env.environment !== 'production'
                    ? `max. ${employeeLimit}`
                    : undefined
                }
              />
              <CardBodyItem title="Size" value={size} />
              {location && <CardBodyItem title="Location" value={location} />}
            </View>
          </View>
          <View className={styles.block}>
            <Typography.H2>Access</Typography.H2>
            <View className={styles.cardBody}>
              <CardBodyItem
                className={styles.csm}
                title="CSM"
                value={
                  consultants
                    ? consultants.map((c) => c.attributes.email).join('\r\n')
                    : '-'
                }
              />
              <CardBodyItem
                title="Allowed"
                value={
                  accessAllowed ? (
                    'Yes'
                  ) : (
                    <span className={styles.danger}>No</span>
                  )
                }
              />
              {accessAllowed && (
                <CardBodyItem
                  title="Regions"
                  value={
                    accessRegions === null
                      ? 'All'
                      : accessRegions
                          .map((region) => {
                            return REGION_HUMAN_MAP[region] || region;
                          })
                          .join(', ')
                  }
                />
              )}
            </View>
          </View>
          <View className={styles.block}>
            <Typography.H2>Survey</Typography.H2>
            {roundCount === 0 && (
              <span>{name} does not have any survey data.</span>
            )}
            {(roundCount > 0 || nextOpen) && (
              <View className={styles.cardBody}>
                <CardBodyItem
                  title="Next Survey"
                  value={nextOpen ? moment(nextOpen).format('ll') : null}
                />
                <CardBodyItem
                  title="Last Survey"
                  value={
                    <span>
                      {lastOpen ? moment(lastOpen).format('ll') : null}
                      {lastAnswerAt
                        ? ` - ${moment(lastAnswerAt).format('ll')}`
                        : null}
                    </span>
                  }
                />
                <CardBodyItem title="Total Rounds" value={roundCount} />
              </View>
            )}
          </View>
          {customSenderDomains?.length > 0 && (
            <View className={styles.block}>
              <Typography.H2>Custom Email Domains</Typography.H2>
              {customSenderDomains.map((csd) => {
                const {
                  active,
                  selector,
                  domain,
                  lastVerifiedAt,
                  verificationStatus,
                } = csd.attributes;

                const humanTime = (time) =>
                  moment.duration(moment(time).diff(moment())).humanize(true);

                const timeSinceLastChecked = lastVerifiedAt
                  ? humanTime(lastVerifiedAt)
                  : 'Never';
                return (
                  <tr key={csd.id}>
                    <View className={styles.block}>
                      <View className={styles.cardBody}>
                        <CardBodyItem title="Domain" value={domain} />
                        <CardBodyItem
                          title="Active"
                          value={active ? 'Yes' : 'No'}
                        />
                        {selector && (
                          <CardBodyItem title="Selector" value={selector} />
                        )}
                        <CardBodyItem
                          title="Verification status"
                          value={`${verificationStatus[0].toUpperCase()}${verificationStatus.slice(
                            1,
                          )}`}
                        />
                        <CardBodyItem
                          title="Last checked"
                          value={timeSinceLastChecked}
                        />
                      </View>
                    </View>
                  </tr>
                );
              })}
            </View>
          )}
        </Fragment>
      )}
    </View>
  );
};

CompanyOverview.propTypes = {
  company: PropTypes.object,
};

export default CompanyOverview;
