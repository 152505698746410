import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Avatar, View } from '@peakon/components';

import { getAbbreviation } from '../../utils/abbreviation';
import { getPlanForBadge } from '../../utils/company';
import getImgixUrl from '../../utils/getImgixUrl';
import PlanBadge from '../PlanBadge';

import styles from './styles.css';

const CompanyInfo = ({ company, size = 'medium' }) => {
  const { id, name, logo } = company;

  return (
    <View className={classnames(styles.container, styles[size])}>
      <View>
        <Avatar
          src={getImgixUrl({ src: logo, fit: 'crop', width: 56, height: 56 })}
          abbreviation={getAbbreviation(name)}
          rounded
          size={size}
        />
      </View>
      <View className={styles.name}>
        {name} &mdash; ID: {id}
      </View>
      <PlanBadge plan={getPlanForBadge(company)} />
    </View>
  );
};

CompanyInfo.propTypes = {
  company: PropTypes.object,
  size: PropTypes.oneOf(['small', 'medium']),
};

export default CompanyInfo;
