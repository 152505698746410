import { useState, useCallback } from 'react';

import jsonapiparser from '@peakon/jsonapiparser';

import peakonApi from '../client/peakonApi';

export function usePaginatedApi(props = {}) {
  const [lazyRequest, { data: latestData, loading, error, reset }] = useApi({
    ...props,
    keepPreviousData: true,
  });
  const [nextUrl, setNextUrl] = useState(null);
  const method = props.method;
  const hasMore = Boolean(nextUrl) && Boolean(latestData);

  const resetPagination = useCallback(() => {
    reset();
    setNextUrl(null);
  }, [reset]);

  const paginatedLazyRequest = useCallback(
    async (...args) => {
      try {
        const response = await lazyRequest(...args);

        if (response && response.links) {
          setNextUrl(response.links.next);
        } else {
          setNextUrl(null);
        }

        return response;
      } catch (paginatedRequestError) {
        setNextUrl(null);
        throw paginatedRequestError;
      }
    },
    [lazyRequest],
  );

  const loadMore = useCallback(() => {
    if (hasMore && !loading) {
      return paginatedLazyRequest(nextUrl, method);
    }
  }, [nextUrl, loading, hasMore, method, paginatedLazyRequest]);

  return [
    paginatedLazyRequest,
    {
      // The standard `data` property is not used for paginated queries. Use `latestData`.
      data: undefined,
      loading,
      error,
      reset: resetPagination,
      latestData,
      loadMore,
      hasMore,
    },
  ];
}

function useApi({
  initialLoading = true,
  method = 'GET',
  keepPreviousData = false,
  client = peakonApi,
  jsonApiParse,
} = {}) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(initialLoading);
  const [error, setError] = useState(null);

  const reset = useCallback(() => {
    setData(null);
    setError(null);
    setLoading(initialLoading);
  }, [initialLoading]);

  const lazyRequest = useCallback(
    async (url, ...options) => {
      if (!keepPreviousData) {
        setData(null);
      }

      setError(null);
      setLoading(true);

      try {
        let response = await client[method.toLowerCase()](url, ...options);

        if (jsonApiParse) {
          response = jsonapiparser(response);
        }

        setData(response);
        setLoading(false);

        return response;
      } catch (lazyRequestError) {
        setLoading(false);
        setError(lazyRequestError);

        throw lazyRequestError;
      }
    },
    [method, keepPreviousData, jsonApiParse, client],
  );

  return [
    lazyRequest,
    {
      data,
      loading,
      error,
      reset,
    },
  ];
}

export default useApi;
