import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  View,
  Spinner,
  Select,
  Button,
  InputField,
  Label,
} from '@peakon/components';

import * as AccessActions from '../../actions/AccessActions';
import { ACCESS_ROLE_CONSTANTS } from '../../constants/AccessRoleConstants';
import { REGION_HUMAN_MAP } from '../../constants/RegionConstants';

import styles from './styles.css';

const ACCESS_ROLE_SELECT_OPTIONS = ACCESS_ROLE_CONSTANTS.map((r) => ({
  value: r,
  label: r,
}));

const REGION_HUMAN_MAP_OPTIONS = Object.keys(REGION_HUMAN_MAP).map((region) => {
  return {
    value: region,
    label: REGION_HUMAN_MAP[region],
  };
});

class BackofficeAccess extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      email: '',
      role: null,
      region: null,
    };
  }

  componentDidMount() {
    const {
      accessActions: { getAccounts },
    } = this.props;

    getAccounts();
  }

  render() {
    const { isLoading, accounts } = this.props;
    const { email, role, region } = this.state;

    return (
      <View className={styles.container}>
        {isLoading && <Spinner />}
        <View className={styles.grantAccess}>
          <form action="" onSubmit={this.handleGrantAccess}>
            <View className={styles.field}>
              <InputField
                label="Grant access"
                inputType="email"
                onChange={this.handleGrantAccessEmailChange}
                value={email}
                placeholder="email@peakon.com"
              />
            </View>
            <View className={styles.field}>
              <Label>Role</Label>
              <Select
                onChange={this.handleGrantAccessRoleChange}
                data-testid="value"
                value={role}
                options={ACCESS_ROLE_SELECT_OPTIONS}
                isClearable={false}
                placeholder="Select role.."
              />
            </View>
            <View className={styles.field}>
              <Label>Region</Label>
              <Select
                onChange={this.handleGrantAccessRegionChange}
                data-testid="region"
                value={region}
                options={REGION_HUMAN_MAP_OPTIONS}
                isClearable={false}
                placeholder="Select region.."
              />
            </View>
            <View className={styles.submit}>
              <Button disabled={isLoading || !this.hasValidNewAccount()}>
                Create
              </Button>
            </View>
          </form>
        </View>
        {!isLoading && accounts.length > 0 && (
          <table>
            <thead>
              <tr>
                <td>Email</td>
                <td>Role</td>
                <td>Region</td>
                <td>&nbsp;</td>
              </tr>
            </thead>
            <tbody>
              {accounts.map((account) => {
                const roleValue = ACCESS_ROLE_SELECT_OPTIONS.find(
                  (option) => option.value === account.role,
                );
                const regionValue = REGION_HUMAN_MAP_OPTIONS.find(
                  (option) => option.value === account.region,
                );

                return (
                  <tr key={account.id}>
                    <td>{account.email}</td>
                    <td className={styles.select}>
                      <Select
                        onChange={(selected) =>
                          this.handleAccountChange('role', selected, account)
                        }
                        value={roleValue}
                        options={ACCESS_ROLE_SELECT_OPTIONS}
                        isClearable={false}
                      />
                    </td>
                    <td className={styles.select}>
                      <Select
                        onChange={(selected) =>
                          this.handleAccountChange('region', selected, account)
                        }
                        value={regionValue}
                        options={REGION_HUMAN_MAP_OPTIONS}
                        isClearable={false}
                      />
                    </td>
                    <td>
                      <Button
                        size="small"
                        type="danger"
                        onClick={() => this.handleAccountDelete(account)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </View>
    );
  }

  handleAccountChange = (type, selected, account) => {
    const {
      accessActions: { udpate },
    } = this.props;

    const body = {
      role: account.role,
      region: account.region,
      [type]: selected.value,
    };

    udpate(account.id, body);
  };

  handleAccountDelete = (account) => {
    const {
      accessActions: { removeAccount },
    } = this.props;
    const { id, email } = account;

    const message = `Are you sure you want to revoke backoffice access for ${email}?`;

    if (confirm(message)) {
      removeAccount(id);
    }
  };

  handleGrantAccess = (syntheticEvent) => {
    syntheticEvent.preventDefault();

    const {
      accessActions: { addAccount },
    } = this.props;
    const { email, role, region } = this.state;

    addAccount(email, {
      role: role.value,
      region: region.value,
    });
  };

  handleGrantAccessEmailChange = (email) => {
    this.setState({ email });
  };

  handleGrantAccessRoleChange = (role) => {
    this.setState({ role });
  };

  handleGrantAccessRegionChange = (region) => {
    this.setState({ region });
  };

  hasValidNewAccount() {
    const { email, role, region } = this.state;

    return email && role && region;
  }
}

BackofficeAccess.propTypes = {
  accessActions: PropTypes.object,
  isLoading: PropTypes.bool,
  accounts: PropTypes.array,
};

BackofficeAccess.defaultProps = {
  isLoading: true,
  accounts: [],
};

const mapStateToProps = (state) => {
  const {
    access: { isLoading, accounts },
  } = state;

  return {
    isLoading,
    accounts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  accessActions: bindActionCreators(AccessActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackofficeAccess);
