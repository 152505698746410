import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button, Spinner, View } from '@peakon/components';

import {
  showSuccessNotification,
  showErrorNotification,
} from '../../../actions/NotificationActions';
import api from '../../../client/peakonApi';
import extractMessage from '../../../utils/extractMessage';

import styles from './styles.css';

const CompanySubdomains = ({ company }) => {
  const dispatch = useDispatch();
  const [companySubdomains, setCompanySubdomains] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCompanySubdomains = async () => {
      setIsLoading(true);
      try {
        const response = await api.get(`/companies/${company.id}/subdomains`);
        setCompanySubdomains(response.data);
      } catch (error) {
        dispatch(
          showErrorNotification({
            ...extractMessage(error),
            code: error.status,
          }),
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompanySubdomains();
  }, [company, dispatch]);

  const handleSubdomainRemove = async (subdomain) => {
    if (
      confirm(`Are you sure you want to remove the subdomain ${subdomain}?`)
    ) {
      try {
        await api.delete(`/companies/${company.id}/subdomains/${subdomain}`);
        dispatch(
          showSuccessNotification({
            title: `Subdomain ${subdomain} is deleted`,
          }),
        );
        window.location.reload();
      } catch (error) {
        dispatch(
          showErrorNotification({
            ...extractMessage(error),
            code: error.status,
          }),
        );
      }
    }
  };

  const setPrimarySubdomain = async (subdomain) => {
    if (
      confirm(
        `Are you sure you want to make the subdomain ${subdomain} primary?`,
      )
    ) {
      try {
        await api.patch(
          `/companies/${company.id}/subdomains/${subdomain}`,
          {},
          {
            primary: true,
          },
        );
        dispatch(
          showSuccessNotification({
            title: `Subdomain ${subdomain} is set as primary`,
          }),
        );
        window.location.reload();
      } catch (error) {
        dispatch(
          showErrorNotification({
            ...extractMessage(error),
            code: error.status,
          }),
        );
      }
    }
  };

  const handleSubdomainAdd = async () => {
    const subdomain = prompt('Enter new subdomain:');

    if (subdomain) {
      try {
        await api.put(`/companies/${company.id}/subdomains/${subdomain}`);
        dispatch(
          showSuccessNotification({
            title: `Subdomain ${subdomain} is added`,
          }),
        );
        window.location.reload();
      } catch (error) {
        dispatch(
          showErrorNotification({
            ...extractMessage(error),
            code: error.status,
          }),
        );
      }
    }
  };

  return (
    <div className={styles.container}>
      <h2>Subdomains</h2>
      {isLoading && <Spinner />}
      {!isLoading && companySubdomains.length === 0 && (
        <p>{company.name} does not have any subdomains.</p>
      )}
      {!isLoading && companySubdomains.length > 0 && (
        <table>
          <tbody>
            {companySubdomains.map((companySubdomain) => {
              return (
                <tr key={companySubdomain.attributes.subdomain}>
                  <td>
                    {companySubdomain.attributes.subdomain}&nbsp;
                    {companySubdomain.attributes.primary && (
                      <View className={styles.badge}>
                        <View className={styles.text}>Primary</View>
                      </View>
                    )}
                  </td>
                  <td>
                    <Button
                      type="danger"
                      size="small"
                      onClick={() =>
                        handleSubdomainRemove(
                          companySubdomain.attributes.subdomain,
                        )
                      }
                    >
                      Remove
                    </Button>
                    &nbsp;
                    {!companySubdomain.attributes.primary && (
                      <Button
                        type="danger"
                        size="small"
                        onClick={() =>
                          setPrimarySubdomain(
                            companySubdomain.attributes.subdomain,
                          )
                        }
                      >
                        Make primary
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <Button size="small" onClick={handleSubdomainAdd}>
        Add subdomain
      </Button>
    </div>
  );
};

CompanySubdomains.propTypes = {
  company: PropTypes.object,
};

export default CompanySubdomains;
