import jsonapiparser from '@peakon/jsonapiparser';

import { showSuccessNotification } from './NotificationActions';
import api from '../client/peakonApi';
import asyncDispatch from '../utils/asyncDispatch';

const FEATURE_FLIP_FIELDS = { companies: 'domains,+' };
const FEATURE_FLIP_INCLUDES = ['enabledCompanies', 'disabledCompanies'].join(
  ',',
);

export const list = () => async (dispatch) => {
  const params = {
    per_page: 100,
    fields: FEATURE_FLIP_FIELDS,
    include: FEATURE_FLIP_INCLUDES,
  };

  dispatch({ type: 'FEATURE_FLIP_LIST_CLEAR' });

  let result = await asyncDispatch({
    dispatch,
    resource: 'FEATURE_FLIP_LIST',
    action: api.get(`/feature_flips`, params).then(jsonapiparser),
  });

  // Just continue loading one big list, don't use pagination here for now
  while (result.links.next) {
    result = await asyncDispatch({
      dispatch,
      resource: 'FEATURE_FLIP_LIST',
      action: api.get(result.links.next).then(jsonapiparser),
    });
  }
};

export const read = (id) => (dispatch) => {
  const params = {
    fields: FEATURE_FLIP_FIELDS,
    include: FEATURE_FLIP_INCLUDES,
  };

  return asyncDispatch({
    dispatch,
    resource: 'FEATURE_FLIP_READ',
    data: { id },
    action: api.get(`/feature_flips/${id}`, params).then(jsonapiparser),
  });
};

export const create =
  (body = {}) =>
  (dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'FEATURE_FLIP_CREATE',
      action: api
        .post(
          `/feature_flips`,
          {
            fields: FEATURE_FLIP_FIELDS,
            include: FEATURE_FLIP_INCLUDES,
          },
          body,
        )
        .then((response) => {
          dispatch(
            showSuccessNotification({
              title: 'The feature flip has been created',
            }),
          );

          return response;
        })
        .then(jsonapiparser),
    });
  };

export const update =
  (id, body = {}) =>
  (dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'FEATURE_FLIP_UPDATE',
      action: api
        .put(
          `/feature_flips/${id}`,
          {
            fields: FEATURE_FLIP_FIELDS,
            include: FEATURE_FLIP_INCLUDES,
          },
          body,
        )
        .then((response) => {
          dispatch(
            showSuccessNotification({
              title: 'The feature flip has been updated',
            }),
          );

          return response;
        })
        .then(jsonapiparser),
    });
  };

export const destroy = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'FEATURE_FLIP_DELETE',
    action: api.delete(`/feature_flips/${id}`).then((response) => {
      dispatch(
        showSuccessNotification({
          title: 'The feature flip has been deleted',
        }),
      );

      return response;
    }),
  });
};
