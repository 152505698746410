import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { View } from '@peakon/components';

import styles from './styles.css';

const EmployeeStatusBadge = ({ status }) => (
  <View className={classnames(styles.badge, styles[status])}>
    <View className={styles.text}>{status}</View>
  </View>
);

EmployeeStatusBadge.propTypes = {
  status: PropTypes.oneOf(['anonymized', 'deleted']),
};

EmployeeStatusBadge.defaultProps = {
  status: null,
};

export default EmployeeStatusBadge;
