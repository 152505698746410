const INITIAL_STATE = {
  isLoading: false,
  responses: [],
  links: {},
};

const responses = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'RESPONSES_READ_LOADING': {
      return {
        isLoading: true,
      };
    }
    case 'RESPONSES_READ_FAILED': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'RESPONSES_READ_SUCCESS': {
      return {
        isLoading: false,
        responses: action.data.data.map((response) => {
          const { id, attributes, relationships } = response;

          return {
            id,
            ...attributes,
            ...relationships,
          };
        }),
        links: action.data.links,
      };
    }

    case 'RESPONSES_READ_NEXT_SUCCESS': {
      return {
        ...state,
        responses: [
          ...state.responses,
          ...action.data.data.map((response) => {
            const { id, attributes, relationships } = response;

            return {
              id,
              ...attributes,
              ...relationships,
            };
          }),
        ],
        links: action.data.links,
      };
    }

    case 'RESPONSES_RESET': {
      return {
        responses: [],
        isLoading: false,
        links: {},
      };
    }
    default:
      return state;
  }
};

export default responses;
