export const COMPANY_ACTIONS_MAP = {
  priorities: {
    title: 'Update priorities',
    notes: [
      'Re-calculates the priorities in the background',
      'Usually used after segment changes',
    ],
    confirmationMessage: 'Are you sure you want to recalculate priorities?',
    right: 'backoffice:dataset:admin',
  },
  topics: {
    title: 'Update topics',
    notes: [
      'Re-calculates the topics in the background',
      'Usually used after segment changes',
    ],
    confirmationMessage: 'Are you sure you want to update topics?',
    right: 'backoffice:dataset:admin',
  },
  notifications: {
    title: 'Update notifications',
    notes: ['Closes the round and calculates criticals and notifications'],
    confirmationMessage: 'Are you sure you want to update round?',
    right: 'backoffice:dataset:admin',
  },
  upgrade_engagement_questions: {
    title: 'Upgrade Engagement questions',
    notes: [
      'All standard Engagement questions for this company will be upgraded to the latest default set.',
      'All older standard Engagement questions will be deactivated.',
    ],
    confirmationMessage:
      'WARNING! All standard questions for this company will be upgraded to the latest default set. All older standard questions will be deactivated. Do you want to continue?',
    right: 'backoffice:question:admin',
  },
  upgrade_diversity_inclusion_questions: {
    title: 'Upgrade Diversity and Inclusion Questions',
    notes: [
      'All standard Diversity and Inclusion questions for this company will be upgraded to the latest default set.',
      'All older standard Diversity and Inclusion questions will be deactivated.',
    ],
    confirmationMessage:
      'WARNING! All standard Diversity and Inclusion questions for this company will be upgraded to the latest default set. All older standard questions will be deactivated. Do you want to continue?',
    right: 'backoffice:question:admin',
  },
  upgrade_health_wellbeing_questions: {
    title: 'Upgrade Health and Wellbeing Questions',
    notes: [
      'All standard Health and Wellbeing questions for this company will be upgraded to the latest default set.',
      'All older standard Health and Wellbeing questions will be deactivated.',
    ],
    confirmationMessage:
      'WARNING! All standard Health and Wellbeing questions for this company will be upgraded to the latest default set. All older standard questions will be deactivated. Do you want to continue?',
    right: 'backoffice:question:admin',
  },
  upgrade_text_questions: {
    title: 'Upgrade Open-Ended Questions',
    notes: [
      'All standard open-ended questions for this company will be upgraded to the latest default set.',
      'All older standard open-ended questions will be deactivated.',
    ],
    confirmationMessage:
      'WARNING! All standard open-ended questions for this company will be upgraded to the latest default set. All older standard questions will be deactivated. Do you want to continue?',
    right: 'backoffice:question:admin',
  },
  covid19_questions: {
    title: 'Upgrade COVID-19 questions',
    notes: [
      'All standard COVID-19 questions for this company will be upgraded to the latest default set.',
      'All older standard  COVID-19 questions will be deactivated.',
    ],
    confirmationMessage:
      'WARNING! All standard COVID-19 questions for this company will be upgraded to the latest default set. All older standard questions will be deactivated. Do you want to continue?',
    right: 'backoffice:question:admin',
  },
  'upgrade-sensitive': {
    title: 'Upgrade Sensitive Keyword List',
    notes: [
      'Upgrades the standard sensitive keywords and concepts to the latest version.',
      'Deletes standard keywords that are no longer on the list.',
      'Retains all custom keywords and concepts.',
    ],
    confirmationMessage:
      'Are you sure you want to upgrade all sensitive keywords and concepts?',
    predicate: ({ addOns }) => addOns.includes('sensitive_comments'),
    right: 'backoffice:sensitive:admin',
  },
  block: {
    title: 'Block access',
    notes: [
      'Prevents anyone in the company from accesssing their account.',
      'Used when a free trial expires or when the contract payment has not been paid.',
    ],
    confirmationMessage:
      'WARNING! All dashboard access will be denied and survey scheduled disabled. Do you want to continue?',
    right: 'backoffice:company:admin',
    predicate: ({ status }) => status === 'active',
    isDanger: true,
  },
  unblock: {
    title: 'Unblock access',
    right: 'backoffice:company:admin',
    predicate: ({ status }) => status === 'blocked',
  },
  delete: {
    title: 'Delete company',
    notes: [
      'Deleting a company is a DESTRUCTIVE action that cannot be undone.',
    ],
    confirmationMessage:
      'WARNING! Deleting a company is a DESTRUCTIVE action that cannot be undone. Are you sure you want to continue?',
    promptMessage: 'Type the full name of the company to confirm deletion.',
    right: 'backoffice:company:delete',
    isDanger: true,
  },
};
