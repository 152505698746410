import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from '@peakon/components';

import * as CompanyActions from '../../../actions/CompanyActions';
import { showErrorNotification } from '../../../actions/NotificationActions';
import env from '../../../client/env';
import { COMPANY_ACTIONS_MAP } from '../../../constants/CompanyActionsConstants';
import RightsFilter from '../../RightsFilter';

import styles from './styles.css';

const CompanyActionsComponent = ({ company }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCompanyActionClick = async (key) => {
    const { id, name } = company;

    const {
      updatePriorities,
      updateTopics,
      updateNotifications,
      upgradeEngagementQuestions,
      upgradeDiversityInclusionQuestions,
      upgradeHealthWellbeingQuestions,
      upgradeTextQuestions,
      upgradeCovid19Questions,
      upgradeSensitive,
      blockAccess,
      unblockAccess,
      deleteCompany,
    } = CompanyActions;

    const { confirmationMessage, promptMessage } = COMPANY_ACTIONS_MAP[key];

    if (confirmationMessage) {
      if (!window.confirm(confirmationMessage)) {
        return;
      }
    }

    switch (key) {
      case 'priorities': {
        dispatch(updatePriorities(id));
        break;
      }
      case 'topics': {
        dispatch(updateTopics(id));
        break;
      }
      case 'notifications': {
        dispatch(updateNotifications(id));
        break;
      }
      case 'upgrade_engagement_questions': {
        dispatch(upgradeEngagementQuestions(id));
        break;
      }
      case 'upgrade_diversity_inclusion_questions': {
        dispatch(upgradeDiversityInclusionQuestions(id));
        break;
      }
      case 'upgrade_health_wellbeing_questions': {
        dispatch(upgradeHealthWellbeingQuestions(id));
        break;
      }
      case 'upgrade_text_questions': {
        dispatch(upgradeTextQuestions(id));
        break;
      }
      case 'covid19_questions': {
        dispatch(upgradeCovid19Questions(id));
        break;
      }
      case 'upgrade-sensitive': {
        dispatch(upgradeSensitive(id));
        break;
      }
      case 'block': {
        dispatch(blockAccess(id));
        break;
      }
      case 'unblock': {
        dispatch(unblockAccess(id));
        break;
      }
      case 'delete': {
        const confirmedName = prompt(promptMessage);

        if (confirmedName?.toLowerCase() === name.toLowerCase()) {
          await dispatch(deleteCompany(id));

          history.push('/former-customers');
        } else {
          dispatch(
            showErrorNotification({
              title: 'The name entered did not match the company name',
            }),
          );
        }

        break;
      }
    }
  };

  return (
    <div className={styles.container}>
      <h2>Actions</h2>
      <div>
        {Object.keys(COMPANY_ACTIONS_MAP).map((companyAction) => {
          const { right, title, notes, predicate, loadingPredicate, isDanger } =
            COMPANY_ACTIONS_MAP[companyAction];

          const shouldRender = !predicate || (predicate && predicate(company));
          if (!shouldRender) {
            return null;
          }

          const type = isDanger ? 'danger' : 'secondary';
          const isLoading = loadingPredicate && loadingPredicate(this.props);

          return (
            <RightsFilter key={companyAction} rights={[right]}>
              <div className={styles.action}>
                <h3>{title}</h3>
                {notes && (
                  <ul>
                    {notes.map((note) => {
                      return <li key={note}>{note}</li>;
                    })}
                  </ul>
                )}
                {env.environment !== 'production' &&
                  companyAction === 'update_notiofications' && (
                    <Button
                      type={type}
                      size="small"
                      disabled={isLoading}
                      onClick={() => handleCompanyActionClick(companyAction)}
                    >
                      {title}
                    </Button>
                  )}
                {companyAction !== 'update_notifications' && (
                  <Button
                    type={type}
                    size="small"
                    disabled={isLoading}
                    onClick={() => handleCompanyActionClick(companyAction)}
                  >
                    {title}
                  </Button>
                )}
              </div>
            </RightsFilter>
          );
        })}
      </div>
    </div>
  );
};

CompanyActionsComponent.propTypes = {
  company: PropTypes.object,
};

export default CompanyActionsComponent;
