import { composeWithDevTools } from '@redux-devtools/extension';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import access from './reducers/access';
import addOns from './reducers/addOns';
import audit from './reducers/audits';
import benchmarks from './reducers/benchmarks';
import companies from './reducers/companies';
import company from './reducers/company';
import employee from './reducers/employee';
import featureFlips from './reducers/featureFlips';
import integrations from './reducers/integrations';
import notifications from './reducers/notifications';
import recent from './reducers/recent';
import responses from './reducers/responses';
import session from './reducers/session';
import testCompany from './reducers/testCompany';

const rootReducer = combineReducers({
  session,
  notifications,
  access,
  testCompany,
  companies,
  company,
  recent,
  integrations,
  employee,
  responses,
  addOns,
  benchmarks,
  audit,
  featureFlips,
});

const persisted = persistReducer(
  {
    key: 'peakon.backoffice',
    storage,
    whitelist: ['recent'],
  },
  rootReducer,
);

export default () => {
  const store = createStore(
    persisted,
    composeWithDevTools(applyMiddleware(thunk)),
  );

  const persistor = persistStore(store);

  return { store, persistor };
};
