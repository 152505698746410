import React, { useContext, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import PropType from 'prop-types';

import { Alert } from '@peakon/bedrock/react/alert';
import { View, Typography, Button } from '@peakon/components';
import jsonapiparser from '@peakon/jsonapiparser';

import useApi from '../../../hooks/useApi';
import TestCompanyForm from '../Form';
import { TestCompanyContext } from '../TestCompanyProvider';

import styles from '../Overview/styles.css';

const GMS_DEFAULT_PLAN = 'premier';
export default function ResetTestCompany({
  match: { params },
  history: { push },
}) {
  const { testCompany, setTestCompanyContext, showNotification } =
    useContext(TestCompanyContext);

  const [searchTestCompany] = useApi();
  const [patchTestCompanyClient] = useApi({
    method: 'PATCH',
  });
  const [resetTestCompanyClient] = useApi({
    method: 'POST',
  });
  const [generatePassword] = useApi({
    method: 'POST',
  });

  useEffect(() => {
    const checkTestCompany = async () => {
      if (isEmpty(testCompany.name !== '') || testCompany) {
        await searchForCompany(searchTestCompany, setTestCompanyContext, {
          testCompanyId: params.id,
        });
      }
    };
    checkTestCompany();
  }, []); // eslint-disable-line

  const handleReset = async (updatedTestCompany) => {
    try {
      await resetCompany(
        patchTestCompanyClient,
        resetTestCompanyClient,
        testCompany,
        updatedTestCompany,
      );
    } catch (error) {
      showNotification(
        `Resetting failed with: ${error.message} for company ${updatedTestCompany.name}`,
        true,
      );
      navigateToCompanies();
      return;
    }
    showNotification(`Test company ${updatedTestCompany.name} is being reset`);
    navigateToCompanies();
  };

  const handlePasswordGenerate = async (updatedTestCompany) => {
    await generatePassword(
      `/test_companies/${updatedTestCompany.id}/generate_password`,
    );
    navigateToCompanies();
  };

  const navigateToCompanies = () => {
    push('/test-companies');
  };
  return (
    <View className={styles.content}>
      <Typography.H1>Data settings for {testCompany.name}</Typography.H1>
      <Typography.Paragraph>
        Update/Change configuration for {testCompany.name}
      </Typography.Paragraph>
      <div className={styles.separator} />

      <TestCompanyForm
        onCancel={navigateToCompanies}
        onReset={handleReset}
        testCompany={testCompany}
      />

      <div className={styles.separator} />
      <View className={styles.container}>
        <Typography.H1>Reset company wide password</Typography.H1>
        <Typography.Paragraph>
          Regenerate password - This will update the password for all users of
          {testCompany.name}
        </Typography.Paragraph>
        <Alert
          heading="Warning!"
          headingLevel={2}
          variant="warning"
          role="status"
        >
          <Alert.Paragraph>This action cannot be undone!</Alert.Paragraph>
        </Alert>
        <Typography.Paragraph>
          Current company password:
          <strong> {testCompany.password ?? 'default'}</strong>
        </Typography.Paragraph>
        <Button
          size="small"
          type="danger"
          onClick={() => handlePasswordGenerate(testCompany)}
        >
          Generate password
        </Button>
      </View>
      <View className={styles.btnCancel}>
        <Button size="small" type="secondary" onClick={navigateToCompanies}>
          Cancel
        </Button>
      </View>
    </View>
  );
}

async function searchForCompany(client, setter, { testCompanyId }) {
  const result = await client(`/test_company/${testCompanyId}`);
  setter({
    id: testCompanyId,
    ...jsonapiparser(result).data.attributes,
  });
}

async function resetCompany(
  patchClient,
  resetClient,
  originalCompany,
  company,
) {
  company = {
    ...company,
    configName: company.configName.value,
    plan:
      company.configName.value === 'gms' ||
      company.configName.value === 'gmsBeta'
        ? GMS_DEFAULT_PLAN
        : company.plan.value,
  };

  if (
    company.employees !== originalCompany.employees ||
    company.weeks !== originalCompany.weeks ||
    company.configName !== originalCompany.configName ||
    company.plan !== originalCompany.plan
  ) {
    return jsonapiparser(
      await patchClient(
        `/test_companies/${company.id}`,
        {},
        {
          data: {
            type: 'test_companies',
            attributes: pick(company, [
              'weeks',
              'configName',
              'plan',
              'employees',
              'adminPassword',
            ]),
          },
        },
      ),
    );
  } else {
    await resetClient(`/test_companies/${company.id}/reset`);
  }
}

ResetTestCompany.propTypes = {
  match: PropType.object,
  history: PropType.object,
};
