import React, { Component } from 'react';

import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { LinkButton } from '@peakon/bedrock/react/button';
import { Spinner } from '@peakon/bedrock/react/spinner';
import { View, Button } from '@peakon/components';

import * as ResponseActions from '../../../actions/ResponseActions';
import RightsFilter from '../../RightsFilter';

import styles from './styles.css';

class ResponsesList extends Component {
  render() {
    const { responses, showEmployee, links } = this.props;

    return (
      <View className={styles.container}>
        <table>
          <thead>
            <tr>
              {showEmployee && <td>Employee</td>}
              <td>Status</td>
              <td>Round Status</td>
              <td>Round Type</td>
              <td>Round ID</td>
              <td>Round Opens At</td>
              <td>Round Closes At</td>
              <td>Sent at</td>
              <td>Reminded at</td>
              <td>Answered at</td>
              <td>Answers</td>
              <td>Token</td>
              <td>Kiosk code</td>
              <td>Via</td>
              <td>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            {responses.map((response) => {
              const {
                id,
                status,
                answeredAt,
                sendAt,
                sentAt,
                remindAt,
                answeredVia,
                lastRemindedAt,
                answerCount,
                tokens,
                kioskCode,
                link,
                employee: {
                  id: employeeId,
                  attributes: { name },
                },
                round,
              } = response;

              const isOpen = status === 'open';

              return (
                <tr key={id}>
                  {showEmployee && (
                    <td>
                      <Link to={`/employees/${employeeId}`}>{name}</Link>
                    </td>
                  )}
                  <td style={{ textTransform: 'capitalize' }}>{status}</td>
                  <td>{round.attributes.status}</td>
                  <td>{round.attributes.type}</td>
                  <td>{round.id}</td>
                  <td>{round.attributes.opensAt}</td>
                  <td>{round.attributes.closesAt}</td>
                  <td
                    className={classnames({ [styles.queued]: Boolean(sendAt) })}
                  >
                    {sendAt
                      ? moment.utc(sendAt).format('lll')
                      : sentAt
                        ? moment.utc(sentAt).format('lll')
                        : '-'}
                  </td>
                  <td
                    className={classnames({
                      [styles.queued]: Boolean(remindAt),
                    })}
                  >
                    {remindAt
                      ? moment.utc(remindAt).format('lll')
                      : lastRemindedAt
                        ? moment.utc(lastRemindedAt).format('lll')
                        : '-'}
                  </td>
                  <td>
                    {answeredAt ? moment.utc(answeredAt).format('ll') : '-'}
                  </td>
                  <td>{answerCount}</td>
                  <td style={{ width: 100 }}>
                    {tokens.length > 0 ? tokens.join(', ') : '-'}
                  </td>
                  <td>{kioskCode || '-'}</td>
                  <td>{answeredVia || '-'}</td>
                  <td className={styles.actions}>
                    {isOpen && tokens.length > 0 && link && (
                      <LinkButton size="small" variant="primary" href={link}>
                        View
                      </LinkButton>
                    )}
                    <RightsFilter hasRight={['backoffice:response:admin']}>
                      {isOpen && (
                        <Button
                          size="small"
                          type="muted"
                          onClick={() => this.handleResend(response)}
                        >
                          Resend
                        </Button>
                      )}
                    </RightsFilter>
                    <RightsFilter hasRight={['backoffice:response:admin']}>
                      {isOpen && (
                        <Button
                          size="small"
                          type="muted"
                          onClick={() => this.handleRemind(response)}
                        >
                          Remind
                        </Button>
                      )}
                    </RightsFilter>
                    <RightsFilter hasRight={['backoffice:response:admin']}>
                      {!isOpen && (
                        <Button
                          size="small"
                          type="muted"
                          onClick={() => this.handleOpen(response)}
                        >
                          Reopen
                        </Button>
                      )}
                    </RightsFilter>
                    <RightsFilter hasRight={['backoffice:response:clear']}>
                      {!isOpen && (
                        <Button
                          size="small"
                          type="muted"
                          onClick={() => this.handleClear(response)}
                        >
                          Clear
                        </Button>
                      )}
                    </RightsFilter>
                  </td>
                </tr>
              );
            })}
            {links?.next && (
              <tr>
                <td colSpan={100}>
                  <InView
                    onChange={(inView) => {
                      inView &&
                        this.props.responseActions.getNextResponses(links.next);
                    }}
                    className={styles.spinner}
                  >
                    <Spinner />
                  </InView>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </View>
    );
  }

  handleResend = (response) => {
    const {
      responseActions: { sendSurvey },
    } = this.props;

    if (confirm('A survey email will be sent to the employee. Are you sure?')) {
      sendSurvey(...this.getRefreshArgs(response));
    }
  };

  handleRemind = (response) => {
    const {
      responseActions: { sendReminder },
    } = this.props;

    const message =
      'A reminder email will be sent to the employee. Are you sure?';

    if (confirm(message)) {
      sendReminder(...this.getRefreshArgs(response));
    }
  };

  handleOpen = (response) => {
    const {
      responseActions: { open },
    } = this.props;

    const message =
      'This will delete all current answers for this response and reopen it. Are you sure?';

    if (confirm(message)) {
      open(...this.getRefreshArgs(response));
    }
  };

  handleClear = (response) => {
    const {
      responseActions: { clear },
    } = this.props;

    const message =
      'This will permanently delete all answers and comments for this response. Are you sure?';

    if (confirm(message)) {
      clear(...this.getRefreshArgs(response));
    }
  };

  getRefreshArgs = (response) => {
    const { token } = this.props;
    const {
      id,
      employee: { id: employeeId },
    } = response;

    let args;
    if (token.length > 0) {
      args = ['token', token];
    } else {
      args = ['employee', employeeId];
    }

    return [id, ...args];
  };
}

ResponsesList.propTypes = {
  responseActions: PropTypes.object,
  responses: PropTypes.array,
  token: PropTypes.string,
  showEmployee: PropTypes.bool,
  links: PropTypes.object,
};

ResponsesList.defaultProps = {
  responses: [],
  token: '',
  showEmployee: true,
  links: {},
};

const mapStateToProps = (state) => {
  const { responses, links } = state.responses;

  return {
    responses,
    links,
  };
};

const mapDispatchToProps = (dispatch) => ({
  responseActions: bindActionCreators(ResponseActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsesList);
