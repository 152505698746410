import React, { Component } from 'react';

import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { components } from 'react-select';
import { bindActionCreators } from 'redux';

import { View, Select, Avatar } from '@peakon/components';

import * as CompanyActions from '../../actions/CompanyActions';
import { getAbbreviation } from '../../utils/abbreviation';
import { getPlanForBadge } from '../../utils/company';
import getImgixUrl from '../../utils/getImgixUrl';
import PlanBadge from '../PlanBadge';

import styles from './styles.css';

export const Company = ({ company }) => {
  return (
    <div>
      <div>
        <strong>{company.name}</strong> &mdash; ID: {company.id}
      </div>
      {company.domains?.length && <div>{company.domains[0]}</div>}
    </div>
  );
};

Company.propTypes = {
  company: PropTypes.object.isRequired,
};

class CompanySearch extends Component {
  static propTypes = {
    companyActions: PropTypes.object,
    isSearching: PropTypes.bool,
    searchResult: PropTypes.array,
    handleSearchSelected: PropTypes.func,
  };

  static defaultProps = {
    isSearching: false,
    searchResult: [],
  };

  constructor(...args) {
    super(...args);

    this.state = {
      query: '',
    };

    this.search = debounce(this.search, 300);
  }

  componentWillUnmount() {
    const {
      companyActions: { resetSearch },
    } = this.props;

    this.search.cancel();
    resetSearch();
  }

  reset = () => {
    this.setState({
      query: '',
    });
  };

  search(q) {
    const {
      companyActions: { search },
    } = this.props;

    if (q.length === 0) {
      return;
    }

    search({
      q,
    });
  }

  onInputChange = (inputValue, { action }) => {
    if (action === 'menu-close') {
      return;
    }

    this.setState({ query: inputValue });
    this.search(inputValue);
  };

  onChange = (company) => {
    const { handleSearchSelected } = this.props;
    handleSearchSelected(company, this.reset);
  };

  renderSearchCompany = (props) => {
    const { data: company } = props;
    const { label: name, logo } = company;

    return (
      // getClassNames noop is required overwise it will throw a runtime error
      <components.Option {...props} key={company.id} getClassNames={() => {}}>
        <View
          style={{
            padding: '2.5px 0px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Avatar
            src={getImgixUrl({ src: logo, fit: 'crop', height: 58, width: 58 })}
            abbreviation={getAbbreviation(name)}
          />
          <View style={{ marginLeft: 10, marginRight: 10 }}>
            <Company company={company} />
          </View>
          <PlanBadge plan={getPlanForBadge(company)} />
        </View>
      </components.Option>
    );
  };

  render() {
    const { isSearching, searchResult } = this.props;

    const { query } = this.state;
    return (
      <View className={styles.search}>
        <Select
          placeholder="i.e. Kinetar"
          onChange={this.onChange}
          onInputChange={this.onInputChange}
          loadingMessagefunction={() => 'Loading....'}
          isLoading={isSearching}
          inputValue={query}
          value={query}
          noOptionsMessage={() =>
            query.length > 0 ? <span>No results found.</span> : null
          }
          options={searchResult.map((c) => {
            const { id, name, logo } = c;

            return {
              ...c,
              id,
              label: `${name} — ID: ${id}`,
              logo,
            };
          })}
          components={{ Option: this.renderSearchCompany }}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    companies: { isSearching, searchResult },
  } = state;

  return {
    isSearching,
    searchResult,
  };
};

const mapDispatchToProps = (dispatch) => ({
  companyActions: bindActionCreators(CompanyActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanySearch);
