const initialState = {
  isLoading: false,
  error: null,
  company: null,
  consultants: [],
  companySubdomains: [],
  isLoadingCreate: false,
  isLoadingMailDomains: false,
  mailDomains: [],
};

const company = (state = initialState, action) => {
  switch (action.type) {
    case 'COMPANY_READ_SUCCESS':
    case 'COMPANY_UPDATE_SUCCESS': {
      const {
        data: {
          id,
          attributes,
          relationships: { csm, companySubdomains } = {},
        },
      } = action.data;

      return {
        ...state,
        id,
        csm,
        companySubdomains,
        ...attributes,
      };
    }
    case 'COMPANY_GET_CONSULTANTS_LOADING': {
      return {
        ...state,
        isLoadingConsultants: true,
      };
    }
    case 'COMPANY_GET_CONSULTANTS_SUCCESS': {
      return {
        ...state,
        consultants: action.data.data,
        isLoadingConsultants: false,
      };
    }
    case 'COMPANY_ADMINS_READ_LOADING': {
      return {
        ...state,
        isLoadingAdmins: true,
      };
    }
    case 'COMPANY_ADMINS_READ_SUCCESS': {
      const admins = action.data.data.map((employee) => {
        const {
          id,
          attributes: { name },
          relationships: {
            account: {
              attributes: { email, lastSeenAt },
            },
          },
        } = employee;

        return {
          id,
          name,
          email,
          lastSeenAt,
        };
      });

      return {
        ...state,
        isLoadingAdmins: false,
        admins,
      };
    }
    case 'COMPANY_CREATE_LOADING': {
      return {
        ...state,
        isLoadingCreate: true,
      };
    }
    case 'COMPANY_CREATE_FAILED':
    case 'COMPANY_CREATE_SUCCESS': {
      return {
        ...state,
        isLoadingCreate: false,
      };
    }
    case 'COMPANY_MAIL_DOMAINS_READ_LOADING': {
      return {
        ...state,
        isLoadingMailDomains: true,
        mailDomains: [],
      };
    }
    case 'COMPANY_MAIL_DOMAINS_READ_SUCCESS': {
      const { data } = action.data;
      return {
        ...state,
        isLoadingMailDomains: false,
        mailDomains: data,
      };
    }
    case 'COMPANY_MAIL_DOMAINS_READ_FAILED': {
      return {
        ...state,
        isLoadingMailDomains: false,
        mailDomains: [],
      };
    }
  }

  return state;
};

export default company;
