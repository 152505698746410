import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Spinner, View } from '@peakon/components';
import { Button } from '@peakon/components/dist/Button';

import * as FeatureFlipActions from '../../../actions/FeatureFlipActions';
import FeatureFlipsList from '../List';

import styles from './styles.css';

class FeatureFlips extends Component {
  static propTypes = {
    history: PropTypes.object,
    featureFlipActions: PropTypes.object,
    featureFlips: PropTypes.array,
    isLoading: PropTypes.bool,
  };

  componentDidMount() {
    this.props.featureFlipActions.list();
  }

  navigateToFeatureFlip = (id) => {
    this.props.history.push(`/feature-flips/${id}`);
  };

  render() {
    const { featureFlips, isLoading } = this.props;

    if (isLoading || !featureFlips) {
      return <Spinner />;
    }

    return (
      <View className={styles.content}>
        <View>
          <Button onClick={() => this.navigateToFeatureFlip('new')}>
            New feature flip
          </Button>
          <FeatureFlipsList
            featureFlips={featureFlips}
            onSelect={(id) => this.navigateToFeatureFlip(id)}
          />
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  featureFlips: state.featureFlips.featureFlips,
  isLoading: state.featureFlips.isLoading,
  links: state.featureFlips.links,
});

const mapDispatchToProps = (dispatch) => ({
  featureFlipActions: bindActionCreators(FeatureFlipActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureFlips);
