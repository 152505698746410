import jsonapiparser from '@peakon/jsonapiparser';

import { showErrorNotification } from './NotificationActions';
import api from '../client/peakonApi';
import asyncDispatch from '../utils/asyncDispatch';

export const getSamlConfig = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'SAML_CONFIG_READ',
    action: api.get(`/saml/configuration/${id}`).then(jsonapiparser),
    showNotification: false,
  });
};

export const postSamlAssertionCheck =
  (companyId, samlAssertion) => async (dispatch) => {
    try {
      return await api.post(`/saml/check/${companyId}`, null, {
        SAMLResponse: samlAssertion,
      });
    } catch (err) {
      return dispatch(showErrorNotification({ title: err.message }));
    }
  };
