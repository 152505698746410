const INITIAL_STATE = {
  isLoading: false,
  addOns: null,
};

const addOns = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_ONS_READ_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'ADD_ONS_READ_SUCCESS': {
      const newAddOns = action.data.data.map((addOn) => {
        const { id, attributes } = addOn;

        return {
          id,
          ...attributes,
        };
      });
      const addOnsById = new Map(newAddOns.map((addOn) => [addOn.id, addOn]));

      return {
        addOns: newAddOns,
        addOnsById,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default addOns;
