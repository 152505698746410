import React from 'react';

import PropTypes from 'prop-types';

import { Button, FileInput, Label, Spinner } from '@peakon/components';

import styles from './styles.css';

function JsonInput(props) {
  const { onChange, file, name, progress, isUploading } = props;

  const chooseFile = async (e) => {
    if (!onChange) {
      return;
    }

    await onChange(e.target.files[0]);
  };

  const onRemove = () => {
    onChange(null);
  };

  const renderFile = () => {
    return (
      <Button
        className={styles.button}
        type="secondary"
        onClick={onRemove}
        stretched
      >
        {`${file.name} (Remove)`}
      </Button>
    );
  };

  const renderChooseFileButton = () => {
    const label = isUploading ? (
      <React.Fragment>
        <Spinner size="small" color="white" />
        &nbsp;{`Uploading... ${Math.round(progress)}%`}
      </React.Fragment>
    ) : (
      'Choose File'
    );

    return (
      <FileInput
        id={name}
        name={name}
        accept=".json"
        label={label}
        onChange={chooseFile}
        disabled={isUploading}
        stretched={true}
      />
    );
  };

  return (
    <div className={styles.picker}>
      <Label htmlFor={name}>{name}</Label>
      {file ? renderFile() : renderChooseFileButton()}
    </div>
  );
}

JsonInput.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  isUploading: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  progress: PropTypes.number,
};

export default JsonInput;
