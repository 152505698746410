import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import hasRequiredRight from '../../utils/hasRequiredRight';

export const RightsFilter = ({ hasRight, children }) => {
  if (typeof children === 'function') {
    return children(hasRight);
  }

  return hasRight ? children : null;
};

RightsFilter.propTypes = {
  hasRight: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

const mapStateToProps = ({ session }, { rights }) => {
  return {
    hasRight: session && hasRequiredRight(session.rights, rights),
  };
};

export default connect(mapStateToProps)(RightsFilter);
