import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { View, Button, InputField, Spinner } from '@peakon/components';

import { create } from '../../actions/CompanyActions';

import styles from './styles.css';

const Signup = () => {
  const isLoading = useSelector((state) => state.company.isLoadingCreate);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companySubdomain, setCompanySubdomain] = useState('');

  const dispatch = useDispatch();

  const handleSubmit = (syntheticEvent) => {
    syntheticEvent.preventDefault();

    const payload = {
      email,
      firstName,
      lastName,
      ...(companySubdomain === '' ? {} : { companySubdomain }),
    };

    dispatch(create(payload));
  };

  return (
    <View className={styles.container}>
      {isLoading && <Spinner />}
      <View className={styles.content}>
        <form action="" onSubmit={handleSubmit}>
          <View className={styles.field}>
            <InputField
              label="Email"
              inputType="text"
              onChange={setEmail}
              value={email}
              placeholder="bob.loblaw@loblaw.lawblog"
            />
          </View>
          <View className={styles.field}>
            <InputField
              label="First Name"
              inputType="text"
              onChange={setFirstName}
              value={firstName}
              placeholder="Bob"
            />
          </View>
          <View className={styles.field}>
            <InputField
              label="Last Name"
              inputType="text"
              onChange={setLastName}
              value={lastName}
              placeholder="Loblaw"
            />
          </View>
          <View className={styles.field}>
            <InputField
              label="Subdomain"
              inputType="text"
              onChange={setCompanySubdomain}
              value={companySubdomain}
              placeholder="kinetar"
            />
          </View>

          <View className={styles.submit}>
            <Button disabled={isLoading}>Create</Button>
          </View>
        </form>
      </View>
    </View>
  );
};

export default Signup;
