import clone from 'lodash/clone';
import has from 'lodash/has';
import identity from 'lodash/identity';
import merge from 'lodash/merge';
import pickBy from 'lodash/pickBy';
import qs from 'qs';

export const getDiffFromDefault = (a, b) => {
  return Object.keys(b).reduce((acc, current) => {
    if (a[current] !== b[current]) {
      acc[current] = b[current];
    }

    return acc;
  }, {});
};

export const getUrlQueryParams = (
  search,
  stateParams = {},
  defaultState = {},
) => {
  const currentQuery = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const diffFromDefault = getDiffFromDefault(defaultState, stateParams);

  const prevQuery = pickBy(currentQuery, (value, key) => {
    // Only preserve keys that are not in default state
    return !has(defaultState, key);
  });

  const mergedQuery = merge(prevQuery, diffFromDefault);

  return qs.stringify(pickBy(mergedQuery, identity), {
    addQueryPrefix: true,
  });
};

export const getStateFromQueryParams = (search, defaultState) => {
  const query = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const validFilters = Object.keys(defaultState);

  return Object.keys(query).reduce((acc, current) => {
    if (validFilters.includes(current)) {
      acc[current] = query[current];
    }

    return acc;
  }, clone(defaultState));
};
