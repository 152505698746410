import React, { Component } from 'react';

import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { components } from 'react-select';
import { bindActionCreators } from 'redux';

import { View, Spinner, Select, Avatar, Typography } from '@peakon/components';

import * as CompanyActions from '../../actions/CompanyActions';
import Banner from '../../components/Banner';
import CompanyCard from '../../components/CompanyCard';
import PlanBadge from '../../components/PlanBadge';
import RecentCompanies from '../../components/RecentCompanies';
import { getAbbreviation } from '../../utils/abbreviation';
import { getPlanForBadge } from '../../utils/company';
import getImgixUrl from '../../utils/getImgixUrl';

import styles from './styles.css';

class Overview extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    me: PropTypes.object,
    companies: PropTypes.array,
    companyActions: PropTypes.object,
    isLoading: PropTypes.bool,
    recentCompanies: PropTypes.array,
    isSearching: PropTypes.bool,
    searchResult: PropTypes.array,
  };

  static defaultProps = {
    isLoading: true,
    isSearching: false,
    searchResult: [],
  };

  constructor(...args) {
    super(...args);

    this.state = {
      query: '',
    };

    this.search = debounce(this.search, 300);
  }

  componentDidMount() {
    const { companyActions, session } = this.props;

    if (!session) {
      return;
    }

    const { accountId } = session;

    companyActions.list({ accountId });
  }

  componentWillUnmount() {
    const {
      companyActions: { resetSearch },
    } = this.props;

    resetSearch();
  }

  render() {
    const {
      me,
      companies,
      isLoading,
      recentCompanies,
      isSearching,
      searchResult,
    } = this.props;

    const { query } = this.state;

    return (
      <View>
        <Banner name={me && me.firstName} />
        <View className={styles.content}>
          <View className={styles.column}>
            <Typography.H1 className={styles.title}>
              My Companies {companies.length > 0 && `(${companies.length})`}
            </Typography.H1>
            {isLoading && <Spinner />}
            {!isLoading && companies.length === 0 && (
              <Typography.P>You don&apos;t have any companies.</Typography.P>
            )}
            {!isLoading &&
              companies.length > 0 &&
              companies.map((company) => {
                return <CompanyCard key={company.id} company={company} />;
              })}
          </View>
          <View className={styles.column}>
            <Typography.H1 className={styles.title}>Find company</Typography.H1>
            <View className={styles.search}>
              <Select
                placeholder="i.e. Kinetar"
                onChange={this.handleSearchSelected}
                onInputChange={this.search}
                loadingMessagefunction={() => 'Loading....'}
                isLoading={isSearching}
                noOptionsMessage={() =>
                  query.length > 0 ? <span>No results found.</span> : null
                }
                options={searchResult.map((c) => {
                  const { id, name, logo } = c;

                  return {
                    ...c,
                    id,
                    label: `${name} — ID: ${id}`,
                    logo,
                  };
                })}
                components={{ Option: this.renderSearchCompany }}
              />
            </View>

            <Link className={styles.link} to="/companies">
              See all companies
            </Link>

            {recentCompanies.length > 0 && (
              <View className={styles.recent}>
                <Typography.H2 className={styles.subtitle}>
                  Recent
                </Typography.H2>
                <RecentCompanies companies={recentCompanies} />
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }

  renderSearchCompany = (props) => {
    const { data: company } = props;

    const { label: name, logo } = company;

    return (
      // getClassNames noop is required overwise it will throw a runtime error
      <components.Option {...props} key={company.id} getClassNames={() => {}}>
        <View
          style={{
            padding: '2.5px 0px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Avatar
            src={getImgixUrl({ src: logo, fit: 'crop', height: 58, width: 58 })}
            abbreviation={getAbbreviation(name)}
          />
          <View style={{ marginLeft: 10, marginRight: 10 }}>{name}</View>
          <PlanBadge plan={getPlanForBadge(company)} />
        </View>
      </components.Option>
    );
  };

  handleSearchSelected = (selected) => {
    const {
      history: { push },
    } = this.props;
    const { id } = selected;

    push(`/companies/${id}`);
  };

  search = (q) => {
    this.setState({ query: q });

    if (q.length === 0) {
      return;
    }

    const {
      companyActions: { search },
    } = this.props;

    search({
      q,
    });
  };
}

const mapStateToProps = (state) => {
  const {
    session,
    companies: { items, isLoading, isSearching, searchResult },
    employee: { me },
    recent,
  } = state;

  return {
    session,
    me,
    companies: items,
    isLoading,
    recentCompanies: recent,
    isSearching,
    searchResult,
  };
};

const mapDispatchToProps = (dispatch) => ({
  companyActions: bindActionCreators(CompanyActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
