import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Spinner } from '@peakon/components';

import { scheduleAuditsExportByCompanyId } from '../../../actions/AuditActions';
import {
  showSuccessNotification,
  showErrorNotification,
} from '../../../actions/NotificationActions';
import useRealtimeChannel from '../../../hooks/useRealtimeChannel';
import DownloadButton from '../../DownloadButton';

import styles from './styles.css';

function ExportAudits({
  objectType,
  event,
  from,
  to,
  objectId,
  companyId,
  employee,
  hasInputError,
}) {
  const opts = { objectType, event, from, to, objectId, employee };

  const dispatch = useDispatch();

  const channel = useSelector(
    ({ session: { accountId } }) => `background-tasks/${accountId}`,
  );

  const [isExporting, setIsExporting] = useState(false);

  const backgroundTasksRealtimeChannel = useRealtimeChannel(channel, {
    autoConnect: true,
  });

  useEffect(() => {
    if (backgroundTasksRealtimeChannel) {
      backgroundTasksRealtimeChannel.on('complete', (result) => {
        setIsExporting(false);

        const message = (
          <React.Fragment>
            {result.filename}&nbsp;&nbsp;
            <DownloadButton title="Download" url={result.url} />
          </React.Fragment>
        );

        dispatch(
          showSuccessNotification({
            title: `Audit Export is ready`,
            message,
            duration: 5 * 60 * 1000, // the temporary download link survives for 5 mins
            dismissable: true,
          }),
        );
      });

      backgroundTasksRealtimeChannel.on('taskError', (result) => {
        setIsExporting(false);

        dispatch(
          showErrorNotification({
            title: `Audit Export failed`,
            message: result.error,
          }),
        );
      });
    }
  }, [backgroundTasksRealtimeChannel, dispatch]);

  const handleExport = (syntheticEvent) => {
    syntheticEvent.preventDefault();

    setIsExporting(true);

    dispatch(scheduleAuditsExportByCompanyId(companyId, opts)).catch(() => {
      setIsExporting(false);
    });
  };

  return (
    <div>
      <form onSubmit={handleExport}>
        <div className={styles.submit}>
          <Button buttonType="submit" disabled={hasInputError} stretched>
            {isExporting ? (
              <React.Fragment>
                <Spinner size="small" color="white" />
              </React.Fragment>
            ) : (
              'Export Audit to Excel'
            )}
          </Button>
        </div>
      </form>
    </div>
  );
}

ExportAudits.propTypes = {
  companyId: PropTypes.string,
  objectType: PropTypes.string,
  event: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  objectId: PropTypes.string,
  employee: PropTypes.string,
  hasInputError: PropTypes.bool,
};

export default ExportAudits;
