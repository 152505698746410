// This list should match all templates
// at @peakon/templates with unsubscribable=true
export const UNSUBSCRIBES_TEMPLATE = {
  acknowledge: false,
  adminRemoved: false,
  conversationNote: false,
  conversationReply: false,
  daily: false,
  feedbackDigest: false,
  feedbackNew: false,
  groupInvite: false,
  lowParticipation: false,
  scheduleReminder: false,
  surveyQuestion: false,
  surveyReactivation: false,
  surveyReminder: false,
};
