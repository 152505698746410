import React, { useState } from 'react';

import classnames from 'classnames';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button, Modal, InputField, TextArea, View } from '@peakon/components';

import { revealCommentIdentity } from '../../../../actions/CompanyActions';
import { showErrorNotification } from '../../../../actions/NotificationActions';

import styles from './styles.css';

const FormField = ({ className, children }) => {
  return <div className={classnames(styles.field, className)}>{children}</div>;
};

FormField.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

const IdentityModal = ({ identity, isOpen, onClose }) => {
  const { name, identifier, email } = identity ?? {};

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Employee details</Modal.Title>
        <Modal.CloseButton closeLabel="Close" onClick={onClose} />
      </Modal.Header>
      <Modal.Content>
        <p>
          <label className={styles.label}>Name:</label> {name}
        </p>
        <p>
          <label className={styles.label}>Email:</label> {email}
        </p>
        <p>
          <label className={styles.label}>Identifier:</label> {identifier}
        </p>
      </Modal.Content>
    </Modal>
  );
};

IdentityModal.propTypes = {
  identity: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

const RevealIdentity = ({ company }) => {
  const dispatch = useDispatch();

  const [reason, setReason] = useState('');
  const [commentId, setCommentId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [identity, setIdentity] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleReasonChange = (value) => {
    if (!hasValidReason(value)) {
      setCommentId('');
      setCompanyId('');
    }
    setReason(value);
  };

  const reset = () => {
    setReason('');
    setCommentId('');
    setCompanyId('');
  };

  const hasValidReason = (value) => {
    const trimmedValue = value.trim();
    return (
      trimmedValue && trimmedValue.length > 19 && trimmedValue.length < 4097
    );
  };

  const revealIdentity = async () => {
    if (companyId !== company.id) {
      dispatch(
        showErrorNotification({
          title: `The company id entered did not match the selected company ${company.name} (id: ${company.id})`,
        }),
      );
    } else {
      const message = `Are you sure you want to reveal the identity of who left the comment id: ${commentId} for company: ${company.name} (company id: ${companyId})`;
      if (confirm(message)) {
        const response = await dispatch(
          revealCommentIdentity(commentId, {
            companyId,
            reason,
          }),
        );

        if (!isNil(response)) {
          setIdentity(response?.data?.attributes);
          setShowModal(true);

          reset();
        }
      }
    }
  };

  return (
    <View className={styles.container}>
      <h3>Reveal Comment Identity</h3>
      <FormField>
        <TextArea
          rows={3}
          minLength={20}
          maxLength={4096}
          label="Reason (min. 20 chars)"
          placeholder="A reason is required for audit purposes"
          onChange={handleReasonChange}
          value={reason}
        />
      </FormField>
      <FormField>
        <InputField
          inputType="number"
          min={1}
          label="Comment Id"
          disabled={!hasValidReason(reason)}
          onChange={setCommentId}
          value={commentId}
          required={true}
          onWheel={(event) => {
            event.target.blur();
          }}
        />
      </FormField>
      <FormField>
        <InputField
          inputType="number"
          min={1}
          label="Company Id"
          disabled={!hasValidReason(reason)}
          onChange={setCompanyId}
          value={companyId}
          required={true}
          onWheel={(event) => {
            event.target.blur();
          }}
        />
      </FormField>
      <FormField className={styles.actions}>
        <Button
          type="danger"
          size="small"
          onClick={revealIdentity}
          disabled={!hasValidReason(reason)}
        >
          Reveal Identity
        </Button>
      </FormField>

      <IdentityModal
        identity={identity}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </View>
  );
};

RevealIdentity.propTypes = {
  company: PropTypes.object,
};

export default RevealIdentity;
