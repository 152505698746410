import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button } from '@peakon/components';

import { sendDaily, sendPasswordReset } from '../../../actions/EmployeeActions';
import { EMPLOYEE_ACTIONS_MAP } from '../../../constants/EmployeeActionsConstants';
import RightsFilter from '../../RightsFilter';

import styles from './styles.css';

function EmployeeActions({ employee, email }) {
  const dispatch = useDispatch();

  const handleEmployeeActionClick = async (key) => {
    const { confirmationMessage } = EMPLOYEE_ACTIONS_MAP[key];

    if (confirmationMessage) {
      if (!window.confirm(confirmationMessage)) {
        return;
      }
    }

    switch (key) {
      case 'daily': {
        dispatch(sendDaily(employee.id));
        break;
      }
      case 'reset': {
        dispatch(sendPasswordReset(email, employee.company.id));
        break;
      }
    }
  };

  return (
    <div className={styles.container}>
      <h2>Actions</h2>
      <div>
        {Object.keys(EMPLOYEE_ACTIONS_MAP).map((employeeAction) => {
          const { right, title, notes, predicate, loadingPredicate, isDanger } =
            EMPLOYEE_ACTIONS_MAP[employeeAction];

          const shouldRender = !predicate || (predicate && predicate(employee));
          if (!shouldRender) {
            return null;
          }

          const type = isDanger ? 'danger' : 'secondary';
          const isLoading = loadingPredicate && loadingPredicate(this.props);

          return (
            <RightsFilter key={employeeAction} rights={[right]}>
              <div className={styles.action}>
                <h3>{title}</h3>
                {notes && (
                  <ul>
                    {notes.map((note) => {
                      return <li key={note}>{note}</li>;
                    })}
                  </ul>
                )}
                <Button
                  type={type}
                  size="small"
                  disabled={isLoading}
                  onClick={() => handleEmployeeActionClick(employeeAction)}
                >
                  {title}
                </Button>
              </div>
            </RightsFilter>
          );
        })}
      </div>
    </div>
  );
}

EmployeeActions.propTypes = {
  employee: PropTypes.object,
  email: PropTypes.string,
};

export default EmployeeActions;
