import startsWith from 'lodash/startsWith';
import qs from 'qs';

const SOURCES = {
  logo: {
    development: 'peakon-logo-development',
    staging: 'peakon-logo-staging',
    production: 'peakon-logo',
  },
  avatar: {
    development: 'peakon-avatar-development',
    staging: 'peakon-avatar-staging',
    production: 'peakon-avatar',
  },
};

const getImgixUrl = ({ src, fit = 'max', height = 200, width = 200 }) => {
  if (!src) {
    return;
  }

  let url;

  if (startsWith(src, 'http')) {
    url = src;
  } else {
    const slugs = src.split('/');
    const host = SOURCES[slugs[1]][slugs[0]];

    url = `https://${host}.imgix.net/${slugs[2]}`;
  }

  const params = qs.stringify({
    fit,
    w: width,
    h: height,
  });

  return `${url}?${params}`;
};

export default getImgixUrl;
