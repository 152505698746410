import io from 'socket.io-client';

import env from '../client/env';

let socket;

const RealtimeSocket = function () {
  if (socket) {
    return socket;
  }

  socket = io(env.realtime.api, {
    path: '/realtime/socket.io',
    autoConnect: false,
    transports: ['websocket', 'polling'],
    reconnection: true,
    reconnectionDelay: 30000,
    randomizationFactor: 0.6,
    reconnectionDelayMax: 60000,
    reconnectionAttempts: Infinity,
    withCredentials: true,
  });

  socket.on('connect_error', () => {
    if (socket.io.opts.transports[0] === 'websocket') {
      // Based on snippet from: https://socket.io/docs/v4/client-options/#transports
      // We failed to connect with the WebSocket transport, revert to polling mode with upgrade.
      socket.io.opts.transports = ['polling', 'websocket'];
      // We need to explicitly connect again because we've disabled autoConnect:
      socket.connect();
    }
  });

  socket.on('connect', () => {
    socket.connected = true;
  });

  socket.on('disconnect', () => {
    socket.connected = false;
  });

  return socket;
};

class RealtimeChannel {
  constructor(channel, { token, getToken } = {}) {
    this.socket = RealtimeSocket();

    this.channel = channel;
    this.listeners = [];
    this.joined = false;
    this.token = token;
    this.getToken = getToken;
  }

  _getToken() {
    if (typeof this.getToken === 'function') {
      return this.getToken();
    }

    return this.token;
  }

  isAuthenticated() {
    const token = this._getToken();

    return Boolean(token);
  }

  on(eventName, callback) {
    const fullEventName = `${this.channel}/${eventName}`;
    this.socket.on(fullEventName, callback);

    this.listeners.push({
      eventName: fullEventName,
      callback,
    });
  }

  join() {
    if (!this.socket.connected) {
      this.socket.connect();
    }
    if (!this.joined) {
      this.joined = true;

      this.joinChannel();

      // Add reconnect listener to rejoin channels
      this.socket.on('reconnect', this.onReconnect);
    }
  }

  joinChannel() {
    if (!this.isAuthenticated()) {
      return this.socket.emit(
        'error',
        `Trying to connect to ${this.channel} without being authenticated.`,
      );
    }

    this.socket.emit('joinChannel', {
      channel: this.channel,
      token: this._getToken(),
    });
  }

  leave() {
    if (this.joined) {
      this.joined = false;
      // Remove reconnect listener
      this.socket.removeListener('reconnect', this.onReconnect);

      // Remove event listeners
      this.listeners.forEach(({ eventName, callback }) =>
        this.socket.removeListener(eventName, callback),
      );

      this.leaveChannel();
    }
  }

  leaveChannel() {
    this.socket.emit('leaveChannel', {
      channel: this.channel,
    });
  }

  onReconnect() {
    if (this.joined && this.isAuthenticated()) {
      this.joinChannel();
    }
  }
}

export default RealtimeChannel;
