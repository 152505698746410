import mapValues from 'lodash/mapValues';

const initialState = {
  isLoading: false,
  error: null,
  featureFlip: null,
  featureFlips: [],
};

const formatFeatureFlip = (featureFlip) => {
  return {
    id: featureFlip.id,
    ...featureFlip.attributes,
    ...mapValues(featureFlip.relationships, (companies) =>
      companies.map((company) => ({
        id: company.id,
        ...company.attributes,
      })),
    ),
  };
};

const featureFlips = (state = initialState, action) => {
  switch (action.type) {
    case 'FEATURE_FLIP_LIST_LOADING': {
      return {
        ...state,
        festureFlips: [],
        isLoading: true,
      };
    }

    case 'FEATURE_FLIP_LIST_CLEAR': {
      return {
        ...state,
        featureFlips: [],
        isLoading: false,
      };
    }

    case 'FEATURE_FLIP_LIST_SUCCESS': {
      const { data, links } = action.data;

      return {
        ...state,
        featureFlips: [...data.map(formatFeatureFlip), ...state.featureFlips],
        links,
        isLoading: false,
      };
    }

    case 'FEATURE_FLIP_CREATE_LOADING':
    case 'FEATURE_FLIP_DELETE_LOADING':
    case 'FEATURE_FLIP_READ_LOADING': {
      return {
        ...state,
        featureFlip: null,
        isLoading: true,
      };
    }

    case 'FEATURE_FLIP_CREATE_SUCCESS':
    case 'FEATURE_FLIP_READ_SUCCESS':
    case 'FEATURE_FLIP_UPDATE_SUCCESS': {
      const { data } = action.data;

      const featureFlip = formatFeatureFlip(data);

      return {
        ...state,
        featureFlip,
        isLoading: false,
      };
    }
  }

  return state;
};

export default featureFlips;
