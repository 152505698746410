import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { NavigationBasicNavigationChevronRightIcon as ArrowRight } from '@peakon/bedrock/icons/system';
import { View } from '@peakon/components';

import CompanyInfo from '../CompanyInfo';

import styles from './styles.css';

class RecentCompanies extends Component {
  static propTypes = {
    companies: PropTypes.array,
  };

  static defaultProps = {
    companies: [],
  };

  render() {
    const { companies } = this.props;

    return (
      <View className={styles.container}>
        {companies.map((company) => {
          const { id } = company;

          return (
            <Link key={id} className={styles.company} to={`/companies/${id}`}>
              <CompanyInfo company={company} />
              <ArrowRight className={styles.arrow} />
            </Link>
          );
        })}
      </View>
    );
  }
}

export default RecentCompanies;
