import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { Button, InputField, View } from '@peakon/components';

import Logo from '../../assets/backoffice_temp_logo@2x.png';

import styles from '../Login/styles.css';

class TwoFactorPanel extends Component {
  static propTypes = {
    onCodeSubmit: PropTypes.func,
    onSendSms: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      token: '',
    };

    this.tokenInput = null;
  }

  componentDidMount() {
    if (this.tokenInput) {
      this.tokenInput.focus();
    }
  }

  render() {
    const { onCodeSubmit, onSendSms } = this.props;
    const { token } = this.state;

    return (
      <View className={styles.container}>
        <img className={styles.logo} src={Logo} />
        <form
          action="/"
          onSubmit={() => onCodeSubmit(token)}
          className={styles.form}
        >
          <View className={styles.input}>
            <InputField
              label="Token"
              inputType="text"
              placeholder="Authy token"
              name="code"
              onChange={(newToken) => this.setState({ token: newToken })}
              value={token}
              inputRef={(node) => (this.tokenInput = node)}
            />
          </View>
          <View className={styles.actions}>
            <Button
              async
              type="primary"
              onClick={() => onCodeSubmit(token)}
              disabled={token.length === 0}
            >
              Verify
            </Button>
            <Button async type="secondary" onClick={onSendSms}>
              Send as SMS
            </Button>
          </View>
        </form>
      </View>
    );
  }
}

export default TwoFactorPanel;
