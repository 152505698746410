import React from 'react';

import PropTypes from 'prop-types';
import { Switch, NavLink } from 'react-router-dom';

import { View, Tabs } from '@peakon/components';

import Overview from './Overview';
import PrivateRoute from '../PrivateRoute';

import styles from './styles.css';

const DevArea = ({ match }) => {
  return (
    <View className={styles.content}>
      <h1>Dev Area</h1>
      <p>All things considering dev.</p>
      <Tabs>
        <Tabs.TabList>
          <Tabs.Tab as={NavLink} to={match.path} title="Dev Area - Overview">
            Feature Checker
          </Tabs.Tab>
        </Tabs.TabList>
      </Tabs>
      <div className={styles.subrouteContent}>
        <Switch>
          <PrivateRoute path={match.path} exact component={Overview} />
        </Switch>
      </div>
    </View>
  );
};

DevArea.propTypes = {
  match: PropTypes.object,
};

export default DevArea;
