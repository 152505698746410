export const BENCHMARK_HIERARCHY = {
  Consumer: {
    'Automobiles & Components': ['Automotive'],
    'Consumer Discretionary': ['Consumer Discretionary'],
    'Consumer Durables & Apparel': [
      'Consumer Goods',
      'Household Durables',
      'Leisure Products',
      'Textiles, Apparel & Luxury Goods',
    ],
    'Consumer Services': [
      'Consumer Services',
      'Diversified Consumer Services',
      'Hotels, Restaurants & Leisure',
    ],
    'Diversified Consumer Services': [
      'Education Services',
      'Family Services',
      'Specialized Consumer Services',
    ],
    Media: ['Media'],
    Retailing: ['Distributors', 'Retailing', 'Specialty Retail'],
    'Consumer Staples': ['Consumer Staples'],
    'Food & Staples Retailing': ['Food & Staples Retailing'],
    'Food, Beverage & Tobacco': ['Beverages', 'Food Products', 'Tobacco'],
    'Household & Personal Products': ['Personal Products'],
  },
  Education: {},
  'Energy & Utilities': {
    'Energy Equipment & Services': ['Gas Utilities'],
    'Independent Power and Renewable Electricity Producers': [
      'Renewable Electricity',
    ],
    Utilities: ['Electric Utilities', 'Utilities'],
  },
  Finance: {
    Banks: ['Banks'],
    'Diversified Financials': [
      'Capital Markets',
      'Diversified Financial Services',
    ],
    Insurance: ['Insurance'],
    'Real Estate': ['Real Estate'],
  },
  Government: {},
  Healthcare: {
    'Health Care Equipment & Services': [
      'Health Care Equipment & Supplies',
      'Health Care Providers & Services',
    ],
    'Pharmaceuticals, Biotechnology & Life Sciences': [
      'Biotechnology',
      'Life Sciences Tools & Services',
      'Pharmaceuticals',
    ],
  },
  Manufacturing: {
    'Capital Goods': [
      'Aerospace & Defense',
      'Capital Goods',
      'Commercial Services & Supplies',
      'Construction & Engineering',
      'Electrical Equipment',
      'Industrial Conglomerates',
      'Machinery',
      'Trading Companies & Distributors',
    ],
    Industrials: ['Industrials'],
  },
  'Professional Services': {
    'Commercial & Professional Services': [
      'Commercial Services & Supplies',
      'Professional Services',
    ],
  },
  Transportation: {
    Transportation: [
      'Air Freight & Logistics',
      'Airlines',
      'Marine',
      'Road & Rail',
      'Transportation',
    ],
  },
  Technology: {
    'Semiconductors & Semiconductor Equipment': [
      'Semiconductors & Semiconductor Equipment',
    ],
    'Software & Services': [
      'Internet Software & Services',
      'IT Services',
      'Software',
    ],
    'Technology Hardware & Equipment': [
      'Communications Equipment',
      'Electronic Equipment, Instruments & Components',
      'Technology Hardware, Storage & Peripherals',
    ],
    'Telecommunication Services': [
      'Diversified Telecommunication Services',
      'Wireless Telecommunication Services',
    ],
  },
  Materials: {
    'Construction Materials': ['Building Materials'],
    Materials: [
      'Chemicals',
      'Containers & Packaging',
      'Metals & Mining',
      'Paper & Forest Products',
    ],
  },
  'Non-profit': {},
};
