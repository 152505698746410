import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { View } from '@peakon/components';

import * as FeatureFlipActions from '../../../actions/FeatureFlipActions';

class Overview extends Component {
  constructor(args) {
    super(args);
    this.state = { companyId: 1, selectedFeatureFlip: '', showResult: false };
  }

  componentDidMount() {
    const {
      featureFlipActions: { list },
    } = this.props;

    list();
  }

  onCompanyIdChange = (e) => {
    this.setState({ companyId: e.target.value });
  };

  onSelectedFeatureFlipChange = (e) => {
    this.setState({ selectedFeatureFlip: e.target.value });
  };

  onFormSubmit = (e) => {
    //  TODO: Server call
    e.preventDefault();
    this.setState({ showResult: true });
  };

  render() {
    const { featureFlips } = this.props;
    const { companyId, selectedFeatureFlip, showResult } = this.state;
    return (
      <View>
        <form onSubmit={this.onFormSubmit}>
          <span>Does company with ID: </span>
          <input
            type="text"
            value={companyId}
            onChange={this.onCompanyIdChange}
          />
          <span> have feature flip </span>
          <select
            value={selectedFeatureFlip}
            onChange={this.onSelectedFeatureFlipChange}
          >
            <option value="null">select feature flip...</option>
            {featureFlips.map((featureFlip) => {
              return (
                <option key={featureFlip.id} value={featureFlip.id}>
                  {featureFlip.name}
                </option>
              );
            })}
          </select>
          <button type="submit">Let me see!</button>
        </form>
        {showResult && <p className="is-positive">Yes, it does!</p>}
      </View>
    );
  }
}

Overview.propTypes = {
  featureFlipActions: PropTypes.object,
  featureFlips: PropTypes.array,
};

Overview.defaultProps = {};

const mapStateToProps = (state) => ({
  featureFlips: state.featureFlips.featureFlips,
});

const mapDispatchToProps = (dispatch) => ({
  featureFlipActions: bindActionCreators(FeatureFlipActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
