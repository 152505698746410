import React from 'react';

import PropTypes from 'prop-types';

function DownloadButton({ title, url }) {
  return (
    <a
      className="peak-btn peak-btn-primary peak-btn--sm"
      href={url}
      rel="noopener noreferrer"
      target="_blank"
    >
      {title}
    </a>
  );
}

DownloadButton.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
};

export default DownloadButton;
