import React from 'react';

import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { TabNavigation } from '@peakon/bedrock/react/navigation';

const NavLinkTab = ({ children, exact, to }) => {
  return (
    <TabNavigation.Link exact={exact} as={NavLink} to={to}>
      {children}
    </TabNavigation.Link>
  );
};

NavLinkTab.propTypes = {
  children: PropTypes.string,
  exact: PropTypes.bool,
  to: PropTypes.string,
};

export default NavLinkTab;
