import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

import { View } from '@peakon/components';

import Edit from './Edit';
import New from './New';
import Overview from './Overview';
import PrivateRoute from '../PrivateRoute';

import styles from './styles.css';

class FeatureFlips extends Component {
  static propTypes = {
    match: PropTypes.object,
  };

  render() {
    const {
      match: { path },
    } = this.props;

    return (
      <View className={styles.content}>
        <Switch>
          <PrivateRoute path={path} exact component={Overview} />
          <PrivateRoute path={`${path}/new`} component={New} />
          <PrivateRoute path={`${path}/:id`} component={Edit} />
        </Switch>
      </View>
    );
  }
}

export default FeatureFlips;
