const INITIAL_STATE = {
  isAuthenticated: false,
  requires2FA: false,
};

const session = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SESSION_READ_LOADING':
    case 'SESSION_2FA_SMS_LOADING':
    case 'SESSION_2FA_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'SESSION_READ_FAILED':
    case 'SESSION_2FA_FAILED': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'SESSION_READ_SUCCESS': {
      const {
        data: {
          id,
          attributes,
          relationships: {
            account: { id: accountId },
          },
        },
      } = action.data;

      return {
        ...state,
        id,
        accountId,
        ...attributes,
        isAuthenticated: true,
        isLoading: false,
      };
    }
    case 'SESSION_2FA_SUCCESS': {
      return {
        ...state,
        requires2FA: false,
        isLoading: false,
      };
    }
    case 'SESSION_2FA_REQUIRED':
    case 'SESSION_2FA_SMS_SUCCESS': {
      return {
        ...state,
        requires2FA: true,
        isLoading: false,
      };
    }
    case 'SESSION_REMOVE_SUCCESS':
      return {
        isAuthenticated: false,
      };
    case 'SESSION_STORE_REDIRECT': {
      const { redirectTo } = action.data;

      return {
        ...state,
        redirectTo,
      };
    }
    default:
      return state;
  }
};

export default session;
