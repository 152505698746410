import React, { Component } from 'react';

import PropTypes from 'prop-types';
import qs from 'qs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { View, InputField, Spinner } from '@peakon/components';

import ResponsesList from './ResponsesList';
import * as ResponseActions from '../../actions/ResponseActions';
import CollectionHeader from '../CollectionHeader';

import styles from './styles.css';

function parseToken(props) {
  const { token } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });

  return token || '';
}

class Responses extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      query: parseToken(this.props),
    };

    this.input = null;
  }

  componentDidMount() {
    const {
      responseActions: { getResponsesByToken, resetResponses },
    } = this.props;
    const { query } = this.state;

    resetResponses();

    if (query) {
      getResponsesByToken(query);
    }

    if (this.input) {
      this.input.focus();
    }
  }

  render() {
    const { isLoading, responses } = this.props;
    const { query } = this.state;

    const token = parseToken(this.props);
    const hasQuery = token.length > 0;

    return (
      <View>
        <CollectionHeader>
          <CollectionHeader.Heading>Responses</CollectionHeader.Heading>
          <CollectionHeader.Actions>
            <View className={styles.action}>
              <form action="" onSubmit={this.handleSubmit}>
                <InputField
                  inputType="search"
                  placeholder="Enter at least 5 characters from token or kiosk code..."
                  label="Token or Kiosk code"
                  value={query}
                  onChange={this.handleSearchChange}
                  inputRef={(node) => (this.input = node)}
                />
              </form>
            </View>
          </CollectionHeader.Actions>
        </CollectionHeader>
        <View className={styles.content}>
          {isLoading && <Spinner />}
          {!isLoading && responses.length === 0 && hasQuery && (
            <span>Could not find any responses for &quot;{token}&quot;.</span>
          )}
          {!isLoading && responses.length > 0 && (
            <ResponsesList responses={responses} token={query} />
          )}
        </View>
      </View>
    );
  }

  handleSearchChange = (query) => {
    this.setState({ query });
  };

  handleSubmit = (syntheticEvent) => {
    syntheticEvent.preventDefault();

    const { query } = this.state;

    if (query.length < 5) {
      return;
    }

    const {
      responseActions: { getResponsesByToken, resetResponses },
      history,
    } = this.props;

    if (query.length > 0) {
      history.push(`/responses?token=${query}`);

      getResponsesByToken(query);
    } else {
      history.push('/responses');

      resetResponses();
    }
  };
}

Responses.propTypes = {
  history: PropTypes.object,
  responseActions: PropTypes.object,
  isLoading: PropTypes.bool,
  responses: PropTypes.array,
};

Responses.defaultProps = {
  isLoading: false,
  responses: [],
};

const mapStateToProps = (state) => {
  const { isLoading, responses } = state.responses;

  return {
    isLoading,
    responses,
  };
};

const mapDispatchToProps = (dispatch) => ({
  responseActions: bindActionCreators(ResponseActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Responses);
