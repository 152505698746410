import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { View, Spinner, Toggle } from '@peakon/components';

import * as EmployeeActions from '../../../actions/EmployeeActions';
import { UNSUBSCRIBES_TEMPLATE } from '../../../constants/UnsubscribeConstants';

import styles from './styles.css';

class EmployeeSubscriptions extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      unsubscribes: [],
    };
  }

  componentDidMount() {
    const {
      employee: {
        account: { id: accountId },
      },
      employeeActions: { getUnsubscribes },
    } = this.props;

    getUnsubscribes(accountId);
  }

  static getDerivedStateFromProps(props) {
    const { unsubscribes } = props;

    return {
      unsubscribes,
    };
  }

  render() {
    const { isLoadingChildData } = this.props;
    const { unsubscribes } = this.state;

    return (
      <View className={styles.container}>
        {isLoadingChildData && <Spinner />}
        {!isLoadingChildData && (
          <table style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <td>Template</td>
                <td>Status</td>
                <td>&nbsp;</td>
              </tr>
            </thead>
            <tbody>
              {Object.keys(UNSUBSCRIBES_TEMPLATE).map((unsubscribeKey) => {
                const index = unsubscribes.findIndex((unsubscribe) => {
                  return unsubscribe.id === unsubscribeKey;
                });

                const status = index > -1 ? 'unsubscribed' : 'subscribed';

                return (
                  <tr key={unsubscribeKey}>
                    <td>{unsubscribeKey}</td>
                    <td style={{ textTransform: 'capitalize' }}>
                      <span className={styles[status]}>{status}</span>
                    </td>
                    <td className={styles.actions}>
                      <Toggle
                        checked={status === 'subscribed'}
                        onChange={() => this.toggleSubscription(unsubscribeKey)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </View>
    );
  }

  toggleSubscription = (unsubscribeKey) => {
    const {
      employee: {
        account: { id: accountId },
      },
      employeeActions: { updateUnsubscribes },
      unsubscribes,
    } = this.props;

    const index = unsubscribes.findIndex((u) => u.id === unsubscribeKey);
    if (index > -1) {
      unsubscribes.splice(index, 1);
    } else {
      unsubscribes.push({
        type: 'unsubscribes',
        id: unsubscribeKey,
      });
    }

    this.setState({ unsubscribes });

    updateUnsubscribes(accountId, {
      data: unsubscribes,
    });
  };
}

EmployeeSubscriptions.propTypes = {
  employeeActions: PropTypes.object,
  isLoadingChildData: PropTypes.bool,
  unsubscribes: PropTypes.array,
  employee: PropTypes.object,
};

EmployeeSubscriptions.defaultProps = {
  isLoadingChildData: true,
  unsubscribes: [],
};

const mapStateToProps = (state) => {
  const { isLoadingChildData, unsubscribes } = state.employee;

  return {
    isLoadingChildData,
    unsubscribes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  employeeActions: bindActionCreators(EmployeeActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeSubscriptions);
