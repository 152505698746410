import React from 'react';

import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

import { View } from '@peakon/components';

import Overview from './Overview';
import Reset from './Reset';
import { TestCompanyProvider } from './TestCompanyProvider';
import PrivateRoute from '../PrivateRoute';

export default function TestCompanies({ match: { path } }) {
  return (
    <TestCompanyProvider>
      <View>
        <Switch>
          <PrivateRoute path={path} exact component={Overview} />
          <PrivateRoute path={`${path}/:id`} component={Reset} />
        </Switch>
      </View>
    </TestCompanyProvider>
  );
}

TestCompanies.propTypes = {
  match: PropTypes.object,
};
