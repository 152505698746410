import React, { Component } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  View,
  InputField,
  Button,
  Select,
  DatePickerA11y,
} from '@peakon/components';

import * as CompanyActions from '../../../actions/CompanyActions';
import env from '../../../client/env';
import { BENCHMARK_HIERARCHY } from '../../../constants/BenchmarkHierarchyConstants';
import { parseInteger } from '../../../utils/parseInteger';
import RightsFilter from '../../RightsFilter';

import styles from './styles.css';

const MOMENT_FORMAT = 'YYYY-MM-DD';

function getIndustryByIndustryGroup(sector, industryGroup) {
  if (sector && industryGroup) {
    return Object.values(
      BENCHMARK_HIERARCHY[sector.value][industryGroup.value],
    ).map((industry) => {
      return {
        value: industry,
        label: industry,
      };
    });
  }
}

function getIndustryGroupBySector(sector) {
  if (sector) {
    return Object.keys(BENCHMARK_HIERARCHY[sector.value]).map(
      (industryGroup) => {
        return {
          value: industryGroup,
          label: industryGroup,
        };
      },
    );
  }
}

function getSectors() {
  return Object.keys(BENCHMARK_HIERARCHY).map((sector) => {
    return {
      value: sector,
      label: sector,
    };
  });
}

function makeOption(x) {
  return x ? { value: x, label: x } : null;
}

function offsetDateForDisplay(date) {
  const oldDate = new Date(date);
  const newDate = new Date(
    oldDate.getTime() + oldDate.getTimezoneOffset() * 60000,
  );
  return moment(newDate).format(MOMENT_FORMAT);
}

class CompanySettings extends Component {
  constructor(...args) {
    super(...args);

    const {
      company: {
        workdayTenant,
        workdayWebserviceHost,
        employeeLimit,
        segmentLimitOverride,
        salesforceId,
        serviceStartedAt,
        serviceEndedAt,
        companyBlockAt,
        companyDeleteAt,
        benchmarkSector,
        benchmarkIndustryGroup,
        benchmarkIndustry,
        sessionDuration,
        addOns,
        settings: { customSenderDomainDkimMode },
      },
    } = this.props;

    this.state = {
      workdayTenant,
      workdayWebserviceHost,
      employeeLimit,
      segmentLimitOverride,
      salesforceId,
      serviceStartedAt,
      serviceEndedAt,
      companyBlockAt,
      companyDeleteAt,
      benchmarkSector: makeOption(benchmarkSector),
      benchmarkIndustryGroup: makeOption(benchmarkIndustryGroup),
      benchmarkIndustry: makeOption(benchmarkIndustry),
      sessionDuration,
      addOns,
      customSenderDomainDkimMode,
    };
  }

  render() {
    const {
      workdayTenant,
      workdayWebserviceHost,
      employeeLimit,
      segmentLimitOverride,
      salesforceId,
      serviceStartedAt,
      serviceEndedAt,
      companyBlockAt,
      companyDeleteAt,
      benchmarkSector,
      benchmarkIndustryGroup,
      benchmarkIndustry,
      sessionDuration,
      addOns,
      customSenderDomainDkimMode,
    } = this.state;

    const sectorOptions = getSectors();
    const industryGroupOptions = getIndustryGroupBySector(benchmarkSector);
    const industryOptions = getIndustryByIndustryGroup(
      benchmarkSector,
      benchmarkIndustryGroup,
    );
    const dkimModes = [
      { value: 'easydkim', label: 'Easy DKIM' },
      { value: 'byodkim', label: 'Bring your own DKIM' },
    ];

    return (
      <View className={styles.container}>
        <h2>Settings</h2>
        <form action="">
          <InputField
            inputType="text"
            label="Workday Tenant"
            value={workdayTenant || ''}
            onChange={(value) =>
              this.handleValueChange(value.trim(), 'workdayTenant')
            }
          />
          <InputField
            inputType="text"
            label="Workday Webservice Host"
            value={workdayWebserviceHost || ''}
            onChange={(value) =>
              this.handleValueChange(value.trim(), 'workdayWebserviceHost')
            }
          />
          {env.environment !== 'production' && (
            <InputField
              inputType="number"
              placeholder="100"
              label="Employee limit"
              value={employeeLimit || ''}
              onChange={(value) =>
                this.handleValueChange(
                  parseInteger(value, 100),
                  'employeeLimit',
                )
              }
            />
          )}
          <RightsFilter rights={['backoffice:feature_flips']}>
            <InputField
              inputType="number"
              label="Segment limit override"
              value={segmentLimitOverride || ''}
              onChange={(value) =>
                this.handleValueChange(
                  parseInteger(value, null),
                  'segmentLimitOverride',
                )
              }
            />
          </RightsFilter>
          <View>
            <label className={styles.label} htmlFor="salesforceId">
              Salesforce Account Id
            </label>
            <View className={styles.info}>
              This field is required in order to set service start and end dates
            </View>
            <InputField
              id="salesForceId"
              inputType="text"
              value={salesforceId || ''}
              onChange={(value) =>
                this.handleValueChange(value, 'salesforceId')
              }
            />
          </View>
          <div className={styles.service}>
            <View>
              <label className={styles.label} htmlFor="serviceStart">
                Service Start
              </label>
              <View className={styles.info}>
                This date should be the start of the service, not the contract
                signing date
              </View>
              <DatePickerA11y
                disabled={!salesforceId}
                value={
                  serviceStartedAt
                    ? offsetDateForDisplay(serviceStartedAt)
                    : null
                }
                id="serviceStart"
                onDateChange={({ target: { value } }) =>
                  this.handleValueChange(value, 'serviceStartedAt')
                }
              />
            </View>
            <View>
              <label className={styles.label} htmlFor="serviceEnd">
                Service End
              </label>
              <View className={styles.info}>
                The service end date should only be set once the company has
                churned
              </View>
              <DatePickerA11y
                disabled={!salesforceId}
                value={
                  serviceEndedAt ? offsetDateForDisplay(serviceEndedAt) : null
                }
                id="serviceEnd"
                onDateChange={({ target: { value } }) =>
                  this.onServiceEndDateChange(value)
                }
              />
            </View>
          </div>
          <div className={styles.service}>
            <View>
              <label className={styles.label} htmlFor="companyBlock">
                Company Block
              </label>
              <View className={styles.info}>
                The company block date should only be set once the service end
                date has been set
              </View>
              <DatePickerA11y
                disabled={!salesforceId || !serviceEndedAt}
                value={companyBlockAt && offsetDateForDisplay(companyBlockAt)}
                id="companyBlock"
                onDateChange={({ target: { value } }) =>
                  this.handleValueChange(value, 'companyBlockAt')
                }
                min={moment().format(MOMENT_FORMAT)}
              />
            </View>
            <View>
              <label className={styles.label} htmlFor="companyDelete">
                Company Delete
              </label>
              <View className={styles.info}>
                The company delete date should only be set once the service end
                date has been set
              </View>
              <DatePickerA11y
                disabled={!salesforceId || !serviceEndedAt}
                value={companyDeleteAt && offsetDateForDisplay(companyDeleteAt)}
                id="companyDelete"
                onDateChange={({ target: { value } }) =>
                  this.handleValueChange(value, 'companyDeleteAt')
                }
                min={moment().format(MOMENT_FORMAT)}
              />
            </View>
          </div>
          <div className={styles.service}>
            <View>
              <label className={styles.label} htmlFor="benchmarkSector">
                Benchmark Sector
              </label>
              <View className={styles.info}>
                The broadest benchmark classification to use when creating
                benchmarks. Not visible to customer.
              </View>
              <Select
                id="benchmarkSector"
                value={
                  benchmarkSector
                    ? sectorOptions.find(
                        (s) => s.value === benchmarkSector.value,
                      )
                    : null
                }
                onChange={(value) => {
                  this.handleValueChange(value, 'benchmarkSector');
                  this.setState({
                    benchmarkIndustryGroup: null,
                    benchmarkIndustry: null,
                  });
                }}
                options={sectorOptions}
                isClearable
                placeholder="Select Sector"
              />
            </View>
            <View>
              <label className={styles.label} htmlFor="benchmarkIndustryGroup">
                Benchmark Industry Group
              </label>
              <View className={styles.info}>
                A finer classification. Optional. Not visible to customer.
              </View>
              <Select
                id="benchmarkIndustryGroup"
                isDisabled={!benchmarkSector}
                value={
                  benchmarkIndustryGroup
                    ? industryGroupOptions.find(
                        (ig) => ig.value === benchmarkIndustryGroup.value,
                      )
                    : null
                }
                onChange={(value) => {
                  this.handleValueChange(value, 'benchmarkIndustryGroup');
                  this.setState({
                    benchmarkIndustry: null,
                  });
                }}
                options={industryGroupOptions}
                isClearable
                placeholder="Select Industry Group"
              />
            </View>
            <View>
              <label className={styles.label} htmlFor="benchmarkIndustry">
                Benchmark Industry
              </label>
              <View className={styles.info}>
                The most precise classification. Optional. Not visible to
                customer.
              </View>
              <Select
                id="benchmarkIndustry"
                isDisabled={!benchmarkIndustryGroup}
                value={
                  benchmarkIndustry
                    ? industryOptions.find(
                        (i) => i.value === benchmarkIndustry.value,
                      )
                    : null
                }
                onChange={(value) =>
                  this.handleValueChange(value, 'benchmarkIndustry')
                }
                options={industryOptions}
                isClearable
                placeholder="Select Industry"
              />
            </View>
          </div>
          <RightsFilter rights={['backoffice:company:session:admin']}>
            <View>
              <label className={styles.label} htmlFor="sessionDuration">
                Session duration (minutes)
              </label>
              <View className={styles.info}>
                Advanced: override default &apos;regular&apos; session duration
                for the company
              </View>
              <View className={styles.info}>
                Range: 30 minutes - 40320 minutes (= default 4 weeks)
              </View>
              <InputField
                id="sessionDuration"
                inputType="number"
                placeholder="40320"
                value={sessionDuration || ''}
                onChange={(value) =>
                  this.handleValueChange(
                    parseInteger(value, 40320),
                    'sessionDuration',
                  )
                }
              />
            </View>
          </RightsFilter>
          <div className={styles.service}>
            {addOns.includes('custom_sender_domain') && (
              <View>
                <label
                  className={styles.label}
                  htmlFor="customSenderDomainDefaultMode"
                >
                  Custom Sender Domain
                </label>
                <View className={styles.info}>
                  The default DKIM mode for custom sender domain.
                </View>
                <Select
                  id="customSenderDomainDkimMode"
                  value={
                    dkimModes.find(
                      (dm) => dm.value === customSenderDomainDkimMode,
                    ) || null
                  }
                  onChange={(value) => this.handleDkimChange(value)}
                  options={dkimModes}
                  placeholder="Select DKIM mode"
                />
              </View>
            )}
          </div>
          <Button
            className={styles.saveButton}
            size="small"
            onClick={this.handleSaveChanges}
          >
            Save
          </Button>
        </form>
      </View>
    );
  }

  handleValueChange = (value, property) => {
    this.setState({ [property]: value });
  };

  onServiceEndDateChange = (value) => {
    const companyBlockAt =
      value && offsetDateForDisplay(moment.utc(value).add(30, 'days').toDate());
    const companyDeleteAt =
      value && offsetDateForDisplay(moment.utc(value).add(90, 'days').toDate());

    this.handleValueChange(value, 'serviceEndedAt');
    this.handleValueChange(companyBlockAt, 'companyBlockAt');
    this.handleValueChange(companyDeleteAt, 'companyDeleteAt');
  };

  handleDkimChange = (event) => {
    this.setState({
      customSenderDomainDkimMode: event ? event.value : null,
    });
  };

  handleSaveChanges = (syntheticEvent) => {
    syntheticEvent.preventDefault();

    const {
      companyActions: { update, updateCompanySettings },
      company: { id },
    } = this.props;

    let {
      employeeLimit,
      segmentLimitOverride,
      workdayTenant,
      workdayWebserviceHost,
      salesforceId,
      serviceStartedAt,
      serviceEndedAt,
      companyBlockAt,
      companyDeleteAt,
      benchmarkSector,
      benchmarkIndustryGroup,
      benchmarkIndustry,
      sessionDuration,
      customSenderDomainDkimMode,
    } = this.state;

    serviceStartedAt = serviceStartedAt
      ? moment(offsetDateForDisplay(serviceStartedAt)).format(MOMENT_FORMAT)
      : null;

    serviceEndedAt = serviceEndedAt
      ? moment(offsetDateForDisplay(serviceEndedAt)).format(MOMENT_FORMAT)
      : null;

    companyBlockAt = companyBlockAt
      ? moment(offsetDateForDisplay(companyBlockAt)).format(MOMENT_FORMAT)
      : null;

    companyDeleteAt = companyDeleteAt
      ? moment(offsetDateForDisplay(companyDeleteAt)).format(MOMENT_FORMAT)
      : null;

    salesforceId = salesforceId || null;
    workdayTenant = workdayTenant || null;
    workdayWebserviceHost = workdayWebserviceHost || null;

    benchmarkSector = benchmarkSector ? benchmarkSector.value : null;
    benchmarkIndustryGroup = benchmarkIndustryGroup
      ? benchmarkIndustryGroup.value
      : null;
    benchmarkIndustry = benchmarkIndustry ? benchmarkIndustry.value : null;

    const body = {
      data: {
        type: 'companies',
        attributes: {
          workdayTenant,
          workdayWebserviceHost,
          employeeLimit,
          segmentLimitOverride,
          salesforceId,
          serviceStartedAt,
          serviceEndedAt,
          companyBlockAt,
          companyDeleteAt,
          benchmarkSector,
          benchmarkIndustryGroup,
          benchmarkIndustry,
          sessionDuration,
        },
      },
    };

    update(id, body);
    updateCompanySettings(id, { customSenderDomainDkimMode });
  };
}

CompanySettings.propTypes = {
  company: PropTypes.object,
  companyActions: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  companyActions: bindActionCreators(CompanyActions, dispatch),
});

export default connect(null, mapDispatchToProps)(CompanySettings);
