import { AppError } from '@peakon/error-reporter';

const extractResponseMessage = (response) => {
  if (response.status === -1) {
    return {
      title: 'Error',
      message: 'notifications__no-connection',
    };
  } else if (response.status === 429) {
    return {
      title: 'Error',
      message: 'notifications__enhance-your-calm',
    };
  } else if (response.status === 503) {
    return {
      title: 'Error',
      message: 'notifications__unavailable',
    };
  } else {
    try {
      const body = JSON.parse(response.body);
      const {
        data: { message },
      } = body;

      return {
        title: 'Error',
        message: body.data.uuid ? `${message} (${body.data.uuid})` : message,
      };
    } catch (error) {
      return {
        title: 'Error',
        message: 'notifications__error__default',
      };
    }
  }
};

const extractErrorMessage = (error) => {
  if (error.message === 'Failed to fetch') {
    // No network connection
    return {
      title: 'Error',
      message: 'notifications__no-connection',
    };
  } else if (/Loading\schunk\s(\d+)\sfailed/.test(error.message)) {
    return {
      title: 'error__loading-chunk__title',
      message: 'error__loading-chunk',
    };
  } else {
    return { title: 'Error', message: error.message };
  }
};

const extractMessage = (error) => {
  if (error instanceof Error || error instanceof AppError) {
    return extractErrorMessage(error);
  } else if (error && error.status) {
    return extractResponseMessage(error);
  }

  return {
    title: 'Error',
    message: 'notifications__error__default',
  };
};

export default extractMessage;
