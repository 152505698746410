import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  Button,
  Card,
  Label,
  Select,
  Spinner,
  Typography,
} from '@peakon/components';

import { scheduleExportCompareBenchmarks } from '../../../actions/BenchmarkActions';
import { showSuccessNotification } from '../../../actions/NotificationActions';
import DownloadButton from '../../DownloadButton';

import styles from '../styles.css';

function ExportBenchmarks({
  backgroundTasksRealtimeChannel,
  benchmarkVersions,
  disabled,
}) {
  const dispatch = useDispatch();

  const [
    selectedBaseBenchmarkVersionOption,
    setSelectedBaseBenchmarkVersionOption,
  ] = useState(null);
  const [
    selectedCompareBenchmarkVersionOption,
    setSelectedCompareBenchmarkVersionOption,
  ] = useState(null);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    if (backgroundTasksRealtimeChannel) {
      backgroundTasksRealtimeChannel.on('complete', (result) => {
        if (result.url) {
          const message = successNotificationMessage(result);

          dispatch(
            showSuccessNotification({
              title: `Comparison export is ready`,
              message,
              duration: 5 * 60 * 1000, // the temporary download link survives for 5 mins
              dismissable: true,
            }),
          );
        }

        setSelectedBaseBenchmarkVersionOption(null);
        setSelectedCompareBenchmarkVersionOption(null);
      });
    }
  }, [backgroundTasksRealtimeChannel]); // eslint-disable-line react-hooks/exhaustive-deps

  const benchmarkVersionOptions = benchmarkVersions.map((bv) => ({
    value: bv.id,
    label: bv.name,
  }));

  const handleExport = (syntheticEvent) => {
    syntheticEvent.preventDefault();

    setIsExporting(true);

    dispatch(
      scheduleExportCompareBenchmarks(
        selectedBaseBenchmarkVersionOption.value,
        selectedCompareBenchmarkVersionOption.value,
      ),
    );
  };

  const successNotificationMessage = (realtimeResult) => {
    return (
      <React.Fragment>
        {realtimeResult.filename}&nbsp;&nbsp;
        <DownloadButton title="Download" url={realtimeResult.url} />
      </React.Fragment>
    );
  };

  return (
    <Card className={styles.headerCard}>
      <Typography.H2>Compare Changes</Typography.H2>
      <form onSubmit={handleExport}>
        <div>
          <Label htmlFor="base-benchmark-version">Main Benchmark Version</Label>
          <Select
            onChange={setSelectedBaseBenchmarkVersionOption}
            value={selectedBaseBenchmarkVersionOption}
            options={benchmarkVersionOptions}
            placeholder="Base benchmarks..."
            inputId="base-benchmark-version"
            isDisabled={disabled}
          />
        </div>
        <div>
          <Label htmlFor="compare-benchmark-version">
            Compare Benchmark Version
          </Label>
          <Select
            onChange={setSelectedCompareBenchmarkVersionOption}
            value={selectedCompareBenchmarkVersionOption}
            options={benchmarkVersionOptions}
            placeholder="Benchmarks to compare..."
            inputId="compare-benchmark-version"
            isDisabled={disabled}
          />
        </div>
        <div className="styles.submit">
          <Button
            buttonType="submit"
            disabled={
              disabled ||
              isExporting ||
              isEmpty(selectedBaseBenchmarkVersionOption) ||
              isEmpty(selectedCompareBenchmarkVersionOption) ||
              selectedBaseBenchmarkVersionOption.value ===
                selectedCompareBenchmarkVersionOption.value
            }
            stretched
          >
            {isExporting ? (
              <React.Fragment>
                <Spinner size="small" color="white" />
              </React.Fragment>
            ) : (
              'Export Comparison to Excel'
            )}
          </Button>
        </div>
      </form>
    </Card>
  );
}

ExportBenchmarks.propTypes = {
  backgroundTasksRealtimeChannel: PropTypes.object,
  benchmarkVersions: PropTypes.array,
  disabled: PropTypes.bool,
};

export default ExportBenchmarks;
