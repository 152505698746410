import { PLANS, PREMIUM_STATUS } from '../constants/PlanConstants';

export function getPlanForBadge({ premiumStatus, plan, status }) {
  if (status === 'blocked') {
    return status;
  }

  if (PLANS.includes(plan)) {
    return plan;
  }

  if (PREMIUM_STATUS.includes(premiumStatus)) {
    return premiumStatus;
  }
}
