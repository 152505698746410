import { v4 as uuidv4 } from 'uuid';

export const showSuccessNotification = (data) =>
  show({ ...data, type: 'success' });

export const showErrorNotification = (data) => show({ ...data, type: 'error' });

export const show =
  ({
    id = uuidv4(),
    title,
    message,
    type,
    code,
    dismissable = true,
    duration = 5000,
  } = {}) =>
  (dispatch) => {
    dispatch({
      type: 'NOTIFICATION_SHOW',
      data: { id, title, message, type, code, dismissable },
    });

    if (duration !== Infinity) {
      setTimeout(() => dispatch(remove(id)), duration);
    }
  };

export const remove = (id) => ({
  type: 'NOTIFICATION_HIDE',
  data: {
    id,
  },
});
