import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import some from 'lodash/some';

const hasRequiredRight = (sessionRights = [], requiredRights = []) =>
  isUndefined(requiredRights) ||
  isEmpty(requiredRights) ||
  some(sessionRights, (givenRight) => {
    if (isArray(requiredRights)) {
      return some(requiredRights, (requiredRight) => {
        return givenRight.startsWith(requiredRight);
      });
    } else {
      return givenRight.startsWith(requiredRights);
    }
  });

export default hasRequiredRight;
