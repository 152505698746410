import React, { Component } from 'react';

import PropTypes from 'prop-types';
import qs from 'qs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { InputField, Button, View, Spinner } from '@peakon/components';

import * as AuthenticateActions from '../../actions/AuthActions';
import * as NotificationActions from '../../actions/NotificationActions';
import Logo from '../../assets/backoffice_temp_logo@2x.png';
import env from '../../client/env';
import { getRedirectUrl } from '../../utils/urlUtil';
import TwoFactorPanel from '../TwoFactorPanel';

import styles from './styles.css';

class LoginPanel extends Component {
  static propTypes = {
    history: PropTypes.object,
    authActions: PropTypes.object,
    notificationActions: PropTypes.object,
    session: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      SSOemail: '',
      password: '',
    };
  }

  async componentDidMount() {
    const {
      history: {
        location: { search },
      },
      authActions: { storeRedirectTo, authenticateWithSaml },
    } = this.props;

    const { redirectTo, response, companyId } = qs.parse(search, {
      ignoreQueryPrefix: true,
    });

    const redirectUrl = getRedirectUrl(redirectTo);
    if (redirectUrl) {
      storeRedirectTo(redirectUrl);
    }

    if (response) {
      await authenticateWithSaml({
        companyId,
        SAMLResponse: response,
      });
    }
  }

  componentDidUpdate() {
    const {
      session: { isAuthenticated, redirectTo = '/' },
      history,
    } = this.props;

    if (isAuthenticated) {
      if (redirectTo) {
        history.push(redirectTo);
      } else {
        history.push('/');
      }
    }
  }

  onAuthenticate = async (syntheticEvent) => {
    syntheticEvent.preventDefault();

    const { authActions } = this.props;
    const { email, password } = this.state;

    await authActions.authenticateWithPassword({ email, password });
  };

  initiateSSOFlow = async (syntheticEvent) => {
    syntheticEvent.preventDefault();
    const { authActions } = this.props;
    const { SSOemail } = this.state;

    const { url } = await authActions.initiateSSOFlow({
      email: SSOemail,
      relayState: `${env.host}/login`,
    });

    window.location.href = url;
  };

  on2FACodeSubmit = async (code) => {
    const { authActions } = this.props;

    await authActions.verify2FA(code);
  };

  onSendSms = async () => {
    const { authActions, notificationActions } = this.props;

    const response = await authActions.send2FASms();

    notificationActions.showSuccessNotification({
      title: '2FA',
      message: `Sent an SMS to ${response.cellphone}`,
    });
  };

  render() {
    const { session } = this.props;
    const { SSOemail, email, password } = this.state;

    if (session.isLoading) {
      return (
        <View className={styles.loading}>
          <Spinner />
        </View>
      );
    }

    if (session.requires2FA) {
      return (
        <View className={styles.container}>
          <TwoFactorPanel
            onCodeSubmit={this.on2FACodeSubmit}
            onSendSms={this.onSendSms}
          />
        </View>
      );
    }

    return (
      <View className={styles.container}>
        <img className={styles.logo} src={Logo} alt="" />
        <form action="/" onSubmit={this.onAuthenticate} className={styles.form}>
          <input type="submit" style={{ visibility: 'hidden' }} />
          <View className={styles.input}>
            <InputField
              autoFocus
              label="Email"
              inputType="email"
              value={email}
              onChange={(newEmail) => this.setState({ email: newEmail })}
            />
          </View>
          <View className={styles.input}>
            <InputField
              label="Password"
              inputType="password"
              value={password}
              onChange={(newPassword) =>
                this.setState({ password: newPassword })
              }
            />
          </View>
          <View className={styles.actions}>
            <Button async type="primary" onClick={this.onAuthenticate}>
              Login
            </Button>
          </View>
        </form>
        {env.allowSSO && (
          <form
            action="/"
            onSubmit={this.initiateSSOFlow}
            className={styles.form}
          >
            <input type="submit" style={{ visibility: 'hidden' }} />
            <View className={styles.input}>
              <InputField
                autoFocus
                label="Email"
                inputType="email"
                value={SSOemail}
                onChange={(newSSOemail) =>
                  this.setState({ SSOemail: newSSOemail })
                }
              />
            </View>
            <View className={styles.actions}>
              <Button async type="primary" onClick={this.onSSO}>
                Login with SSO
              </Button>
            </View>
          </form>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(AuthenticateActions, dispatch),
  notificationActions: bindActionCreators(NotificationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPanel);
