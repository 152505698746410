import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { Spinner } from '@peakon/bedrock/react/spinner';

import { SideNav } from './SideNav';
import { TopNav } from './TopNav';
import * as AuthActions from '../../actions/AuthActions';
import env from '../../client/env';
import BackofficeAccess from '../../components/Access';
import BenchmarkVersions from '../../components/BenchmarkVersions';
import Companies from '../../components/Companies';
import Company from '../../components/Company';
import DevArea from '../../components/DevArea';
import Employee from '../../components/Employee';
import Employees from '../../components/Employees';
import FeatureFlips from '../../components/FeatureFlips';
import FormerCustomers from '../../components/FormerCustomers';
import Login from '../../components/Login';
import Logout from '../../components/Logout';
import PrivateRoute from '../../components/PrivateRoute';
import Responses from '../../components/Responses';
import Signup from '../../components/Signup';
import TestCompanies from '../../components/TestCompanies';
import Missed from '../Missed';
import Overview from '../Overview';

import styles from './styles.css';

class App extends Component {
  static propTypes = {
    authActions: PropTypes.object,
    isAuthenticated: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    const { authActions } = this.props;

    try {
      // try to authenticate with token
      await authActions.getSession(null, { remember: true });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { isAuthenticated } = this.props;
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <div className={styles.container}>
          <div className={styles.spinnerContainer}>
            <Spinner size="32" />
          </div>
        </div>
      );
    }

    return (
      <Fragment>
        <TopNav isAuthenticated={isAuthenticated} />
        <div className={styles.container}>
          <SideNav isAuthenticated={isAuthenticated} />
          <div className={styles.content}>
            <main className={styles.mainContent}>
              <Switch>
                <Route path="/logout" component={Logout} />
                <Route path="/login" component={Login} />
                <PrivateRoute
                  rights={['backoffice:access:admin']}
                  path="/backoffice-access"
                  component={BackofficeAccess}
                />
                <PrivateRoute
                  rights={['backoffice:benchmarks:admin']}
                  path="/benchmarks"
                  component={BenchmarkVersions}
                />
                {env.environment !== 'production' && (
                  <PrivateRoute
                    rights={['testCompany:admin']}
                    path="/test-companies"
                    component={TestCompanies}
                  />
                )}
                <PrivateRoute
                  rights={['backoffice:company:read']}
                  path="/companies/:id"
                  component={Company}
                />
                <PrivateRoute
                  rights={['backoffice:company:read']}
                  path="/companies"
                  component={Companies}
                />
                <PrivateRoute
                  rights={['backoffice:employee:read']}
                  path="/employees/:id"
                  component={Employee}
                />
                <PrivateRoute
                  rights={['backoffice:employee:read']}
                  path="/employees"
                  component={Employees}
                />
                <PrivateRoute
                  rights={['backoffice:response:read']}
                  path="/responses"
                  component={Responses}
                />
                <PrivateRoute
                  rights={['backoffice:company:delete']}
                  path="/former-customers"
                  component={FormerCustomers}
                />
                <PrivateRoute
                  rights={['backoffice:feature_flips']}
                  path="/feature-flips"
                  component={FeatureFlips}
                />
                <PrivateRoute
                  rights={['backoffice:feature_flips']}
                  path="/dev-area"
                  component={DevArea}
                />
                <PrivateRoute
                  rights={['backoffice:company:create']}
                  path="/signup"
                  component={Signup}
                />
                <PrivateRoute
                  redirect="/404"
                  rights={['backoffice:company:read']}
                  path="/"
                  exact
                  component={Overview}
                />
                <Route path="/404" component={Missed} />
                <Route component={Missed} />
              </Switch>
            </main>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(AuthActions, dispatch),
});

const mapStateToProps = (state) => ({
  isAuthenticated: state.session.isAuthenticated,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
