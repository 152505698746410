import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import peakonApi from '../client/peakonApi';
import hasRequiredRight from '../utils/hasRequiredRight';

const PrivateRoute = (props) => {
  const {
    component: Component,
    redirect,
    session,
    location,
    rights,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!peakonApi.auth.isAuthenticated()) {
          const pathname = `/login?redirectTo=${encodeURIComponent(
            window.location.pathname,
          )}`;

          return <Redirect to={pathname} />;
        }

        if (!hasRequiredRight(session.rights, rights)) {
          return (
            <Redirect
              to={{
                pathname: redirect,
                state: { from: location },
              }}
            />
          );
        }

        const componentProps = {
          ...routeProps,
          ...rest,
        };

        return <Component {...componentProps} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.object,
  redirect: PropTypes.string,
  rights: PropTypes.array,
  session: PropTypes.object,
};

PrivateRoute.defaultProps = {
  redirect: '/login',
};

const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps)(PrivateRoute);
