import React, { Component, Fragment } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { View, Spinner } from '@peakon/components';

import * as EmployeeActions from '../../../actions/EmployeeActions';
import { getPageQuery } from '../../../utils/pagination';
import Paginate from '../../Paginate';

import styles from './styles.css';

class EmployeeEmailLogs extends Component {
  componentDidMount() {
    const {
      employee: {
        account: { id: accountId },
      },
      employeeActions: { getMailEvents },
    } = this.props;

    getMailEvents(accountId);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      location,
      employee: {
        account: { id: accountId },
      },
      employeeActions: { getMailEvents },
    } = this.props;

    const page = getPageQuery(location.search);
    const hasChangedPage = page !== getPageQuery(prevProps.location.search);

    if (hasChangedPage) {
      getMailEvents(accountId, page);
    }
  }

  render() {
    const {
      location,
      isLoadingChildData,
      emailLogs,
      links,
      employee: { name },
    } = this.props;

    return (
      <View className={styles.container}>
        {isLoadingChildData && <Spinner />}
        {!isLoadingChildData && emailLogs.length === 0 && (
          <span>{name} does not have any Email logs.</span>
        )}
        {!isLoadingChildData && emailLogs.length > 0 && (
          <Fragment>
            <table style={{ tableLayout: 'fixed' }}>
              <thead>
                <tr>
                  <td>Time</td>
                  <td>Event</td>
                  <td>Template</td>
                  <td>Additional data</td>
                </tr>
              </thead>
              <tbody>
                {emailLogs.map((emailLog) => {
                  const { id, eventAt, event, template, data } = emailLog;

                  return (
                    <tr key={id}>
                      <td title={eventAt}>
                        {moment(eventAt)
                          .utc()
                          .format('MMMM Do YYYY, h:mm:ss a')}
                      </td>
                      <td>{event}</td>
                      <td>{template}</td>
                      <td>{data}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <Paginate
              links={links}
              currentPage={getPageQuery(location.search)}
              handlePageClick={this.handlePageClick}
            />
          </Fragment>
        )}
      </View>
    );
  }

  handlePageClick = (page) => {
    const {
      history,
      employee: { id },
    } = this.props;

    let url = `/employees/${id}/email-logs`;

    if (page > 1) {
      url += `?page=${page}`;
    }

    history.push(url);
  };
}

EmployeeEmailLogs.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  employeeActions: PropTypes.object,
  isLoadingChildData: PropTypes.bool,
  emailLogs: PropTypes.array,
  employee: PropTypes.object,
  links: PropTypes.object,
};

EmployeeEmailLogs.defaultProps = {
  isLoadingChildData: true,
  emailLogs: [],
};

const mapStateToProps = (state) => {
  const { isLoadingChildData, emailLogs, links } = state.employee;

  return {
    isLoadingChildData,
    emailLogs,
    links,
  };
};

const mapDispatchToProps = (dispatch) => ({
  employeeActions: bindActionCreators(EmployeeActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeEmailLogs);
