import { checkPartnerAccount } from './CompanyActions';
import {
  showErrorNotification,
  showSuccessNotification,
} from './NotificationActions';
import api from '../client/peakonApi';
import asyncDispatch from '../utils/asyncDispatch';

export const getAccounts = () => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'ACCESS_ACCOUNTS_READ',
    action: api.get('/access', {
      order: 'asc',
      fields: {
        accounts: ['email', 'role', 'region'].join(','),
      },
    }),
  });
};

export const udpate = (id, body) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'ACCESS_ACCOUNTS_ROLE_UPDATE',
    action: api.put(`/access/${id}`, {}, body).then((response) => {
      dispatch(
        showSuccessNotification({
          title: 'The Backoffice account has been updated.',
        }),
      );

      dispatch(getAccounts());

      return response;
    }),
  });
};

async function checkEmployeeAccount(email, dispatch) {
  const response = await api.get(`/employees/contexts/backoffice`, {
    filter: {
      'account.email': email,
    },
  });

  if (response.data.length === 0) {
    return dispatch(
      showErrorNotification({
        title: `No account found with e-mail ${email}`,
      }),
    );
  } else {
    return response.data[0].relationships.account.data.id;
  }
}

export const addAccount = (email, body) => async (dispatch) => {
  let accountId;
  const { role, region } = body;
  const isPartner = role === 'partner_consultant';

  if (isPartner) {
    const response = await checkPartnerAccount(email, dispatch);
    accountId = response ? response.data.id : null;
  } else {
    accountId = await checkEmployeeAccount(email, dispatch);
  }

  const updateRoleRoute = isPartner
    ? `/access/partner/${accountId}`
    : `/access/${accountId}`;
  return asyncDispatch({
    dispatch,
    resource: 'ACCESS_ACCOUNTS_ADD',
    action: api.put(updateRoleRoute, {}, body).then((addAccountResponse) => {
      dispatch(
        showSuccessNotification({
          title: `${email} has been created with role ${role} for ${region}.`,
        }),
      );

      dispatch(getAccounts());

      return addAccountResponse;
    }),
  });
};

export const removeAccount = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'ACCESS_ACCOUNTS_REMOVE',
    action: api.delete(`/access/${id}`).then((response) => {
      dispatch(
        showSuccessNotification({
          title: 'The account has been deleted.',
        }),
      );

      dispatch(getAccounts());

      return response;
    }),
  });
};
