import { useEffect, useRef, useState } from 'react';

import peakonApi from '../client/peakonApi';
import RealtimeChannel from '../utils/realtime';

export default function useRealtimeChannel(
  channel,
  { autoConnect = false } = {},
) {
  const ref = useRef(null);
  const [_, setSocket] = useState(null); // eslint-disable-line no-unused-vars

  useEffect(() => {
    if (!channel) {
      ref.current && ref.current.leave();
      return;
    }

    ref.current = new RealtimeChannel(channel, {
      getToken: () => peakonApi.auth.restoreToken(),
    });

    if (autoConnect) {
      ref.current.join();
    }

    // hack to force a re-render, we only care when we
    // have changed channel, so let's use the channel as key
    setSocket(channel);

    return () => {
      ref.current && ref.current.leave();
    };
  }, [autoConnect, channel, setSocket]);

  return ref.current;
}
