import React from 'react';

import PropTypes from 'prop-types';

import { View } from '@peakon/components';

import RevealIdentity from './RevealIdentity';
import RightsFilter from '../../RightsFilter';

import styles from './styles.css';

const CompanyCommentActions = ({ company }) => {
  return (
    <View>
      <h2>Comments</h2>
      <div className={styles.container}>
        <RightsFilter rights={['backoffice:comment:identity']}>
          <RevealIdentity company={company} />
        </RightsFilter>
      </div>
    </View>
  );
};

CompanyCommentActions.propTypes = {
  company: PropTypes.object,
};

export default CompanyCommentActions;
