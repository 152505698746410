import React, { useEffect, useState } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button, DatePicker, Select, View } from '@peakon/components';

import * as BenchmarkActions from '../../../actions/BenchmarkActions';
import { formatDate } from '../../BenchmarkVersions/util';

import styles from './styles.css';

const BenchmarkVersions = (props) => {
  const {
    company: { id: companyId, name: companyName },
    isLoading,
  } = props;
  const {
    addCompanyBenchmarkVersion,
    getBenchmarkVersions,
    getCompanyBenchmarkVersions,
    removeCompanyBenchmarkVersion,
  } = BenchmarkActions;
  const dispatch = useDispatch();
  const { benchmarkVersions, companyBenchmarkVersions } = useSelector(
    (state) => {
      return {
        benchmarkVersions: state.benchmarks.benchmarkVersions,
        companyBenchmarkVersions: state.benchmarks.companyBenchmarkVersions,
      };
    },
  );

  useEffect(() => {
    dispatch(getBenchmarkVersions());
    dispatch(getCompanyBenchmarkVersions(companyId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [selectedBenchmarkVersionOption, setSelectedBenchmarkVersionOption] =
    useState(null);
  const [selectedValidFrom, setSelectedValidFrom] = useState(null);

  const handleBenchmarkVersionAdd = async (
    benchmarkVersionOption,
    validFrom,
  ) => {
    const benchmarkVersion = benchmarkVersions.find(
      (bv) => bv.id === benchmarkVersionOption.value,
    );
    const confirmed = !benchmarkVersion.publishedAt
      ? confirm(
          `Benchmark version ${
            benchmarkVersion.name
          } is unpublished, which means it has not yet been verified by data scientists. Are you sure you want to set this as the benchmark for ${companyName} from ${formatDate(
            validFrom,
          )}?`,
        )
      : true;

    if (confirmed) {
      dispatch(
        addCompanyBenchmarkVersion(benchmarkVersion.id, companyId, validFrom),
      );

      setSelectedBenchmarkVersionOption(null);
      setSelectedValidFrom(null);
    }
  };

  const handleBenchmarkVersionRemove = async (companyVersion) => {
    if (
      confirm(
        `Are you sure you want to remove the benchmark ${companyVersion.name} from company ${companyId}?`,
      )
    ) {
      dispatch(removeCompanyBenchmarkVersion(companyVersion, companyId));
    }
  };

  const handleValidFromChange = (value) => {
    const selected = value
      ? moment.utc(value).hours(0).minutes(0).seconds(0)
      : null;

    setSelectedValidFrom(selected);
  };

  const benchmarkVersionOptions = benchmarkVersions.map((bv) => ({
    value: bv.id,
    label: bv.name,
  }));

  return (
    <div className={styles.container}>
      <h2>Benchmark Versions</h2>
      <table>
        <tbody>
          <tr key="title">
            <td />
            <td>Version</td>
            <td>Valid from</td>
          </tr>
          <tr key="new-version">
            <td />
            <td className={styles.filter}>
              <Select
                onChange={setSelectedBenchmarkVersionOption}
                value={selectedBenchmarkVersionOption}
                options={benchmarkVersionOptions}
                isClearable
                placeholder="Add new company benchmark version"
                inputId="action-type"
              />
            </td>
            <td className={styles.filter}>
              <DatePicker
                value={selectedValidFrom && formatDate(selectedValidFrom)}
                renderCalendar
                onDateChange={(value) => handleValidFromChange(value)}
              />
            </td>
            <td>
              <Button
                async
                className={styles.button}
                type="secondary"
                size="small"
                disabled={
                  isLoading ||
                  !selectedBenchmarkVersionOption ||
                  !selectedValidFrom
                }
                onClick={() =>
                  handleBenchmarkVersionAdd(
                    selectedBenchmarkVersionOption,
                    selectedValidFrom,
                  )
                }
              >
                Add
              </Button>
            </td>
          </tr>
          {!isLoading &&
            companyBenchmarkVersions.length > 0 &&
            companyBenchmarkVersions.map((companyVersion) => {
              const { id, name, unpublished, validFrom } = companyVersion;

              return (
                <tr
                  key={id}
                  className={
                    unpublished ? styles.unpublishedVersion : undefined
                  }
                >
                  <td>
                    {unpublished || (
                      <View className={styles.badge}>
                        <View className={styles.text}>Published</View>
                      </View>
                    )}
                  </td>
                  <td>{name}</td>
                  <td>{formatDate(validFrom)}</td>
                  <td>
                    <Button
                      async
                      className={styles.button}
                      type="danger"
                      size="small"
                      onClick={() =>
                        handleBenchmarkVersionRemove(companyVersion)
                      }
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

BenchmarkVersions.propTypes = {
  company: PropTypes.object,
  isLoading: PropTypes.boolean,
};

export default BenchmarkVersions;
