import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

export function getAuditChanges(data) {
  const { oldValues, newValues } = data;

  if (!oldValues || !newValues) {
    return null;
  }

  const changes = [];

  function createChangeSet(key, newValue, oldValue) {
    const change = {
      key,
      oldValue,
      newValue,
    };

    changes.push(change);
  }

  function recurse(key, newValuesAtThisLevel, oldValuesAtThisLevel) {
    const newValue = newValuesAtThisLevel[key];

    if (!oldValuesAtThisLevel) {
      return;
    }

    const oldValue = oldValuesAtThisLevel[key];

    if (isEqual(newValue, oldValue)) {
      return;
    }

    if (Array.isArray(newValue)) {
      return createChangeSet(key, newValue, oldValue);
    }

    if (isObject(newValue) && !isEmpty(newValue)) {
      return Object.keys(newValue).forEach((k) =>
        recurse(k, newValue, oldValue),
      );
    }

    createChangeSet(key, newValue, oldValue);
  }

  if (isObject(newValues)) {
    Object.keys(newValues).forEach((k) => recurse(k, newValues, oldValues));
  }

  return changes;
}

export function getSafeValue(value) {
  if (isString(value)) {
    return value;
  }

  if (isBoolean(value)) {
    return value.toString();
  }

  if (isNull(value)) {
    return 'null';
  }

  if (isObject(value)) {
    if (isEmpty(value)) {
      return 'null';
    } else {
      return JSON.stringify(value);
    }
  }

  return value;
}
