import jwtDecode from 'jwt-decode';

import { showErrorNotification } from './NotificationActions';
import env from '../client/env';
import peakonApi from '../client/peakonApi';
import asyncDispatch from '../utils/asyncDispatch';
import extractMessage from '../utils/extractMessage';

const SESSION_PARAMS = {
  include: 'company,account,employee,employee.account',
  fields: {
    companies: [
      'accessAllowed',
      'code',
      'createdAt',
      'employeeFeatures',
      'features',
      'location',
      'logo',
      'name',
      'sector',
      'size',
      'settings',
      'addOns',
      'serviceStartedAt',
      'serviceEndedAt',
      'benchmarkInfo',
      'benchmarkSector',
      'benchmarkIndustryGroup',
      'benchmarkIndustry',
    ].join(','),
  },
};

export const logout = () => (dispatch) => {
  // delete the token from localstorage
  peakonApi.auth.unstoreToken();

  return asyncDispatch({
    dispatch,
    resource: 'SESSION_REMOVE',
    // destroy the session with the api
    action: peakonApi.auth.unauthenticate(),
  });
};

export const getSession =
  (token, { bootstrap = false, remember = true } = {}) =>
  (dispatch) => {
    token = token || peakonApi.auth.restoreToken();
    const isAuthenticated = peakonApi.auth.isAuthenticated();

    if (!isAuthenticated && !token) {
      return dispatch({
        type: 'SESSION_READ_FAILED',
      });
    }

    if (token) {
      peakonApi.auth.authenticate(token, { remember });

      return asyncDispatch({
        dispatch,
        resource: 'SESSION_READ',
        action: peakonApi.root.session(SESSION_PARAMS, {
          _bootstrap: bootstrap,
        }),
        showNotification: false,
      });
    } else {
      return dispatch({
        type: 'SESSION_READ_FAILED',
      });
    }
  };

export const verify2FA = (code) => async (dispatch) => {
  await asyncDispatch({
    dispatch,
    resource: 'SESSION_2FA',
    data: {},
    action: peakonApi.post(
      '/auth/twofactor/verify',
      {},
      {
        token: code,
      },
    ),
  });

  return dispatch(getSession());
};

export const send2FASms = () => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'SESSION_2FA_SMS',
    data: {},
    action: peakonApi.post('/auth/twofactor/sms'),
  });
};

export const authenticateWithPassword =
  ({ email, password }) =>
  async (dispatch) => {
    let response;

    // FIXME: move this to a new `unsetToken` fn in `peakon-js`
    peakonApi.auth.tokenId = undefined;
    peakonApi.auth.unstoreToken();

    dispatch({
      type: 'SESSION_READ_LOADING',
    });

    try {
      response = await peakonApi.post(
        '/auth/password',
        {},
        {
          email,
          password,
          backoffice: true,
        },
      );
    } catch (error) {
      dispatch({
        type: 'SESSION_READ_FAILED',
      });

      return dispatch(
        showErrorNotification({
          ...extractMessage(error),
          code: error.status,
        }),
      );
    }

    if (response) {
      const token = response.data.id;
      const payload = jwtDecode(token);

      if (payload.tfa === 'required') {
        // Remove when implemented in peakon-js
        peakonApi.auth.authenticate(token, {
          remember: true,
        });

        return dispatch({
          type: 'SESSION_2FA_REQUIRED',
          data: {},
        });
      } else {
        return dispatch(getSession(token));
      }
    }

    return dispatch({
      type: 'SESSION_READ_FAILED',
    });
  };

export const initiateSSOFlow =
  ({ email, relayState }) =>
  (dispatch) => {
    if (!env.allowSSO) {
      return;
    }

    try {
      return peakonApi.post(
        '/auth/check_email',
        {},
        {
          email,
          relayState,
        },
      );
    } catch (error) {
      return dispatch(
        showErrorNotification({
          ...extractMessage(error),
          code: error.status,
        }),
      );
    }
  };

export const authenticateWithSaml =
  ({ companyId, SAMLResponse }) =>
  async (dispatch) => {
    if (!env.allowSSO) {
      return;
    }

    let response;

    // FIXME: move this to a new `unsetToken` fn in `peakon-js`
    peakonApi.auth.tokenId = undefined;
    peakonApi.auth.unstoreToken();

    dispatch({
      type: 'SESSION_READ_LOADING',
    });

    try {
      response = await peakonApi.post(
        `/auth/saml/${companyId}`,
        {},
        {
          SAMLResponse,
        },
      );
    } catch (error) {
      dispatch({
        type: 'SESSION_READ_FAILED',
      });

      return dispatch(
        showErrorNotification({
          ...extractMessage(error),
          code: error.status,
        }),
      );
    }

    if (response) {
      const token = response.data.id;
      return dispatch(getSession(token));
    }

    return dispatch({
      type: 'SESSION_READ_FAILED',
    });
  };

export const storeRedirectTo =
  (redirectTo = '') =>
  (dispatch) => {
    dispatch({
      type: 'SESSION_STORE_REDIRECT',
      data: {
        redirectTo,
      },
    });
  };
