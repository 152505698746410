import React, { Component, Fragment } from 'react';

import classnames from 'classnames';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import qs from 'qs';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { components } from 'react-select';
import { bindActionCreators } from 'redux';

import { LinkButton } from '@peakon/bedrock/react/button';
import {
  View,
  InputField,
  Select,
  Label,
  Spinner,
  Avatar,
} from '@peakon/components';

import * as CompanyActions from '../../actions/CompanyActions';
import * as EmployeeActions from '../../actions/EmployeeActions';
import { getAbbreviation } from '../../utils/abbreviation';
import { getPlanForBadge } from '../../utils/company';
import { getDashboardURL } from '../../utils/getDashboardURL';
import getImgixUrl from '../../utils/getImgixUrl';
import CollectionHeader from '../CollectionHeader';
import CompanyInfo from '../CompanyInfo';
import PlanBadge from '../PlanBadge';
import RightsFilter from '../RightsFilter';

import styles from './styles.css';

const EMPLOYEE_FILTERS = [
  { value: 'account.email', label: 'E-mail' },
  { value: 'name', label: 'Name' },
  { value: 'accountId', label: 'Account Id' },
  { value: 'employeeId', label: 'Employee Id' },
  { value: 'identifier', label: 'Company Employee Id' },
  { value: 'externalId', label: 'External Id' },
];

function parseQuery(location) {
  return qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
}

function parseFilter(query) {
  const queryKeys = Object.keys(query);
  const [filter] = EMPLOYEE_FILTERS.filter(({ value }) =>
    queryKeys.find((qk) => qk === value),
  );

  return filter;
}

class Employees extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      filter: {
        ...EMPLOYEE_FILTERS[0],
      },
      selectedCompany: null,
      companyQuery: '',
    };

    const query = parseQuery(props.location);
    const filter = parseFilter(query);

    if (filter) {
      Object.assign(this.state, {
        filter,
        query: query[filter.value],
      });
    }

    this.input = null;

    this.handleCompanySearch = debounce(this.handleCompanySearch, 300);
  }

  componentDidMount() {
    this.search();
  }

  componentDidUpdate(prevProps) {
    if (isEqual(prevProps.location.search, this.props.location.search)) {
      return;
    }

    this.search();
  }

  render() {
    const { isLoading, employees } = this.props;

    const {
      query,
      filter: { label },
      selectedCompany,
    } = this.state;

    return (
      <View>
        <CollectionHeader>
          <CollectionHeader.Heading>Employees</CollectionHeader.Heading>
          <CollectionHeader.Actions>
            <View className={styles.action}>
              <form action="" onSubmit={this.handleSearch}>
                <InputField
                  inputType="search"
                  placeholder={label}
                  label={label}
                  value={query}
                  onChange={this.handleInputChange}
                  inputRef={(node) => (this.input = node)}
                />
              </form>
            </View>
            {this.renderFilters()}
          </CollectionHeader.Actions>
        </CollectionHeader>
        <View className={styles.content}>
          <View className={styles.table}>
            {isLoading && <Spinner />}
            {!isLoading && employees.length > 0 && (
              <table>
                <thead>
                  <tr className={styles.tableHeader}>
                    <td>Name</td>
                    <td>E-mail</td>
                    <td>Company</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {employees.map((employee) => {
                    const {
                      id,
                      name,
                      account: {
                        attributes: { email },
                      },
                    } = employee;

                    let company;
                    if (employee.company) {
                      company = {
                        id: employee.company.id,
                        ...employee.company.attributes,
                        primarySubdomain:
                          employee.company.relationships.companySubdomains?.find(
                            (subdomain) => {
                              return subdomain.attributes.primary;
                            },
                          ),
                      };
                    } else {
                      company = {
                        id: selectedCompany.id,
                        ...selectedCompany,
                      };
                    }

                    const employeeUrl = `/employees/${id}`;

                    return (
                      <tr key={id}>
                        <td>
                          <Link to={employeeUrl}>{name}</Link>
                        </td>
                        <td>{email}</td>
                        <td>
                          <Link to={`/companies/${company.id}`}>
                            <CompanyInfo company={company} />
                          </Link>
                        </td>
                        <td>
                          {company.accessPossible && (
                            <RightsFilter
                              rights={['backoffice:employee:admin']}
                            >
                              <LinkButton
                                size="small"
                                variant="primary"
                                href={getDashboardURL({
                                  pathname: '/login',
                                  searchParams: { employee_id: id },
                                  subdomain:
                                    company.primarySubdomain?.attributes
                                      .subdomain,
                                })}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                Log in as {name}
                              </LinkButton>
                            </RightsFilter>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </View>
        </View>
      </View>
    );
  }

  search() {
    const {
      employeeActions: { reset, search },
    } = this.props;

    const { selectedCompany } = this.state;

    const query = parseQuery(this.props.location);
    const parsedFilter = parseFilter(query);

    if (parsedFilter) {
      if (query) {
        let params;

        const filterType = parsedFilter.value;
        const queryValue = query[filterType];

        if (filterType === 'name') {
          params = {
            sort: 'score',
            q: queryValue,
          };
        } else {
          let filter = {
            [filterType]: queryValue,
          };

          if (['identifier', 'externalId'].includes(filterType)) {
            // eslint-disable-next-line max-depth
            if (selectedCompany) {
              filter = {
                ...filter,
                companyId: selectedCompany.id,
              };
            } else {
              return;
            }
          }

          params = {
            filter,
          };
        }

        search(params);
      } else {
        reset();
      }
    }

    this.focusInput();
  }

  renderFilters() {
    const { filter, companyQuery } = this.state;
    const { companies, isSearchForCompanies } = this.props;

    return (
      <View className={classnames(styles.action, styles.filters)}>
        <View className={styles.filterText}>&nbsp;</View>
        <View className={styles.filter}>
          <Label>Filter by</Label>
          <Select
            options={EMPLOYEE_FILTERS}
            value={filter}
            onChange={this.handleFilterChange}
            isClearable={false}
          />
        </View>
        {filter && ['identifier', 'externalId'].includes(filter.value) && (
          <Fragment>
            <View className={styles.filter}>
              <Label>Company</Label>
              <Select
                placeholder="i.e. Kinetar"
                onChange={this.handleCompanySelected}
                onInputChange={this.handleCompanySearch}
                loadingMessagefunction={() => 'Loading....'}
                isLoading={isSearchForCompanies}
                noOptionsMessage={() =>
                  !isSearchForCompanies && companyQuery.length > 0 ? (
                    <span>No results found.</span>
                  ) : null
                }
                options={companies.map((c) => {
                  const { id, name, logo } = c;

                  return {
                    ...c,
                    id,
                    label: name,
                    logo,
                  };
                })}
                components={{ Option: this.renderSearchCompany }}
              />
            </View>
          </Fragment>
        )}
      </View>
    );
  }

  handleFilterChange = (filter) => {
    if (filter === null) {
      filter = {
        ...EMPLOYEE_FILTERS[0],
      };
    }

    this.setState({ filter }, this.focusInput);
  };

  handleSearch = (syntheticEvent) => {
    syntheticEvent.preventDefault();

    this.push();
  };

  handleInputChange = (query) => {
    this.setState({ query });
  };

  push() {
    const { history } = this.props;

    const {
      filter: { value: type },
      query,
    } = this.state;

    if (query) {
      const encoded = `${type}=${encodeURIComponent(query)}`;
      history.push(`/employees?${encoded}`);
    }
  }

  focusInput() {
    if (this.input) {
      if (this.input.select) {
        this.input.select();
      } else {
        this.input.focus();
      }
    }
  }

  handleCompanySelected = (company) => {
    this.setState(
      {
        selectedCompany: company,
      },
      this.search,
    );
  };

  handleCompanySearch = (q) => {
    const { companyQuery } = this.state;

    if (q === companyQuery) {
      return;
    }

    this.setState(
      {
        companyQuery: q,
      },
      () => {
        if (q.length === 0) {
          return;
        }

        const {
          companyActions: { search },
        } = this.props;

        search({ q });
      },
    );
  };

  renderSearchCompany = (props) => {
    const { data: company } = props;
    const { label: name, logo } = company;

    return (
      // getClassNames noop is required overwise it will throw a runtime error
      <components.Option {...props} key={company.id} getClassNames={() => {}}>
        <View
          style={{
            padding: '2.5px 0px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Avatar
            src={getImgixUrl({ src: logo, fit: 'crop', height: 58, width: 58 })}
            abbreviation={getAbbreviation(name)}
          />
          <View style={{ marginLeft: 10, marginRight: 10 }}>{name}</View>
          <PlanBadge plan={getPlanForBadge(company)} />
        </View>
      </components.Option>
    );
  };
}

Employees.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  employeeActions: PropTypes.object,
  companyActions: PropTypes.object,
  isLoading: PropTypes.bool,
  employees: PropTypes.array,
  companies: PropTypes.array,
  isSearchForCompanies: PropTypes.bool,
};

Employees.defaultProps = {
  isLoading: false,
  employees: [],
  companies: [],
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.employee.isLoading,
    employees: state.employee.employees,
    companies: state.companies.searchResult,
    isSearchForCompanies: state.companies.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  employeeActions: bindActionCreators(EmployeeActions, dispatch),
  companyActions: bindActionCreators(CompanyActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Employees);
