import React, { useContext, useEffect, useState } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import {
  Button,
  InputField,
  Label,
  Select,
  Spinner,
  View,
} from '@peakon/components';
import jsonapiparser from '@peakon/jsonapiparser';

import { PAGINATION_PER_PAGE_COUNT } from '../../../constants/PaginationConstants';
import useApi, { usePaginatedApi } from '../../../hooks/useApi';
import { getPageQuery } from '../../../utils/pagination';
import CollectionHeader from '../../CollectionHeader';
import Paginate from '../../Paginate';
import AddNewTestCompany from '../AddTestCompanyComponent';
import { TestCompanyContext } from '../TestCompanyProvider';

import styles from './styles.css';

const TEST_COMPANY_FIELDS = [
  'name',
  'domain',
  'employees',
  'weeks',
  'configName',
  'company',
  'plan',
  'password',
].join(',');

const EMPLOYEE_FILTERS = [
  { value: 'name', label: 'Name' },
  { value: 'domain', label: 'Domain' },
  { value: 'testCompanyId', label: 'TestCompany Id' },
  { value: 'companyId', label: 'Company Id' },
];

export default function TestCompanies({ history: { push }, location }) {
  const { setTestCompanyContext, showNotification } =
    useContext(TestCompanyContext);

  const [pageNr, setPageNr] = useState(getPageQuery(location.search));

  const [filters, setFilter] = useState(EMPLOYEE_FILTERS[0]);

  const [deleteCompany] = useApi({ method: 'DELETE' });

  const [searchClient, { latestData, loading }] = usePaginatedApi();

  const [testCompanies, setTestCompanies] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    (async () => {
      await searchForCompanies();
    })();
  }, [pageNr]); //eslint-disable-line

  async function handleClearSearch() {
    setSearchQuery('');
    await searchForCompanies();
  }

  function handlePageClick(page) {
    const query = page === 1 ? '' : `?page=${page}`;
    setPageNr(page);
    push(`/test-companies${query}`);
  }

  function handleDeleteCompany(testCompany) {
    const message = `Are you sure you want to delete the ${testCompany.name} test company?`;
    if (confirm(message)) {
      deleteCompany(`/test_companies/${testCompany.id}`).then((response) => {
        showNotification(
          `The test company ${testCompany.name} will be deleted.`,
        );
        searchForCompanies();
      });
    }
  }

  async function handleCompanyCreateError(error) {
    showNotification(`Could not submit new test company: ${error}`, true);
  }

  async function handleCompanyCreateSuccess(companyName) {
    showNotification(`The test company ${companyName} is being generated`);
    await searchForCompanies();
  }

  function handleFilterChange(filterChange) {
    setFilter(filterChange);
  }

  async function searchForCompanies(synthEvent = null, query = null) {
    if (synthEvent) {
      synthEvent.preventDefault();
    }

    let searchFilters = {};

    if (query) {
      searchFilters = {
        [filters.value]:
          filters.value === 'name' || filters.value === 'domain'
            ? `%${query}%$iLike`
            : query,
      };
      query = null;
    }

    const params = {
      page: pageNr,
      per_page: PAGINATION_PER_PAGE_COUNT,
      q: query,
      fields: {
        companies: ['name, status'].join(','),
        test_companies: TEST_COMPANY_FIELDS,
      },
      include: 'company',
      filter: { ...searchFilters },
    };
    const run = async () => {
      const results = await searchClient('/test_companies', params);
      return jsonapiparser(results).data.map((C) => {
        return {
          ...C.attributes,
          ...C.relationships,
          id: C.id,
        };
      });
    };
    setTestCompanies(await run());
  }

  function navigateToCompany(companyId) {
    push(`/companies/${companyId}`);
  }

  function navigateToReset(testCompany) {
    setTestCompanyContext(testCompany);
    push(`/test-companies/${testCompany.id}`);
  }

  return (
    <View>
      <CollectionHeader>
        <CollectionHeader.Heading>Test Companies</CollectionHeader.Heading>
        <CollectionHeader.Actions>
          <View className={styles.action}>
            <form
              action=""
              onSubmit={(e) => searchForCompanies(e, searchQuery)}
            >
              <InputField
                inputType="search"
                placeholder={filters.label}
                label="Company name"
                value={searchQuery}
                onChange={(v) => setSearchQuery(v)}
                onClear={handleClearSearch}
              />
            </form>
          </View>
          <View className={classnames(styles.action, styles.filters)}>
            <View className={styles.filterText}>Key:</View>
            <View className={styles.filter}>
              <Label>Company Key</Label>
              <Select
                options={EMPLOYEE_FILTERS}
                value={filters}
                onChange={(e) => handleFilterChange(e)}
                placeholder="Name"
              />
            </View>
          </View>
        </CollectionHeader.Actions>
      </CollectionHeader>
      {loading && <Spinner />}
      <View className={styles.content}>
        <AddNewTestCompany
          onSuccess={async (companyName) =>
            await handleCompanyCreateSuccess(companyName)
          }
          onError={async (error) => await handleCompanyCreateError(error)}
          loading={loading}
        />
        {!loading && !testCompanies.length && (
          <span>Could not find any companies.</span>
        )}
        {!loading && testCompanies.length > 0 && (
          <View className={styles.table}>
            <table>
              <thead>
                <tr>
                  <td className={styles.column__xsmall}>Id</td>
                  <td>Name</td>
                  <td>Domain</td>
                  <td className={styles.column__small}>Employees</td>
                  <td className={styles.column__small}>Weeks</td>
                  <td className={styles.column__small}>Config Name</td>
                  <td className={styles.column__small}>Plan</td>
                  <td>Password</td>
                  <td className={styles.column__actions}>&nbsp;</td>
                </tr>
              </thead>
              <tbody>
                {testCompanies.map((testCompany) => {
                  const {
                    id,
                    name,
                    domain,
                    employees,
                    weeks,
                    configName,
                    plan,
                    company,
                    password,
                  } = testCompany;
                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      {company ? (
                        <td>
                          <span
                            className={styles.name}
                            onClick={() => navigateToCompany(company.id)}
                          >
                            {name}
                          </span>
                        </td>
                      ) : (
                        <td>{name}</td>
                      )}
                      <td>{domain}</td>
                      <td>{employees}</td>
                      <td>{weeks}</td>
                      <td>{configName}</td>
                      <td>{plan}</td>
                      <td>{password}</td>
                      <td className={styles.column__actions}>
                        {company && company.attributes.status !== 'blocked' ? (
                          <React.Fragment>
                            <Button
                              size="small"
                              type="danger"
                              onClick={() => handleDeleteCompany(testCompany)}
                            >
                              Delete
                            </Button>
                            <Button
                              size="small"
                              type="secondary"
                              onClick={() => navigateToReset(testCompany)}
                            >
                              Reset
                            </Button>
                          </React.Fragment>
                        ) : (
                          <Spinner size="small" />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Paginate
              links={latestData.links}
              currentPage={getPageQuery(location.search)}
              handlePageClick={(page) => handlePageClick(page)}
            />
          </View>
        )}
      </View>
    </View>
  );
}

TestCompanies.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};
