import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { View, Spinner } from '@peakon/components';

import * as ResponseActions from '../../../actions/ResponseActions';
import ResponsesList from '../../Responses/ResponsesList';

import styles from './styles.css';

class EmployeeResponses extends Component {
  componentDidMount() {
    const {
      employee: { id },
      responseActions: { getResponsesByEmployee },
    } = this.props;

    getResponsesByEmployee(id);
  }

  render() {
    const {
      isLoading,
      responses,
      employee: { name },
    } = this.props;

    return (
      <View className={styles.container}>
        {isLoading && <Spinner />}
        {!isLoading && responses.length === 0 && (
          <span>{name} does not have any responses.</span>
        )}
        {!isLoading && responses.length > 0 && (
          <ResponsesList responses={responses} showEmployee={false} />
        )}
      </View>
    );
  }
}

EmployeeResponses.propTypes = {
  responseActions: PropTypes.object,
  isLoading: PropTypes.bool,
  responses: PropTypes.array,
  employee: PropTypes.object,
};

EmployeeResponses.defaultProps = {
  isLoading: true,
  responses: [],
};

const mapStateToProps = (state) => {
  const { isLoading, responses } = state.responses;

  return {
    isLoading,
    responses,
  };
};

const mapDispatchToProps = (dispatch) => ({
  responseActions: bindActionCreators(ResponseActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeResponses);
