import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as AuthenticateActions from '../../actions/AuthActions';

class Logout extends Component {
  static propTypes = {
    authActions: PropTypes.object,
  };

  componentDidMount() {
    const { authActions } = this.props;

    authActions.logout();
  }

  render() {
    return <Redirect to="/login" />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(AuthenticateActions, dispatch),
});

export default connect(null, mapDispatchToProps)(Logout);
