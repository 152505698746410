import React, { Component } from 'react';

import classnames from 'classnames';
import defaultsDeep from 'lodash/defaultsDeep';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { EditDeleteIcon } from '@peakon/bedrock/icons/system';
import { InputField, Label, View, Button } from '@peakon/components';

import * as FeatureFlipActions from '../../../actions/FeatureFlipActions';
import CompanyInfo from '../../CompanyInfo';
import CompanySearch from '../../CompanySearch';

import styles from './styles.css';

const FEATURE_FLIP_DEFAULTS = {
  name: '',
  rolloutPct: 0,
  enabledCompanies: [],
  disabledCompanies: [],
};

function withConfirmation(message, onConfirmed) {
  return () => {
    const confirmed = window.confirm(message);

    if (confirmed) {
      onConfirmed();
    }
  };
}

const FormField = ({ className, children }) => {
  return <div className={classnames(styles.field, className)}>{children}</div>;
};

FormField.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

class FeatureFlipForm extends Component {
  static propTypes = {
    featureFlip: PropTypes.object,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      featureFlip: defaultsDeep(this.props.featureFlip, FEATURE_FLIP_DEFAULTS),
    };
  }

  handleInputChange(name) {
    return (value) => {
      this.setState({
        ...this.state,
        featureFlip: {
          ...this.state.featureFlip,
          [name]: value,
        },
      });
    };
  }

  addCompany(company, relationship) {
    const companies = uniqBy(
      [...this.state.featureFlip[relationship], company],
      'id',
    );

    this.setState({
      ...this.state,
      featureFlip: {
        ...this.state.featureFlip,
        [relationship]: companies,
      },
    });
  }

  removeCompany(company, relationship) {
    this.setState({
      ...this.state,
      featureFlip: {
        ...this.state.featureFlip,
        [relationship]: this.state.featureFlip[relationship].filter(
          (c) => c.id !== company.id,
        ),
      },
    });
  }

  render() {
    const { onSave, onDelete } = this.props;
    const { featureFlip } = this.state;
    const {
      name,
      rolloutPct,
      enabledCompanies,
      disabledCompanies,
      description,
      hasDefinition,
    } = featureFlip;

    const isEditing = isFunction(onDelete);

    return (
      <View className={styles.container}>
        <FormField>
          <InputField
            type="text"
            required
            data-testid="name"
            label="Name"
            value={name}
            onChange={this.handleInputChange('name')}
            readOnly={isEditing}
          />
        </FormField>
        <FormField>
          <InputField
            type="text"
            label="Description"
            value={description}
            readOnly={true}
          />
        </FormField>
        <FormField>
          <InputField
            type="number"
            required
            min={0}
            max={100}
            data-testid="rollout"
            label="Rollout percentage"
            value={rolloutPct}
            onChange={this.handleInputChange('rolloutPct')}
          />
        </FormField>
        <FormField>
          <Label data-testid="enabled-companies">Enabled companies</Label>
          <CompanySearch
            handleSearchSelected={(company, reset) => {
              this.addCompany(company, 'enabledCompanies');
              reset();
            }}
          />
          {enabledCompanies.length > 0 && (
            <div className={styles.table}>
              <table style={{ tableLayout: 'fixed' }}>
                <thead>
                  <tr className={styles.tableHeader}>
                    <td>Company</td>
                    <td>Id</td>
                    <td>Domains</td>
                    <td>&nbsp;</td>
                  </tr>
                </thead>
                <tbody>
                  {enabledCompanies.map((company) => (
                    <tr key={company.id}>
                      <td>
                        <CompanyInfo company={company} />
                      </td>
                      <td>{company.id}</td>
                      <td>{get(company, 'domains', []).join(', ')}</td>
                      <td>
                        <EditDeleteIcon
                          onClick={() =>
                            this.removeCompany(company, 'enabledCompanies')
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </FormField>
        <FormField>
          <Label data-testid="disabled-companies"> Disabled companies</Label>
          <CompanySearch
            handleSearchSelected={(company, reset) => {
              this.addCompany(company, 'disabledCompanies');
              reset();
            }}
          />
          {disabledCompanies.length > 0 && (
            <div className={styles.table}>
              <table style={{ tableLayout: 'fixed' }}>
                <thead>
                  <tr className={styles.tableHeader}>
                    <td>Company</td>
                    <td>Id</td>
                    <td>Domains</td>
                    <td>&nbsp;</td>
                  </tr>
                </thead>
                <tbody>
                  {disabledCompanies.map((company) => (
                    <tr key={company.id}>
                      <td>
                        <CompanyInfo company={company} />
                      </td>
                      <td>{company.id}</td>
                      <td>{get(company, 'domains', []).join(', ')}</td>
                      <td>
                        <EditDeleteIcon
                          onClick={() =>
                            this.removeCompany(company, 'disabledCompanies')
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </FormField>
        <FormField className={styles.actions}>
          <Button async onClick={() => onSave(featureFlip)}>
            Save
          </Button>
          {onDelete && (
            <Button
              type="danger"
              title={
                hasDefinition
                  ? "Feature is currently in use, can't delete"
                  : null
              }
              disabled={hasDefinition}
              onClick={withConfirmation(
                'Are you sure you want to delete this feature flip?',
                () => onDelete(featureFlip),
              )}
            >
              Delete
            </Button>
          )}
        </FormField>
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  featureFlipActions: bindActionCreators(FeatureFlipActions, dispatch),
});

export default connect(null, mapDispatchToProps)(FeatureFlipForm);
