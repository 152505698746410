import React, { Fragment, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Spinner, View } from '@peakon/components';

import BenchmarkVersion from './BenchmarkVersion';
import ExportBenchmarks from './ExportBenchmarks';
import ImportBenchmarks from './ImportBenchmarks';
import UpgradeBenchmarks from './UpgradeBenchmarks';
import { getBenchmarkVersions } from '../../actions/BenchmarkActions';
import useRealtimeChannel from '../../hooks/useRealtimeChannel';
import CollectionHeader from '../CollectionHeader';

import styles from './styles.css';

const BenchmarkVersions = () => {
  const dispatch = useDispatch();

  const {
    isLoading,
    benchmarkVersions,
    isBenchmarksUploading,
    isCompanyDetailsUploading,
    benchmarksProgress,
    companyDetailsProgress,
  } = useSelector((state) => state.benchmarks);

  const channel = useSelector(
    ({ session: { accountId } }) => `background-tasks/${accountId}`,
  );
  const backgroundTasksRealtimeChannel = useRealtimeChannel(channel, {
    autoConnect: true,
  });

  useEffect(() => {
    dispatch(getBenchmarkVersions());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const publishedCount = benchmarkVersions.filter((v) => v.publishedAt).length;

  return (
    <Fragment>
      <CollectionHeader>
        <CollectionHeader.Heading>Benchmarks</CollectionHeader.Heading>
        <CollectionHeader.Actions>
          <div className={styles.header}>
            <UpgradeBenchmarks
              benchmarkVersions={benchmarkVersions}
              disabled={isLoading}
            />
            <ImportBenchmarks
              benchmarkVersions={benchmarkVersions}
              benchmarksProgress={benchmarksProgress}
              companyDetailsProgress={companyDetailsProgress}
              isBenchmarksUploading={isBenchmarksUploading}
              isCompanyDetailsUploading={isCompanyDetailsUploading}
              disabled={
                isLoading || isBenchmarksUploading || isCompanyDetailsUploading
              }
            />
            <ExportBenchmarks
              benchmarkVersions={benchmarkVersions}
              backgroundTasksRealtimeChannel={backgroundTasksRealtimeChannel}
              disabled={isLoading}
            />
          </div>
        </CollectionHeader.Actions>
      </CollectionHeader>
      {isLoading ? (
        <Spinner />
      ) : (
        benchmarkVersions.length > 0 && (
          <View className={styles.content}>
            <table>
              <thead>
                <tr>
                  <td className={styles.column__medium} />
                  <td>Version</td>
                  <td>Created</td>
                  <td>Published</td>
                  <td>Used by (# companies)</td>
                  <td className={styles.column__narrow} />
                  <td className={styles.column__narrow} />
                  <td className={styles.column__narrow} />
                </tr>
              </thead>
              <tbody>
                {benchmarkVersions.map((version, idx) => {
                  return (
                    <BenchmarkVersion
                      key={idx}
                      version={version}
                      idx={idx}
                      publishedCount={publishedCount}
                      isLoading={isLoading}
                      benchmarkVersions={benchmarkVersions}
                      backgroundTasksRealtimeChannel={
                        backgroundTasksRealtimeChannel
                      }
                    />
                  );
                })}
              </tbody>
            </table>
          </View>
        )
      )}
    </Fragment>
  );
};

export default BenchmarkVersions;
