import orderBy from 'lodash/orderBy';

const INITIAL_STATE = {
  benchmarksProgress: 0,
  companyDetailsProgress: 0,
  isBenchmarksUploading: false,
  isCompanyDetailsUploading: false,
  isLoading: false,
  benchmarkVersions: [],
  companyBenchmarkVersions: [],
};

const formatBenchmarkVersion = (benchmarkVersion) => {
  const {
    id,
    attributes: {
      name,
      createdAt,
      publishedAt,
      companiesCount,
      selectorsCount,
    },
  } = benchmarkVersion;

  return {
    id,
    name,
    createdAt,
    publishedAt,
    companiesCount,
    selectorsCount,
  };
};

const formatCompanyBenchmarkVersion = (companyBenchmarkVersion) => {
  const {
    id,
    attributes: { validFrom },
    relationships: {
      version: {
        id: versionId,
        attributes: { name, publishedAt },
      },
    },
  } = companyBenchmarkVersion;

  return {
    id,
    validFrom,
    versionId,
    name,
    unpublished: !publishedAt,
  };
};

const sortCompanyBenchmarkVersions = (companyBenchmarkVersions) => {
  return orderBy(
    companyBenchmarkVersions,
    [(cbv) => cbv.validFrom, (cbv) => cbv.companyBenchmarkVersionId],
    ['desc', 'desc'],
  );
};

const sortBenchmarkVersions = (benchmarkVersions) => {
  return orderBy(
    benchmarkVersions,
    [(bv) => bv.createdAt, (bv) => bv.benchmarkVersionId],
    ['asc', 'desc'],
  );
};

const benchmarks = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'BENCHMARK_UPGRADE_LOADING':
    case 'BENCHMARKS_UPLOAD_PROGRESS': {
      return {
        ...state,
        benchmarksProgress: action.data.progress,
        isBenchmarksUploading: true,
      };
    }
    case 'COMPANY_DETAILS_UPLOAD_PROGRESS': {
      return {
        ...state,
        companyDetailsProgress: action.data.progress,
        isCompanyDetailsUploading: true,
      };
    }
    case 'BENCHMARK_VERSION_DELETE_LOADING':
    case 'BENCHMARK_VERSION_PUBLISH_LOADING':
    case 'BENCHMARK_VERSIONS_READ_LOADING':
    case 'COMPANY_BENCHMARK_VERSIONS_READ_LOADING':
    case 'COMPANY_BENCHMARK_VERSION_CREATE_LOADING':
    case 'COMPANY_BENCHMARK_VERSION_DELETE_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'BENCHMARK_UPLOAD_PROGRESS': {
      return {
        ...state,
        benchmarkProgress: action.data.progress,
      };
    }
    case 'BENCHMARKS_UPLOAD_SUCCESS': {
      return {
        ...state,
        isBenchmarksUploading: false,
      };
    }
    case 'COMPANY_DETAILS_UPLOAD_SUCCESS': {
      return {
        ...state,
        isCompanyDetailsUploading: false,
      };
    }
    case 'BENCHMARK_UPGRADE_SUCCESS':
    case 'BENCHMARK_VERSIONS_READ_SUCCESS': {
      const benchmarkVersions = action.data.data.map(formatBenchmarkVersion);

      return {
        ...state,
        benchmarkVersions,
        isLoading: false,
      };
    }
    case 'COMPANY_BENCHMARK_VERSIONS_READ_SUCCESS': {
      const companyBenchmarkVersions = action.data.data.map(
        formatCompanyBenchmarkVersion,
      );

      return {
        ...state,
        companyBenchmarkVersions,
        isLoading: false,
      };
    }
    case 'COMPANY_BENCHMARK_VERSION_CREATE_SUCCESS': {
      const companyBenchmarkVersion = formatCompanyBenchmarkVersion(
        action.data.data,
      );
      const companyBenchmarkVersions = sortCompanyBenchmarkVersions([
        companyBenchmarkVersion,
        ...state.companyBenchmarkVersions,
      ]);

      return {
        ...state,
        companyBenchmarkVersions,
        isLoading: false,
      };
    }
    case 'BENCHMARKS_IMPORT_SUCCESS':
    case 'BENCHMARK_VERSION_PUBLISH_SUCCESS': {
      const benchmarkVersions = sortBenchmarkVersions(state.benchmarkVersions);

      return {
        ...state,
        benchmarkVersions,
        versionName: '',
        isLoading: false,
        isUploading: false,
      };
    }
    default:
      return state;
  }
};

export default benchmarks;
