import extractMessage from './extractMessage';
import { showErrorNotification } from '../actions/NotificationActions';

const asyncDispatch = async ({
  resource,
  dispatch,
  data = {},
  action,
  showNotification = true,
}) => {
  if (!action) {
    throw new Error(`Missing action`);
  }

  const dispatchPending = () => {
    dispatch({
      type: `${resource}_LOADING`,
      data,
    });
  };

  const dispatchSuccess = (response) => {
    const payload = Array.isArray(response)
      ? response
      : Object.assign({}, data, response);

    dispatch({
      type: `${resource}_SUCCESS`,
      data: payload,
    });

    return payload;
  };

  const dispatchFailure = (error) => {
    if (showNotification) {
      dispatch(
        showErrorNotification({
          ...extractMessage(error),
          code: error.status,
        }),
      );
    }

    dispatch({
      type: `${resource}_FAILED`,
      data: { ...data, error },
    });

    throw error;
  };

  dispatchPending();

  try {
    const response = await action;
    return dispatchSuccess(response);
  } catch (err) {
    dispatchFailure(err);
  }
};

export default asyncDispatch;
