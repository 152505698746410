import React, { useEffect } from 'react';

import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Spinner, Typography, Toggle, RadioGroup } from '@peakon/components';

import SamlAssertion from './SamlAssertion';
import * as IntegrationActions from '../../../actions/IntegrationActions';
import RightsFilter from '../../RightsFilter';

import styles from './styles.css';

const FIELDS = [
  { id: 'loginUrl', title: 'SSO Login URL' },
  { id: 'logoutUrl', title: 'SSO Logout URL (Optional)' },
];

const NAME_ID_OPTIONS = [
  { id: 'email', title: 'Email' },
  { id: 'identifier', title: 'Employee Number' },
];

const REQUIRED_FOR_OPTIONS = [
  { id: 'none', title: 'None' },
  { id: 'non_admins', title: 'Non-admins' },
  { id: 'everyone', title: 'Everyone' },
];

const CompanyIntegrations = ({
  company,
  integrationActions,
  isLoading,
  config,
}) => {
  useEffect(() => {
    integrationActions.getSamlConfig(company.id);
  }, [company.id, integrationActions]);

  if (isLoading) {
    return (
      <div>
        <h2>SSO Integration</h2>
        <Spinner />
      </div>
    );
  } else if (!isLoading && config === null) {
    return (
      <div>
        <h2>SSO Integration</h2>
        <div className={styles.content}>This company has no active SSO.</div>
      </div>
    );
  }

  return (
    <div>
      <h2>SSO Integration</h2>
      <div className={styles.content}>
        <div className={styles.toggle}>
          <Toggle
            id="required"
            checked={config.required}
            disabled
            title="Required"
          />

          <Typography.H3 className={styles.toggleLabel}>
            {config.required ? 'Required' : 'Not required'}
          </Typography.H3>
        </div>
        <div className={styles.grid}>
          <div className={styles.item}>
            <Typography.H3 className={styles.title} htmlFor="requiredFor">
              REQUIRED FOR
            </Typography.H3>

            {REQUIRED_FOR_OPTIONS.map((option) => (
              <RadioGroup
                key={option.id}
                id="requiredFor"
                value={config.requiredFor}
                onChange={noop}
              >
                <RadioGroup.Radio disabled name={option.id} value={option.id}>
                  {option.title}
                </RadioGroup.Radio>
              </RadioGroup>
            ))}
          </div>

          <div className={styles.item}>
            <Typography.H3 className={styles.title} htmlFor="nameId">
              NAME ID
            </Typography.H3>

            {NAME_ID_OPTIONS.map((option) => (
              <RadioGroup
                key={option.id}
                id="nameId"
                value={config.nameIdFormat}
                onChange={noop}
              >
                <RadioGroup.Radio disabled name={option.id} value={option.id}>
                  {option.title}
                </RadioGroup.Radio>
              </RadioGroup>
            ))}
          </div>

          {FIELDS.map((field) => (
            <div key={field.id} className={styles.item}>
              <Typography.H3 className={styles.title}>
                {field.title.toUpperCase()}
              </Typography.H3>
              <Typography.Paragraph>
                {config[field.id] || '-'}
              </Typography.Paragraph>
            </div>
          ))}
        </div>
      </div>
      <RightsFilter rights={['backoffice:saml']}>
        <SamlAssertion company={company} />
      </RightsFilter>
    </div>
  );
};

CompanyIntegrations.propTypes = {
  company: PropTypes.object,
  config: PropTypes.object,
  integrationActions: PropTypes.object,
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    config: state.integrations.saml.config,
    isLoading: state.integrations.saml.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  integrationActions: bindActionCreators(IntegrationActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyIntegrations);
