import React from 'react';

import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { NavigationNavigationLevel1LogOutIcon as Logout } from '@peakon/bedrock/icons/system';
import { LinkButton } from '@peakon/bedrock/react/button';
import { VisuallyHidden } from '@peakon/bedrock/react/visually-hidden';

import Logo from '../../../assets/backoffice_temp_logo@2x.png';

import styles from './styles.css';

export function TopNav({ isAuthenticated }) {
  return (
    <header className={styles.topNav}>
      <NavLink to="/" className={styles.link}>
        <img src={Logo} aria-hidden className={styles.logo} />
        <VisuallyHidden>Peakon backoffice</VisuallyHidden>
      </NavLink>

      {isAuthenticated && (
        <LinkButton
          variant="tertiary"
          href="/logout"
          icon={<Logout />}
          iconPlacement="end"
        >
          Logout
        </LinkButton>
      )}
    </header>
  );
}

TopNav.propTypes = {
  isAuthenticated: PropTypes.bool,
};
