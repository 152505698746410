import moment from 'moment';

import jsonapiparser from '@peakon/jsonapiparser';

import api from '../client/peakonApi';
import { PAGINATION_PER_PAGE_COUNT } from '../constants/PaginationConstants';
import asyncDispatch from '../utils/asyncDispatch';

function getDefaultParams(page = 1, continuation, opts) {
  let query = {
    page,
    per_page: PAGINATION_PER_PAGE_COUNT,
    include: ['company', 'account', 'appIntegration'].join(','),
    fields: {
      companies: ['name', 'premiumStatus', 'status', 'plan'].join(','),
      accounts: ['email'].join(','),
      audits: ['+', 'data'].join(','),
    },
  };

  if (continuation) {
    query.continuation = continuation;
  }

  if (opts) {
    const { objectType, event, from, to, objectId } = opts;

    const hasFiltering = Boolean(objectType || event || from || to || objectId);

    if (hasFiltering) {
      let filter = {
        objectType,
        event,
        objectId,
      };

      if (from) {
        filter = {
          ...filter,
          createdAt: [
            `${moment(from)
              .utc()
              .hours(0)
              .minutes(0)
              .seconds(0)
              .toISOString()}$gte`,
            `${moment(to || from)
              .utc()
              .hours(23)
              .minutes(59)
              .seconds(59)
              .toISOString()}$lte`,
          ],
        };
      }

      query = {
        ...query,
        filter,
      };
    }
  }

  return query;
}

export const getAuditObjectTypes = () => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'AUDITS_OBJECTTYPES_READ',
    action: api.get(`/audits/objectTypes`),
  });
};

export const getAuditsByCompanyId =
  (companyId, page, continuation, opts) => (dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'AUDITS_COMPANY_READ',
      data: { continuation },
      action: api
        .get(
          `/audits/company/${companyId}`,
          getDefaultParams(page, continuation, opts),
        )
        .then(jsonapiparser),
    });
  };

export const scheduleAuditsExportByCompanyId =
  (companyId, opts) => (dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'AUDITS_COMPANY_EXPORT',
      action: api
        .post(
          `/audits/company/${companyId}/export`,
          getDefaultParams(1, undefined, opts),
        )
        .then(jsonapiparser),
    });
  };

export const getAuditsByEmployeeId =
  (accountId, page, continuation, opts) => (dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'AUDITS_EMPLOYEE_READ',
      data: { continuation },
      action: api
        .get(
          `/audits/employee/${accountId}`,
          getDefaultParams(page, continuation, opts),
        )
        .then(jsonapiparser),
    });
  };

export const reset = () => (dispatch) => {
  dispatch({
    type: 'AUDITS_RESET',
  });
};
