const MAX_ITEMS = 20;

const logBuffer = [];

export const getBuffer = () => logBuffer;

export default () => () => {
  return (next) => (action) => {
    // If buffer full
    if (logBuffer.length >= MAX_ITEMS) {
      // Remove the oldest action to make room
      logBuffer.shift();
    }

    logBuffer.push(action);
    return next(action);
  };
};
