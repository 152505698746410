import jsonapiparser from '@peakon/jsonapiparser';

import { showSuccessNotification } from './NotificationActions';
import api from '../client/peakonApi';
import { PAGINATION_PER_PAGE_COUNT } from '../constants/PaginationConstants';
import asyncDispatch from '../utils/asyncDispatch';

export const search =
  (query = {}) =>
  (dispatch) => {
    const params = {
      include: ['company', 'company.companySubdomains', 'account'].join(','),
      fields: {
        companies: [
          'name',
          'premiumStatus',
          'status',
          'plan',
          'accessPossible',
          'companySubdomains',
        ].join(','),
      },
      ...query,
      filter: {
        employmentStatus: 'hired,left,employed$in',
        ...query.filter,
      },
    };

    return asyncDispatch({
      dispatch,
      resource: 'EMPLOYEE_SEARCH',
      action: api
        .get(`/employees/contexts/backoffice`, params)
        .then(jsonapiparser),
    });
  };

export const read = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'EMPLOYEE_READ',
    action: api.employee.get(id, {
      fields: {
        employees: [
          '+',
          'createdAt',
          'deletedAt',
          'anonymizedAt',
          'externalId',
        ].join(','),
        companies: ['name', 'accessPossible', 'companySubdomains'].join(','),
      },
      include: ['company', 'company.companySubdomains', 'account'].join(','),
    }),
  });
};

export const sendDaily = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'EMPLOYEE_SEND_DAILY',
    action: api.post(`/employees/${id}/send_daily`).then((response) => {
      dispatch(
        showSuccessNotification({
          title:
            'Daily digest will be sent for the last round. It is recommended to run the "Update notifications" button in company actions prior to sending the daily digest.',
        }),
      );

      return response;
    }),
  });
};

export const sendPasswordReset = (email, companyId) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'EMPLOYEE_SEND_PASSWORD_RESET',
    action: api
      .post(`/auth/password/forgot`, null, {
        email,
        companyId,
      })
      .then((response) => {
        dispatch(
          showSuccessNotification({
            title: 'Password reset email will be sent.',
          }),
        );

        return response;
      }),
  });
};

export const getSmsEvents = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'EMPLOYEE_SMS_EVENTS_READ',
    action: api.get(`/employees/${id}/smses`),
  });
};

export const clearBounces = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'EMPLOYEE_BOUNCES_CLEAR',
    action: api.delete(`/employees/${id}/bounces`).then((response) => {
      dispatch(
        showSuccessNotification({
          title: 'Successfully cleared bounced status.',
        }),
      );

      dispatch(read(id));

      return response;
    }),
  });
};

export const getUnsubscribes = (accountId) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'EMPLOYEE_SUBSCRIPTIONS_READ',
    action: api.get(`/accounts/${accountId}/unsubscribes`),
  });
};

export const updateUnsubscribes =
  (accountId, body = {}) =>
  (dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'EMPLOYEE_SUBSCRIPTIONS_UPDATE',
      action: api
        .patch(`/accounts/${accountId}/unsubscribes`, {}, body)
        .then((response) => {
          dispatch(
            showSuccessNotification({
              title: 'Successfully updated subscriptions.',
            }),
          );

          return response;
        }),
    });
  };

export const getMailEvents =
  (accountId, page = 1) =>
  (dispatch) => {
    const query = {
      page,
      per_page: PAGINATION_PER_PAGE_COUNT,
    };

    return asyncDispatch({
      dispatch,
      resource: 'EMPLOYEE_MAIL_EVENTS_READ',
      action: api.get(`/accounts/${accountId}/mail_events`, query),
    });
  };

export const reset = () => (dispatch) => {
  dispatch({
    type: 'EMPLOYEE_SEARCH_RESET',
  });
};

export const resetAll = () => (dispatch) => {
  dispatch({
    type: 'EMPLOYEE_RESET',
  });
};

export const anonymize = (employeeId) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'EMPLOYEE_ANONYMIZE',
    action: api
      .post(
        `/employees/${employeeId}/anonymize`,
        {},
        {
          allowRecentlyDeleted: true,
        },
      )
      .then((response) => {
        dispatch(
          showSuccessNotification({
            title: 'The employee has been anonymized.',
          }),
        );

        dispatch(read(employeeId));

        return response;
      }),
  });
};
