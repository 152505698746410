export function getRedirectUrl(redirectTo) {
  if (!redirectTo) {
    return;
  }

  // eslint-disable-next-line no-restricted-globals
  const a = document.createElement('a');
  a.href = redirectTo;

  const { protocol, hostname, pathname } = a;

  const isValid =
    protocol === window.location.protocol &&
    hostname === window.location.hostname;

  if (!isValid) {
    return;
  }

  return pathname;
}

export function slugify(string) {
  return string.replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase();
}
