import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button, TextArea, Typography } from '@peakon/components';

import { postSamlAssertionCheck } from '../../../../actions/IntegrationActions';

import styles from './styles.css';

const SamlAssertion = ({ company }) => {
  const dispatch = useDispatch();
  const [samlAssertion, setSamlAssertion] = useState('');
  const [response, setResponse] = useState('');

  const submit = async () => {
    setResponse('');

    const res = await dispatch(
      postSamlAssertionCheck(company.id, samlAssertion),
    );

    setResponse(res?.data?.attributes);
  };

  return (
    <div className={styles.container}>
      <Typography.H3 className={styles.title}>
        Debug SAML Assertion
      </Typography.H3>
      <div className={styles.field}>
        <TextArea
          rows={8}
          label="SAML Assertion in Base64"
          value={samlAssertion}
          onChange={(value) => setSamlAssertion(value)}
          placeholder="PHNhbWxwOlJlc3BvbnNlIHhtbG5zOnNhbWxwPSJ1cm46b2FzaXM6bmFtZXM6dGM6U0FNTDoyLjA6cHJvdG9jb2wiIElEPSJfZWY2MWE4MzkxMmU0NDZlYzlhZTgiICBJblJlc3BvbnNlVG89Il80OGY2OGNhZGExOTNlMTRjZGQ4M2QzNWEwYjVlMTBlMDhhY2Q1ODZjYTkiICBWZXJzaW9uPSIyLjAiIElzc3VlSW5zdGFudD0iMjAy..."
        />
      </div>
      <div className={styles.field}>
        <Button
          type="secondary"
          size="small"
          disabled={!samlAssertion}
          onClick={submit}
        >
          Debug
        </Button>
      </div>
      {response && (
        <div className={styles.field}>
          <div className={styles.response}>
            {JSON.stringify(response, null, 2)}
          </div>
        </div>
      )}
    </div>
  );
};

SamlAssertion.propTypes = {
  company: PropTypes.object,
};

export default SamlAssertion;
