import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button, Card, InputField, Typography, View } from '@peakon/components';

import { importBenchmarkVersion } from '../../../actions/BenchmarkActions';
import JsonInput from '../../JsonInput';
import { hasUniqueVersionName } from '../util';

import styles from '../styles.css';

function ImportBenchmarks({
  benchmarksProgress,
  benchmarkVersions,
  disabled,
  companyDetailsProgress,
  isBenchmarksUploading,
  isCompanyDetailsUploading,
}) {
  const dispatch = useDispatch();

  const [benchmarksFile, setBenchmarksFile] = useState(null);
  const [companyDetailsFile, setCompanyDetailsFile] = useState(null);
  const [importVersionName, setImportVersionName] = useState('');

  const allFieldsFilled =
    !isEmpty(importVersionName.trim()) &&
    !isEmpty(benchmarksFile?.name) &&
    !isEmpty(companyDetailsFile?.name);

  const handleImportBenchmarks = (syntheticEvent) => {
    syntheticEvent.preventDefault();

    if (allFieldsFilled) {
      const message = `Are you sure you want to import benchmarks from ${benchmarksFile?.name} and company details from ${companyDetailsFile?.name}?`;
      if (confirm(message)) {
        dispatch(
          importBenchmarkVersion(
            benchmarksFile,
            companyDetailsFile,
            importVersionName,
          ),
        );
      }

      setImportVersionName('');
      setBenchmarksFile(null);
      setCompanyDetailsFile(null);
    }
  };

  return (
    <Card className={styles.headerCard}>
      <Typography.H2>Import Benchmarks</Typography.H2>
      <form onSubmit={handleImportBenchmarks}>
        <View>
          <InputField
            data-testid="benchmarkVersionName"
            label="Benchmark Version Name"
            inputType="text"
            name="version-name"
            value={importVersionName}
            onChange={setImportVersionName}
            disabled={disabled}
          />
        </View>
        <View>
          <JsonInput
            file={benchmarksFile}
            isUploading={isBenchmarksUploading}
            name="Benchmarks File"
            onChange={setBenchmarksFile}
            progress={benchmarksProgress}
          />
        </View>
        <View>
          <JsonInput
            file={companyDetailsFile}
            isUploading={isCompanyDetailsUploading}
            name="Company Details File"
            onChange={setCompanyDetailsFile}
            progress={companyDetailsProgress}
          />
        </View>
        <View>
          <Button
            async
            buttonType="submit"
            disabled={
              disabled ||
              !allFieldsFilled ||
              !hasUniqueVersionName(importVersionName, benchmarkVersions)
            }
          >
            Queue Benchmark Import
          </Button>
        </View>
      </form>
    </Card>
  );
}

ImportBenchmarks.propTypes = {
  benchmarkVersions: PropTypes.array,
  benchmarksProgress: PropTypes.number,
  companyDetailsProgress: PropTypes.number,
  isBenchmarksUploading: PropTypes.bool,
  isCompanyDetailsUploading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ImportBenchmarks;
